// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".email-modal-content--XuvD3{max-height:none;max-height:initial;padding:0 0.5rem;scrollbar-width:none;color:inherit;max-width:900px}.email-modal-content--XuvD3::-webkit-scrollbar{display:none}.email-modal--WDVyi{margin:auto !important}.email-modal-buttons--LmaEB{display:flex;justify-content:right;padding:0 0.0625rem}.modal-button--etxza{min-width:7.8125rem}.decline-button--iIqDG{color:var(--main-color);background:var(--admin-hover-background-interactive-elements)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitEmailModal/BunitEmailModal.module.sass"],"names":[],"mappings":"AAEA,4BACE,eAAA,CAAA,kBAAA,CACA,gBAAA,CACA,oBAAA,CACA,aAAA,CACA,eAAA,CACA,+CACE,YAAA,CAEJ,oBACE,sBAAA,CAEF,4BACE,YAAA,CACA,qBAAA,CACA,mBAAA,CAEF,qBACE,mBAAA,CAEF,uBACE,uBAAA,CACA,6DAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.email-modal-content\n  max-height: unset\n  padding: 0 func.rem(8)\n  scrollbar-width: none\n  color: unset\n  max-width: 900px\n  &::-webkit-scrollbar\n    display: none\n\n.email-modal\n  margin: auto !important\n\n.email-modal-buttons\n  display: flex\n  justify-content: right\n  padding: 0 func.rem(1)\n\n.modal-button\n  min-width: func.rem(125)\n\n.decline-button\n  color: var(--main-color)\n  background: var(--admin-hover-background-interactive-elements)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email-modal-content": "email-modal-content--XuvD3",
	"email-modal": "email-modal--WDVyi",
	"email-modal-buttons": "email-modal-buttons--LmaEB",
	"modal-button": "modal-button--etxza",
	"decline-button": "decline-button--iIqDG"
};
export default ___CSS_LOADER_EXPORT___;
