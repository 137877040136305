import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import SelectMailTemplatesLang from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitEmailModal/SelectMailTemplatesLang/SelectMailTemplatesLang';
import BUnitTemplatesContent from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitEmailModal/BUnitTemplatesContent/BUnitTemplatesContent';
import WrapBtns from '@/components/Common/Modal/WrapBtns';
import styles from './BunitEmailModal.module.sass';
import BUnitSelectEmailTemplate from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitEmailModal/BUnitSelectEmailTemplate/BUnitSelectEmailTemplate';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useNLS from '@/components/App/Admin/hooks/hooks';
import SelectMenuTitle from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/SelectMenuTitle/SelectMenuTitle';
import { makeUnitsGroupOperations } from '@/store/actions/admin/superset/unitsTree';
import useGroupOperationNotify from '../BUnitActionsConfirmButton/useGroupOperationNotify';
import useSelectEmailTemplates from './hooks/selectTemplates';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';

function BUnitEmailModal({ isShowingModal, toggleModal }) {
  const [
    [currentTemplateLang, setCurrentTemplateLang],
    [currentTemplateId, setCurrentTemplateId],
  ] = useSelectEmailTemplates();
  const callGroupNotify = useGroupOperationNotify();
  const { units } = useSelector(selectUnitsTreeData);

  const tNLS = useNLS();
  const { supersetId } = useSelector(selectSuperset);
  const dispatch = useDispatch();

  const { mailTemplates } = useSelector(
    (state) => state.admin.superset.supersetFields,
  );

  const sendEmail = () => {
    dispatch(
      makeUnitsGroupOperations({
        supersetId,
        operationType: 'sendEmail',
        langId: currentTemplateLang,
        template: currentTemplateId,
      }),
    )
      .then(() => {
        toggleModal(false);
        const selectedCount = Object.values(units).filter(
          ({ selected }) => !!selected,
        ).length;
        callGroupNotify('sendEmail', selectedCount, 'success');
      })
      .catch(() => {
        toggleModal(false);
        callGroupNotify('sendEmail', 0, 'error');
      });
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={() => toggleModal(false)}
      title="Выберите шаблон"
      externalClassNames={{
        contentClass: styles['email-modal-content'],
        modal: styles['email-modal'],
      }}
    >
      <Content>
        <SelectMenuTitle />
        {mailTemplates && Object.keys(mailTemplates).length > 1 && (
          <SelectMailTemplatesLang
            languagesId={Object.keys(mailTemplates)}
            currentTemplateLang={currentTemplateLang}
            setCurrentTemplateLang={setCurrentTemplateLang}
          />
        )}

        <BUnitSelectEmailTemplate
          mailTemplates={mailTemplates[currentTemplateLang]}
          currentTemplateId={currentTemplateId}
          setCurrentTemplateId={setCurrentTemplateId}
        />

        <BUnitTemplatesContent
          templatesByLang={mailTemplates[currentTemplateLang]}
          currentTemplateId={currentTemplateId}
          currentTemplateLang={currentTemplateLang}
        />

        <div className={styles['email-modal-buttons']}>
          <BUnitsMenuBtn
            type="reject"
            additionalClassNames={`${styles['decline-button']} ${styles['modal-button']}`}
            handleButtonClick={() => toggleModal(false)}
          >
            {tNLS('mail.tmpl.cancel')}
          </BUnitsMenuBtn>
          <BUnitsMenuBtn
            additionalClassNames={styles['modal-button']}
            handleButtonClick={() => {
              sendEmail();
            }}
          >
            {tNLS('mail.tmpl.send')}
          </BUnitsMenuBtn>
        </div>
      </Content>
      <WrapBtns />
    </Modal>
  );
}

BUnitEmailModal.propTypes = {
  isShowingModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default BUnitEmailModal;