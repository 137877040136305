// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".choose-all-children--kObYT{position:relative;display:flex;gap:0.5rem;align-items:center;margin-bottom:0.25rem;padding:0 0.5rem;color:var(--unit-tree-main-color);font-size:1.125rem}.choose-all-children--kObYT:hover{cursor:pointer}.choose-all-children--kObYT .unit-checkbox--af7hn{position:relative;top:1px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/BUnitBranch/SelectAllCheckBox/SelectAllCheckBox.module.sass"],"names":[],"mappings":"AAEA,4BACE,iBAAA,CACA,YAAA,CACA,UAAA,CACA,kBAAA,CACA,qBAAA,CACA,gBAAA,CACA,iCAAA,CACA,kBAAA,CACA,kCACE,cAAA,CACF,kDACE,iBAAA,CACA,OAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.choose-all-children\n  position: relative\n  display: flex\n  gap: func.rem(8)\n  align-items: center\n  margin-bottom: func.rem(4)\n  padding: 0 func.rem(8)\n  color: var(--unit-tree-main-color)\n  font-size: func.rem(18)\n  &:hover\n    cursor: pointer\n  .unit-checkbox\n    position: relative\n    top: 1px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"choose-all-children": "choose-all-children--kObYT",
	"unit-checkbox": "unit-checkbox--af7hn"
};
export default ___CSS_LOADER_EXPORT___;
