// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html[data-theme=dark] .wrap-criteria-list--jZrZZ{--icon-color: #ffffff}html[data-theme=light] .wrap-criteria-list--jZrZZ{--icon-color: #838999}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/WrapperCuts/Criteria/Criteria.module.sass"],"names":[],"mappings":"AAGE,iDACE,qBAAA,CAIF,kDACE,qBAAA","sourcesContent":["@use '../../../../../../../styles/functions' as func\n\nhtml[data-theme='dark']\n  .wrap-criteria-list\n    --icon-color: #ffffff\n\n\nhtml[data-theme='light']\n  .wrap-criteria-list\n    --icon-color: #838999"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-criteria-list": "wrap-criteria-list--jZrZZ"
};
export default ___CSS_LOADER_EXPORT___;
