// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".events-list-element--55cmW{display:flex}.events-list-element--55cmW .event-date--Eb-Kx{display:flex;justify-content:space-around;width:7.5rem}.events-list-element--55cmW .event-date--Eb-Kx .time--c3LsA{width:50%}.events-list-element--55cmW .event-date--Eb-Kx .date--3Byvb{width:50%}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Superset/SupersetEvens/SupersetEventsList/SupersetEvent/SupersetEvent.module.sass"],"names":[],"mappings":"AAEA,4BACE,YAAA,CACA,+CACE,YAAA,CACA,4BAAA,CACA,YAAA,CACA,4DACE,SAAA,CACF,4DACE,SAAA","sourcesContent":["@use \"@/styles/functions\" as func\n\n.events-list-element\n  display: flex\n  .event-date\n    display: flex\n    justify-content: space-around\n    width: func.rem(120)\n    .time\n      width: 50%\n    .date\n      width: 50%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"events-list-element": "events-list-element--55cmW",
	"event-date": "event-date--Eb-Kx",
	"time": "time--c3LsA",
	"date": "date--3Byvb"
};
export default ___CSS_LOADER_EXPORT___;
