import React, { RefObject, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styles from './DataCircle.module.sass';
import { RatingResultByScoreType } from '@/store/types/admin/superset/cuts/fourD';
import {
  useGetDidTheCircleChooseStatus, useTargetingSelectedObject,
} from '@/components/App/Admin/Results/Cuts/FourD/Diagram/CirclesArea/DataCircle/hooks';
import { getClearClassNames } from '@/helpers/helpers';
import { setSelectedObjectID } from '@/store/actions/admin/superset/process/cuts/fourD';

interface DataCircleProps {
  ratingItem: RatingResultByScoreType;
  circlesAreaWidth: number;
  circlesAreaHeight: number;
}

function DataCircle({ ratingItem, circlesAreaWidth, circlesAreaHeight }: DataCircleProps) {
  const dispatch = useDispatch();
  const {
    id, xPerc, yPerc, size, color,
  } = ratingItem;
  const circleRef = useRef<SVGCircleElement | null>(null);

  const isSelected = useGetDidTheCircleChooseStatus(id);
  useTargetingSelectedObject(circleRef, isSelected);

  const mapSizeToRadius = (cSize: number) => {
    const minRadius = 10;
    const maxRadius = 25;

    // using a linear interpolation formula
    return minRadius + ((cSize / 100) * (maxRadius - minRadius));
  };

  const calculateCX = (
    xPosition: number,
    areaWidth: number,
  ) => (
    (xPosition / 100) * areaWidth
  );

  const calculateCY = (
    yPosition: number,
    areaHeight: number,
  ) => (
    areaHeight - ((yPosition / 100) * areaHeight)
  );

  const onShowObjectInfo = (
    objectID: string,
    cRef: RefObject<SVGCircleElement>,
  ) => {
    if (cRef.current) {
      const rect = cRef.current.getBoundingClientRect();
      const circleCenterX = rect.left + rect.width / 2;
      const circleCenterY = rect.top + rect.height / 2;

      dispatch(setSelectedObjectID({
        id: objectID,
        circleCenterX,
        circleCenterY,
      }));
    }
  };

  return (
    <circle
      ref={circleRef}
      id={id}
      key={id}
      cx={calculateCX(xPerc, circlesAreaWidth)}
      cy={calculateCY(yPerc, circlesAreaHeight)}
      r={mapSizeToRadius(size)}
      className={getClearClassNames([
        styles['data-circle'],
        isSelected && styles['data-circle-highlighted'],
      ])}
      fill={color || '#138bc5'}
      onClick={() => { onShowObjectInfo(id, circleRef); }}
    />
  );
}

export default DataCircle;