// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-wrapper-group-checkboxes--8V7ky .wrap-checkboxes--U5thO{margin-top:1.25rem;display:flex;flex-wrap:wrap;gap:0.75rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionAdminSettings/WrapperGroupCheckboxesSetting/WrapperGroupCheckboxesSetting.module.sass"],"names":[],"mappings":"AAGE,8DACE,kBAAA,CACA,YAAA,CACA,cAAA,CACA,WAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.wrap-wrapper-group-checkboxes\n  .wrap-checkboxes\n    margin-top: func.rem(20)\n    display: flex\n    flex-wrap: wrap\n    gap: func.rem(12)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-wrapper-group-checkboxes": "wrap-wrapper-group-checkboxes--8V7ky",
	"wrap-checkboxes": "wrap-checkboxes--U5thO"
};
export default ___CSS_LOADER_EXPORT___;
