import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styles from './BUnitTransferModal.module.sass';
import Modal from '@/components/Common/Modal';
import Content from '@/components/Common/Modal/Content';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import SelectTargetDepartment from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitTransferModal/SelectTargetDepartment/SelectTargetDepartment';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { updateTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import {
  selectSelectedUnits,
  selectUnitsTreeData,
} from '@/store/selectors/admin/superset/unitsTree';
import UnitsForChangeList from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitTransferModal/UnitsForTransferList/UnitsForChangeList';
import SelectMenuTitle from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/SelectMenuTitle/SelectMenuTitle';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { unitTreeSlice } from '@/store/reducers/admin/superset/unitsTree';
import useGroupOperationNotify from '../BUnitActionsConfirmButton/useGroupOperationNotify';

function BUnitsTransferModal({ isShowingModal, toggleModal }) {
  const { units } = useSelector(selectUnitsTreeData);
  const selectedUnits = useSelector(selectSelectedUnits);
  const { supersetId } = useSelector(selectSuperset);
  const dispatch = useDispatch();
  const callNotify = useGroupOperationNotify();
  const [targetPath, setTargetPath] = useState({
    path: [],
    targetDepartmentId: '',
  });
  const tNLS = useNLS();

  const handleButtonClick = () => {
    const unitsData = selectedUnits.flatMap((unitId) => {
      if (units[unitId].parent === targetPath.targetDepartmentId) return [];
      return {
        ID: unitId,
        name: units[unitId].name,
        departments: targetPath.path,
      };
    });
    if (unitsData.length === 0) return;
    dispatch(
      updateTreeUnits({
        supersetId,
        unitsData,
      }),
    )
      .then((response) => {
        dispatch(
          unitTreeSlice.actions.transferUnitsInLocal({
            targetDepartmentId: targetPath.targetDepartmentId,
            unitsData,
          }),
        );
        callNotify('transferUnits', response.payload.length, 'success');
      })
      .catch(() => {
        callNotify('transferUnits', 0, 'error');
      });
  };

  return (
    <Modal
      isShowing={isShowingModal}
      hide={() => toggleModal(false)}
      title=""
      externalClassNames={{
        contentClass: styles['transfer-units-modal'],
        modal: styles['transfer-modal'],
      }}
    >
      <Content>
        <SelectMenuTitle />
        <UnitsForChangeList actionType="transfer" />
        <div>
          <div>{tNLS('tree.move.title')}</div>
          <SelectTargetDepartment
            targetPath={targetPath}
            selectTargetPath={setTargetPath}
          />
        </div>
        <BUnitsMenuBtn
          additionalClassNames={styles['transfer-button']}
          handleButtonClick={handleButtonClick}
        >
          {tNLS('tree.move.button.go')}
        </BUnitsMenuBtn>
      </Content>
    </Modal>
  );
}

BUnitsTransferModal.propTypes = {
  isShowingModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default BUnitsTransferModal;