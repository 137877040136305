// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".custom-select-label--LTAuB{display:flex;align-items:center;color:#fff;background:#565964;padding:0 0.875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectOptionLabel/CustomSelectOptionLabel.module.sass"],"names":[],"mappings":"AAEA,4BACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,kBAAA,CACA,kBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.custom-select-label\n  display: flex\n  align-items: center\n  color: #FFF\n  background: #565964\n  padding: 0 func.rem(14)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-select-label": "custom-select-label--LTAuB"
};
export default ___CSS_LOADER_EXPORT___;
