import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteAnswer,
  getProtocols,
  optOutOfEvaluation,
  sendAnswers,
  setAnswer,
  setProtocol,
  setRelations,
  setSeveralProtocols,
  setTestBlockAnswer,
} from '@/store/actions/user/protocols';
import {
  getDateInPostgreFormat,
} from '@/helpers/helpers';
import { getActor, selectProject } from '@/store/actions/user/actor';
import {
  GetProtocolsPayload,
  OptOutOfEvaluationPayload,
  ProtocolsState,
  SentProtocols,
  SetAnswerPayload, SetRelationArgs,
  SetTestBlockAnswer,
} from '@/store/types/user/protocols';
import { Project } from '@/store/types/user/actor';
import parceProtocolsArray from './protocolsHelpers/protocolsHelpers';
import { setAnswersInLocalStorage } from './protocolsHelpers/protocolsLocalStorage';

const initialState: ProtocolsState = {
  protocols: {},
  relations: {},
  relationsComments: {},
  updatedRelationsComments: {},
  updatedItems: {},
  updatedRelations: {},
  isHandleActionOnPage: false,
  deletedProtocols: {},
  deletedRelations: {},
  lastItemFormType: '',
  protocolsTime: {},
  relationsTime: {},
  commentsTime: {},
  lastUpdatedItem: '',
  isChanged: false,
  sentProtocols: [],
  projectId: undefined,
  isAnswersInProcess: false,
};

export const protocolsSlice = createSlice({
  name: 'protocols',
  initialState,
  reducers: {
    clearProtocols: (state) => {
      state.protocols = {};
      state.relations = {};
      state.updatedItems = {};
      state.updatedRelations = {};
      state.protocolsTime = {};
      state.relationsComments = {};
      state.commentsTime = {};
      state.updatedRelationsComments = {};
    },
    toggleUserActionOnPage: (state, { payload }: PayloadAction<{ newValue: boolean }>) => {
      const { newValue } = payload;
      state.isHandleActionOnPage = newValue;
    },
    catchUserAction: (state) => {
      state.isChanged = true;
    },
    deleteItemRelations: (state, { payload }: PayloadAction<{ id: string }>) => {
      const { id } = payload;
      const deletedItemRelations = state.deletedRelations[id] ? { ...state.deletedRelations[id] } : {};
      const itemRelations = state.relations[id] ? { ...state.relations[id] } : {};
      state.deletedRelations[id] = { ...deletedItemRelations, ...itemRelations };
      delete state.relations[id];
    },
    getUserActorId: (state, { payload }: PayloadAction<string>) => {
      state.actorId = payload;
    },
    getUserProjectId: (state, { payload }: PayloadAction<string>) => {
      state.projectId = payload;
    },
    deleteLastUpdatedItem: (state) => {
      state.lastUpdatedItem = '';
    },
    deleteAnswer: (state, { payload }: PayloadAction<SetAnswerPayload>) => {
      const { id, unitId } = payload;
      if (unitId) {
        delete state.relations[id][unitId];
        delete state.updatedRelations[id][unitId];
      } else {
        state.deletedProtocols[id] = state.protocols[id];
        delete state.updatedItems[id];
        delete state.protocols[id];
        state.lastUpdatedItem = id;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAnswer.fulfilled, (state, { payload }:PayloadAction<SetAnswerPayload>) => {
        const {
          id, unitId, formType, actorId, projectId,
        } = payload;

        state.isChanged = true;
        state.lastItemFormType = formType || '';
        if (!unitId) {
          state.protocolsTime[id] = getDateInPostgreFormat();
          if (state.deletedProtocols[id]) delete state.deletedProtocols[id];
        }

        state.lastUpdatedItem = id;
        // записываем несохраненные ответы в localStorage
        setAnswersInLocalStorage({ actorId, projectId, ...state });
      })
      .addCase(setProtocol.fulfilled, (state, { payload }) => {
        const { id, value } = payload;
        state.isChanged = true;
        state.isHandleActionOnPage = true;
        state.updatedItems[id] = value;
        state.protocols[id] = value;
        state.protocolsTime[id] = getDateInPostgreFormat();
      })
      .addCase(selectProject.fulfilled, (state, { payload }: PayloadAction<Project>) => {
        const { projectId } = payload;
        state.projectId = projectId;
      })
      .addCase(getActor.fulfilled, (state, { payload }: PayloadAction<{ id: string }>) => {
        const { id } = payload;
        state.actorId = id;
      })
      .addCase(setRelations.fulfilled, (state, { payload }:PayloadAction<SetRelationArgs>) => {
        const {
          id, value, unitId, isComment,
        } = payload;

        if (isComment) {
          if (!state.relationsComments[id]) state.relationsComments[id] = {};
          if (!state.updatedRelationsComments[id]) state.updatedRelationsComments[id] = {};
          if (!state.commentsTime[id]) state.commentsTime[id] = {};
          state.relationsComments[id][unitId] = value as string;
          state.updatedRelationsComments[id][unitId] = value as string;
          state.commentsTime[id][unitId] = getDateInPostgreFormat();
        } else {
          if (!state.relations[id]) state.relations[id] = {};
          if (!state.updatedRelations[id]) state.updatedRelations[id] = {};
          if (!state.relationsTime[id]) state.relationsTime[id] = {};
          state.relations[id][unitId] = value;
          state.updatedRelations[id][unitId] = value;
          state.relationsTime[id][unitId] = getDateInPostgreFormat();
        }
      })
      .addCase(getProtocols.fulfilled, (state, { payload }: PayloadAction<GetProtocolsPayload>) => {
        const { degreeItemsByPagesFromState, actorId, projectId } = payload;
        const parcedData = parceProtocolsArray(payload.data || [], degreeItemsByPagesFromState, actorId, projectId);

        return { ...state, ...parcedData };
      })
      // @ts-ignore
      .addCase(deleteAnswer.fulfilled, (state, { payload }: PayloadAction<SetAnswerPayload>) => {
        const { id, unitId } = payload;
        if (unitId) {
          if (!state.deletedRelations[id]) state.deletedRelations[id] = {};
          state.deletedRelations[id][unitId] = state.relations[id][unitId];
          if (state.relations[id]) delete state.relations[id][unitId];
          if (state.updatedRelations[id]) delete state.updatedRelations[id][unitId];
        } else {
          state.deletedProtocols[id] = state.protocols[id];
          delete state.updatedItems[id];
          delete state.protocols[id];
          state.lastUpdatedItem = id;
        }
      })
      .addCase(optOutOfEvaluation.fulfilled, (state, { payload }: PayloadAction<OptOutOfEvaluationPayload>) => {
        const { questions, unitId } = payload;
        questions.forEach((question) => {
          state.relations[question.id] = {
            ...state.relations[question.id],
            [unitId]: question.value,
          };
          state.updatedRelations[question.id] = {
            ...state.updatedRelations[question.id],
            [unitId]: question.value,
          };
        });
        setAnswersInLocalStorage({
          ...state,
        });
      })
      .addCase(setSeveralProtocols.fulfilled, (state, { payload }) => {
        const { valuesWithId, protocolsTime, itemDeps } = payload;
        const updatedItemsWithDeps = Object.keys(valuesWithId).filter(
          (id) => itemDeps[id],
        );
        state.protocolsTime = { ...state.protocolsTime, ...protocolsTime };
        state.protocols = { ...state.protocols, ...valuesWithId };
        state.updatedItems = { ...state.updatedItems, ...valuesWithId };
        if (updatedItemsWithDeps.length) [state.lastUpdatedItem] = updatedItemsWithDeps;
        setAnswersInLocalStorage({
          ...state,
        });
      })
      .addCase(setTestBlockAnswer.fulfilled, (state, { payload }: PayloadAction<SetTestBlockAnswer>) => {
        const { itemId, answer, time } = payload;
        state.protocols[itemId] = answer;
        state.protocolsTime[itemId] = time;
      })
      .addCase(sendAnswers.pending, (state) => {
        state.isAnswersInProcess = true;
      })
      .addCase(sendAnswers.fulfilled, (state, { payload }: PayloadAction<{ sentProtocols: SentProtocols, actorId: string, projectId: string }>) => {
        state.isAnswersInProcess = false;
        const { sentProtocols, actorId, projectId } = payload;
        localStorage.removeItem(
          `${actorId}-${projectId}-unsaved-answers`,
        );

        sentProtocols.forEach(({ itemId, answer, unitId }) => {
          if (!unitId) {
            if (state.updatedItems[itemId] === answer) delete state.updatedItems[itemId];
          } else if (
            state.updatedRelations[itemId]
            && state.updatedRelations[itemId][unitId] === answer
          ) {
            delete state.updatedRelations[itemId][unitId];
          }
        });
      });
  },
});

const { reducer } = protocolsSlice;

export default reducer;