// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".diagram-container--wOyHD{width:100%;position:relative;margin-top:2.5rem}.diagram-container--wOyHD .graph--ZaLY5{width:100%;height:500px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FourD/Diagram/Diagram.module.sass"],"names":[],"mappings":"AAEA,0BACE,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,wCACE,UAAA,CACA,YAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.diagram-container\n  width: 100%\n  position: relative\n  margin-top: func.rem(40)\n  .graph\n    width: 100%\n    height: 500px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"diagram-container": "diagram-container--wOyHD",
	"graph": "graph--ZaLY5"
};
export default ___CSS_LOADER_EXPORT___;
