import React, { Dispatch, SetStateAction, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '@/components/Common/Modal';
import CalculateResultsModalContent from '@/components/App/Admin/Results/LoadResults/CalculateResults/CalculateResultsModal/CalculateResultsModalContent/CalculateResultsModalContent';
import Content from '@/components/Common/Modal/Content';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { startProcessor } from '@/store/actions/admin/superset/process/process';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { UnitsFilters } from './CalculateResultsModalContent/SelectUnitsFilter/SelectUnitFilter/SelectUnitFilterRadio';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import selectStatusProcessorResult from '@/store/selectors/admin/superset/process/process';

interface CalculateResultsModalProps {
  isModalOpen: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
}

function CalculateResultsModal({ isModalOpen, toggleModal }: CalculateResultsModalProps) {
  const [selectedCheckpointId, setSelectedCheckpointId] = useState('');
  const [selectedFilterId, setSelectedFilterId] = useState<UnitsFilters>(UnitsFilters.WithoutFilter);
  const [selectedSpecs, setSelectedSpecs] = useState([]);
  const tNLS = useNLS();

  const dispatch = useAdminDispatch();
  const { supersetId } = useAdminSelector(selectSuperset);
  const { hasEvaluated } = useSelector(selectStatusProcessorResult);
  const hideModal = () => {
    toggleModal(false);
    setSelectedCheckpointId('');
  };

  return (
    <Modal
      isShowing={isModalOpen}
      title={tNLS('results.calculate.header')}
      hide={hideModal}
    >
      <Content>
        <CalculateResultsModalContent
          selectedCheckpointId={selectedCheckpointId}
          setSelectedCheckpointId={setSelectedCheckpointId}
          selectedSpecs={selectedSpecs}
          setSelectedSpecs={setSelectedSpecs}
          setSelectedFilterId={setSelectedFilterId}
          selectedFilterId={selectedFilterId}
        />
        <CustomButton
          styleTypeBtn="btn-blue btn-large"
          onClick={() => {
            const dataForm = {
              supersetId,
              checkpointId: selectedCheckpointId,
              useSelected: selectedFilterId === UnitsFilters.BySelected ? 'true' : 'false',
            };

            if (selectedFilterId === UnitsFilters.BySpec && selectedSpecs.length) {
              // @ts-ignore
              dataForm.specs = selectedSpecs;
            }

            // @ts-ignore
            dispatch(startProcessor(dataForm));
            hideModal();
          }}
        >
          {tNLS(hasEvaluated ? 'results.recalculate' : 'results.calculate')}
        </CustomButton>
      </Content>
    </Modal>
  );
}

CalculateResultsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default CalculateResultsModal;