import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectRatings } from '@/store/selectors/admin/superset/process/cuts/fourD';
import CustomSelect from '../../../CustomSelect';
import useTransformRatingToGroupedRatingsOptions from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/hooks/hooks';
import { Option } from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/FourDParamsForm';
import BUnitSelectMenuRatingSection from './BUnitSelectMenuRatingSection/BUnitSelectMenuRatingSection';
import styles from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/SelectMenu.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

interface BUnitSelectMenuRatingProps {
  selectedRatingId: string;
  setSelectedRatingId: Dispatch<SetStateAction<string>>;
}

export default function BUnitSelectMenuRating({ selectedRatingId, setSelectedRatingId }: BUnitSelectMenuRatingProps) {
  const ratings = useAdminSelector(selectRatings);
  const ratingOptions: { label: string, options: Option[] }[] = useTransformRatingToGroupedRatingsOptions(ratings);

  const [isOpen, toggleOpen] = useState(false);
  const [selectedRatingName, setSelectedRatingName] = useState('');

  const hanleSelectName = (id: string, name: string) => {
    setSelectedRatingId(id);
    setSelectedRatingName(name);
    toggleOpen(false);
  };

  useEffect(
    () => {
      if (!selectedRatingId && ratingOptions?.length) {
        const { options } = ratingOptions[0];
        const { label, value } = options[0];
        setSelectedRatingId(`${value}`);
        setSelectedRatingName(label);
      }
    },
    [ratingOptions, selectedRatingId, setSelectedRatingId],
  );

  useEffect(() => () => setSelectedRatingId(''), [setSelectedRatingId]);

  if (!ratings?.length) return null;

  return (
    <CustomSelect
      activeValue={selectedRatingName}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      type="button"
      mainBtnClassNames={getClearClassNames([
        styles['select-option'],
        styles.ratings,
      ])}
    >
      {ratingOptions
        .map(({ options, label }) => (
          <BUnitSelectMenuRatingSection
            key={label}
            options={options}
            selectRatingId={hanleSelectName}
            selectedRatingId={selectedRatingId}
            sectionLabel={label}
          />
        ))}
    </CustomSelect>
  );
}