// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-tabs--HjVAJ .panes-labels--pQrOe{display:flex}.wrap-tabs--HjVAJ .panes-labels--pQrOe .pane-label--cvEoE{background-color:#565964;border-right:1px solid #3c3f47;transition:background-color .3s ease 0s}.wrap-tabs--HjVAJ .panes-labels--pQrOe .pane-label--cvEoE:first-child{border-radius:8px 0 0 8px}.wrap-tabs--HjVAJ .panes-labels--pQrOe .pane-label--cvEoE:last-child{border-radius:0 8px 8px 0;border-right:none}.wrap-tabs--HjVAJ .panes-labels--pQrOe .pane-label--cvEoE.active--kfSXa{background-color:#2279fc}.wrap-tabs--HjVAJ .panes-labels--pQrOe .pane-label--cvEoE:hover{background-color:#2279fc}.wrap-tabs--HjVAJ .panes-labels--pQrOe .pane-label--cvEoE .btn-pane--86fWU{color:#fff;font-size:1rem;padding:0.9375rem 1.25rem}", "",{"version":3,"sources":["webpack://./src/components/Common/Tabs/Tabs.module.sass"],"names":[],"mappings":"AAGE,uCACE,YAAA,CACA,0DACE,wBAAA,CACA,8BAAA,CACA,uCAAA,CACA,sEACE,yBAAA,CACF,qEACE,yBAAA,CACA,iBAAA,CACF,wEACE,wBAAA,CACF,gEACE,wBAAA,CACF,2EACE,UAAA,CACA,cAAA,CACA,yBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.wrap-tabs\n  .panes-labels\n    display: flex\n    .pane-label\n      background-color: #565964\n      border-right: 1px solid #3C3F47\n      transition: background-color 0.3s ease 0s\n      &:first-child\n        border-radius: 8px 0 0 8px\n      &:last-child\n        border-radius: 0 8px 8px 0\n        border-right: none\n      &.active\n        background-color: #2279FC\n      &:hover\n        background-color: #2279FC\n      .btn-pane\n        color: #FFFFFF\n        font-size: func.rem(16)\n        padding: func.rem(15) func.rem(20)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-tabs": "wrap-tabs--HjVAJ",
	"panes-labels": "panes-labels--pQrOe",
	"pane-label": "pane-label--cvEoE",
	"active": "active--kfSXa",
	"btn-pane": "btn-pane--86fWU"
};
export default ___CSS_LOADER_EXPORT___;
