// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".question--UNRAm{font-size:1.125rem;margin:0 0 1rem 0}.buttons--xKLtq{display:flex;gap:0.75rem;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitConfirmActionModal/BUnitConfirmActionModal.module.sass"],"names":[],"mappings":"AAEA,iBACE,kBAAA,CACA,iBAAA,CAEF,gBACE,YAAA,CACA,WAAA,CACA,wBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.question\n  font-size: func.rem(18)\n  margin: 0 0 func.rem(16) 0\n\n.buttons\n  display: flex\n  gap: func.rem(12)\n  justify-content: flex-end\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question": "question--UNRAm",
	"buttons": "buttons--xKLtq"
};
export default ___CSS_LOADER_EXPORT___;
