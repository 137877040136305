// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".invite-link--N55KE{padding:10px;border-radius:4px;background-color:rgba(160,160,160,.5);cursor:pointer}.invite-link--N55KE:hover{text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/components/App/User/Common/ResultLink/ResultLink.module.sass"],"names":[],"mappings":"AAAA,oBACE,YAAA,CACA,iBAAA,CACA,qCAAA,CACA,cAAA,CACA,0BACE,yBAAA","sourcesContent":[".invite-link\n  padding: 10px\n  border-radius: 4px\n  background-color: rgba(160,160,160,0.5)\n  cursor: pointer\n  &:hover\n    text-decoration: underline"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invite-link": "invite-link--N55KE"
};
export default ___CSS_LOADER_EXPORT___;
