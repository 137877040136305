import { Dispatch, SetStateAction, useState } from 'react';
// import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { getSelectActionByType } from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/helpers';

export enum UnitsSelectActionFlags {
  Select = 'select',
  Unselect = 'unselect',
}

export type UnitsSelectActionNlsByFlagType = Record<UnitsSelectActionFlags, string>;

export const UnitsSelectActionNlsByFlag: UnitsSelectActionNlsByFlagType = {
  [UnitsSelectActionFlags.Select]: 'tree.select',
  [UnitsSelectActionFlags.Unselect]: 'tree.unselect',
};

export interface BUnitSelectAction {
  key: UnitsSelectActionFlags;
  name: string
}

export type SetCurrentSelectActionType = Dispatch<SetStateAction<BUnitSelectAction>>;
const useSelectAction = (): [BUnitSelectAction, SetCurrentSelectActionType] => {
  // todo Сделать совместимым со старым ключом
  // const [, readFromLocalStorage] = useLocalStorageByAdmin<BUnitSelectAction>('selectActions');
  const tNLS = useNLS();
  const [currentSelectAction, setCurrentSelectAction] = useState<BUnitSelectAction>(
    getSelectActionByType(UnitsSelectActionFlags.Select, tNLS),
  );

  return [currentSelectAction, setCurrentSelectAction];
};

export default useSelectAction;