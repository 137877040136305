// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".default-input-container--ftVay{position:relative;padding:0.125rem 0.25rem;border-radius:0.5rem}.default-input-container--ftVay .error-message--jmVlv{position:absolute;color:var(--error-color);font-size:0.75rem;bottom:100%;left:0}.default-input-container--ftVay.visible--zj225{border:solid .5px var(--secondary-color)}.default-input--DdjR1{font-size:1rem;width:100%;color:var(--main-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsTree/UnitsTree/CustomInput/CustomInput.module.sass"],"names":[],"mappings":"AAEA,gCACE,iBAAA,CACA,wBAAA,CACA,oBAAA,CACA,sDACE,iBAAA,CACA,wBAAA,CACA,iBAAA,CACA,WAAA,CACA,MAAA,CACF,+CACE,wCAAA,CAEJ,sBACE,cAAA,CACA,UAAA,CACA,uBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.default-input-container\n  position: relative\n  padding: func.rem(2) func.rem(4)\n  border-radius: func.rem(8)\n  .error-message\n    position: absolute\n    color: var(--error-color)\n    font-size: func.rem(12)\n    bottom: 100%\n    left: 0\n  &.visible\n    border: solid 0.5px var(--secondary-color)\n\n.default-input\n  font-size: func.rem(16)\n  width: 100%\n  color: var(--main-color)\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default-input-container": "default-input-container--ftVay",
	"error-message": "error-message--jmVlv",
	"visible": "visible--zj225",
	"default-input": "default-input--DdjR1"
};
export default ___CSS_LOADER_EXPORT___;
