// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-with-path--AGbWU{color:var(--main-color);display:flex}.unit-with-path--AGbWU .unselect-button--4iaSx{align-self:baseline;padding-top:0.3125rem;font-size:1rem}.unit-with-path--AGbWU .unit-name--G22we{font-size:1.125rem;display:flex;flex-direction:column}.unit-with-path--AGbWU .unit-path--zjvxF{font-size:0.875rem;color:var(--secondary-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitTransferModal/UnitsForTransferList/UnitForTransfer/UnitForTransfer.module.sass"],"names":[],"mappings":"AAEA,uBACE,uBAAA,CACA,YAAA,CACA,+CACE,mBAAA,CACA,qBAAA,CACA,cAAA,CACF,yCACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACF,yCACE,kBAAA,CACA,4BAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.unit-with-path\n  color: var(--main-color)\n  display: flex\n  .unselect-button\n    align-self: baseline\n    padding-top: func.rem(5)\n    font-size: func.rem(16)\n  .unit-name\n    font-size: func.rem(18)\n    display: flex\n    flex-direction: column\n  .unit-path\n    font-size: func.rem(14)\n    color: var(--secondary-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-with-path": "unit-with-path--AGbWU",
	"unselect-button": "unselect-button--4iaSx",
	"unit-name": "unit-name--G22we",
	"unit-path": "unit-path--zjvxF"
};
export default ___CSS_LOADER_EXPORT___;
