// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".admin-superset-container--H5Bkd{border:1px solid var(--tab-border-color);position:relative;border-radius:20px}.header-content-container--NyfSW{position:relative;max-width:68.75rem;margin:0 auto}.header-content-container--NyfSW .link-to-projects--wxeby{margin-bottom:0.9375rem;display:inline-block}.header-content-container--NyfSW .wrap-right-controls--sjdYj{position:absolute;right:0;bottom:0.3125rem;display:flex;align-items:center;gap:0.625rem}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/MainContentContainer/AdminSupersetContainer/AdminSupersetContainer.module.sass"],"names":[],"mappings":"AAEA,iCACE,wCAAA,CACA,iBAAA,CACA,kBAAA,CACF,iCACE,iBAAA,CACA,kBAAA,CACA,aAAA,CACA,0DACE,uBAAA,CACA,oBAAA,CACF,6DACE,iBAAA,CACA,OAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,YAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.admin-superset-container\n  border: 1px solid var(--tab-border-color)\n  position: relative\n  border-radius: 20px\n.header-content-container\n  position: relative\n  max-width: func.rem(1100)\n  margin: 0 auto\n  .link-to-projects\n    margin-bottom: func.rem(15)\n    display: inline-block\n  .wrap-right-controls\n    position: absolute\n    right: 0\n    bottom: func.rem(5)\n    display: flex\n    align-items: center\n    gap: func.rem(10)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin-superset-container": "admin-superset-container--H5Bkd",
	"header-content-container": "header-content-container--NyfSW",
	"link-to-projects": "link-to-projects--wxeby",
	"wrap-right-controls": "wrap-right-controls--sjdYj"
};
export default ___CSS_LOADER_EXPORT___;
