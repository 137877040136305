import React from 'react';
import Tabs from '@/components/Common/Tabs';
import styles from './FilterSupersetsTabs.module.sass';
import TabPane from '@/components/Common/Tabs/TabPane';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import useLocalStorageByAdmin from '../../BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';

interface FilterSupersetsProps {
  filterFlag: FilterSupersetsFlag,
  setFilterFlag: (arg: FilterSupersetsFlag) => void;
}

export enum FilterSupersetsFlag {
  ACTIVE = 'active',
  TEMPLATES = 'templates',
  ALL = 'all',
  ARCHIVE = 'archive',
}

function FilterSupersetsTabs({ filterFlag, setFilterFlag }: FilterSupersetsProps) {
  const checkPermission = useCheckPermission();

  const [writeInLocal] = useLocalStorageByAdmin<FilterSupersetsFlag>(
    'filter-superset-flag',
    false,
    true,
  );

  const handleSetFlag = (flag: FilterSupersetsFlag) => {
    setFilterFlag(flag);
    writeInLocal(flag);
  };

  return (
    <Tabs
      selectedTabByID={`supersets.tab.${filterFlag}`}
      externalClasses={{
        wrapTabs: styles['select-superset-tabs'],
        paneLabel: styles['select-superset-label'],
        panesLabels: styles['select-superset-labels'],
        activePaneLabel: styles['active-label'],
        labelButton: styles['label-btn'],
      }}
    >
      {/* @ts-ignore */}
      <TabPane
        key={`supersets.tab.${FilterSupersetsFlag.ACTIVE}`}
        // @ts-ignore
        id={`supersets.tab.${FilterSupersetsFlag.ACTIVE}`}
        label="Active"
        onClick={() => handleSetFlag(FilterSupersetsFlag.ACTIVE)}
      />
      {/* @ts-ignore */}
      <TabPane
        key={`supersets.tab.${FilterSupersetsFlag.ARCHIVE}`}
        // @ts-ignore
        id={`supersets.tab.${FilterSupersetsFlag.ARCHIVE}`}
        label="Archive"
        onClick={() => handleSetFlag(FilterSupersetsFlag.ARCHIVE)}
      />
      {/* @ts-ignore */}
      <TabPane
        key={`supersets.tab.${FilterSupersetsFlag.ALL}`}
        // @ts-ignore
        id={`supersets.tab.${FilterSupersetsFlag.ALL}`}
        label="All"
        onClick={() => handleSetFlag(FilterSupersetsFlag.ALL)}
      />
      {checkPermission() && (
        // @ts-ignore
        <TabPane
          key={`supersets.tab.${FilterSupersetsFlag.TEMPLATES}`}
          // @ts-ignore
          id={`supersets.tab.${FilterSupersetsFlag.TEMPLATES}`}
          label="Templates"
          onClick={() => handleSetFlag(FilterSupersetsFlag.TEMPLATES)}
        />
      )}
    </Tabs>
  );
}

export default FilterSupersetsTabs;