// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".webinars-list--tyjbI{display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/Webinars/WebinarsList/WebinarsFilteredList/WebinarsFilteredList.module.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.webinars-list\n  display: flex\n  flex-direction: column\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"webinars-list": "webinars-list--tyjbI"
};
export default ___CSS_LOADER_EXPORT___;
