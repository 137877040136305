import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './Diagram.module.sass';
import CirclesArea from '@/components/App/Admin/Results/Cuts/FourD/Diagram/CirclesArea/CirclesArea';
import Axes from '@/components/App/Admin/Results/Cuts/FourD/Diagram/Axes';
import ListSelectedItems from '@/components/App/Admin/Results/Cuts/FourD/Diagram/ListSelectedItems';
import { useDrawingAreaSelectedObjects } from '@/components/App/Admin/Results/Cuts/FourD/hooks';
import { selectSelectedObject } from '@/store/selectors/admin/superset/process/cuts/fourD';
import ObjectInfoWindow from '@/components/App/Admin/Results/Cuts/FourD/Diagram/ObjectInfoWindow';

function Diagram() {
  const SVGContainerRef = useRef<SVGSVGElement | null>(null);
  const [svgContainerWidth, setSVGContainerWidth] = useState(0);
  const selectedObject = useSelector(selectSelectedObject);

  const {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    selectionRect,
  } = useDrawingAreaSelectedObjects(SVGContainerRef);

  useEffect(() => {
    if (SVGContainerRef.current) {
      setSVGContainerWidth(SVGContainerRef.current.clientWidth);
    }
  }, []);

  // TODO добавить анимацию при клике на круг

  return (
    <div className={styles['diagram-container']}>
      <svg
        ref={SVGContainerRef}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        className={styles.graph}
      >
        <Axes svgContainerWidth={svgContainerWidth} />

        {svgContainerWidth > 0 && (
        <CirclesArea
          svgContainerWidth={svgContainerWidth}
        />
        )}

        {selectionRect && (
        <rect
          x={selectionRect.x}
          y={selectionRect.y}
          width={selectionRect.width}
          height={selectionRect.height}
          fill="rgba(0, 120, 215, 0.2)"
          stroke="#0078d7"
          strokeDasharray="4"
        />
        )}

      </svg>
      {selectedObject && (
        <ObjectInfoWindow
          svgContainerRef={SVGContainerRef}
          selectedObject={selectedObject}
        />
      )}
      <ListSelectedItems
        svgContainerWidth={svgContainerWidth}
        selectionRect={selectionRect}
      />
    </div>
  );
}

export default Diagram;