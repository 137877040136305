import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HeaderResults from 'src/components/App/Admin/Results/Cuts/HeaderResults';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import useProcessor from '@/components/App/Admin/Results/hooks/useProcessor';
import { DURING_SURVEY_PROCESS } from '@/constants/admin';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';
import styles from './LoadResults.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';

function LoadResults() {
  const tNLS = useNLS();
  const resultPage = <Outlet />;
  const { supersetId, stage } = useSelector(selectSuperset);
  const { received, hasEvaluated, isProcessorRunning } = useProcessor(
    supersetId,
    stage,
  );
  const resultsNotCalculatedYet = received && !hasEvaluated && !isProcessorRunning;

  if (stage < DURING_SURVEY_PROCESS) {
    return <div>{tNLS('results.bad.stage')}</div>;
  }

  if (!received) {
    return <Preloader />;
  }

  if (received && !hasEvaluated && isProcessorRunning) {
    return <Preloader />;
  }

  if (resultsNotCalculatedYet) {
    return <HeaderResults resultsNotCalculatedYet={resultsNotCalculatedYet} />;
  }

  return (
    <div className={styles['wrap-result']}>
      <HeaderResults />
      {resultPage}
    </div>
  );
}

export default LoadResults;