// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-scales--YLEM4{position:relative;height:50px}.wrap-scales--YLEM4 .scale--xmLxT{position:absolute;top:50%;transform:translateY(-50%);width:50px;text-align:center}.wrap-scales--YLEM4 .scale--xmLxT.scale-1--CgVV9{left:calc(0% - 25px)}.wrap-scales--YLEM4 .scale--xmLxT.scale-2--3ubaR{left:calc(15% - 25px)}.wrap-scales--YLEM4 .scale--xmLxT.scale-3--QnJ0P{left:calc(30% - 25px)}.wrap-scales--YLEM4 .scale--xmLxT.scale-4--8dZRG{left:calc(50% - 25px)}.wrap-scales--YLEM4 .scale--xmLxT.scale-5--vF5Lz{left:calc(70% - 25px)}.wrap-scales--YLEM4 .scale--xmLxT.scale-6--B4rl5{left:calc(85% - 25px)}.wrap-scales--YLEM4 .scale--xmLxT.scale-7--NzCUu{left:calc(100% - 25px)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FactorsCuts/PercentileFS/Scales/Scales.module.sass"],"names":[],"mappings":"AAAA,oBACE,iBAAA,CACA,WAAA,CACA,kCACE,iBAAA,CACA,OAAA,CACA,0BAAA,CACA,UAAA,CACA,iBAAA,CACA,iDACE,oBAAA,CACF,iDACE,qBAAA,CACF,iDACE,qBAAA,CACF,iDACE,qBAAA,CACF,iDACE,qBAAA,CACF,iDACE,qBAAA,CACF,iDACE,sBAAA","sourcesContent":[".wrap-scales\n  position: relative\n  height: 50px\n  .scale\n    position: absolute\n    top: 50%\n    transform: translateY(-50%)\n    width: 50px\n    text-align: center\n    &.scale-1\n      left: calc(0% - 25px)\n    &.scale-2\n      left: calc(15% - 25px)\n    &.scale-3\n      left: calc(30% - 25px)\n    &.scale-4\n      left: calc(50% - 25px)\n    &.scale-5\n      left: calc(70% - 25px)\n    &.scale-6\n      left: calc(85% - 25px)\n    &.scale-7\n      left: calc(100% - 25px)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-scales": "wrap-scales--YLEM4",
	"scale": "scale--xmLxT",
	"scale-1": "scale-1--CgVV9",
	"scale-2": "scale-2--3ubaR",
	"scale-3": "scale-3--QnJ0P",
	"scale-4": "scale-4--8dZRG",
	"scale-5": "scale-5--vF5Lz",
	"scale-6": "scale-6--B4rl5",
	"scale-7": "scale-7--NzCUu"
};
export default ___CSS_LOADER_EXPORT___;
