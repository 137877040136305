import React, {
  ChangeEvent, Dispatch, SetStateAction, useMemo,
} from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import styles from './SelectSpecs.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { getClearClassNames, sortByAlphabet } from '@/helpers/helpers';
import { BackendUnit } from '@/store/types/admin/superset/unitsTree';

interface SelectSpecsProps {
  selectedSpecs: string[];
  setSelectedSpecs: Dispatch<SetStateAction<string[]>>;
  unitsArray?: BackendUnit[];
}

export default function SelectSpecs({ selectedSpecs, setSelectedSpecs, unitsArray = [] }: SelectSpecsProps) {
  const { specs } = useAdminSelector(selectSuperset);
  const tNLS = useNLS();

  const unitsForCalculateCount = useMemo(() => {
    const unitsBySpecsCount: Record<string, number> = {};

    unitsArray.forEach(({ spec }) => {
      spec.forEach((sp) => {
        if (!(sp in unitsBySpecsCount)) unitsBySpecsCount[sp] = 0;

        if (spec.includes(sp)) unitsBySpecsCount[sp] += 1;
      });
    });

    return unitsBySpecsCount;
  }, [unitsArray]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>, sp: string) => {
    const val = e.target.checked;
    if (val) {
      setSelectedSpecs((prevValue) => [...prevValue, sp]);
    } else {
      setSelectedSpecs((prevValue) => prevValue.filter((spec) => spec !== sp));
    }
  };

  const selectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked;

    if (val) {
      setSelectedSpecs(specs.filter((sp: string) => !!sp));
    } else {
      setSelectedSpecs([]);
    }
  };

  const isSelectAllChecked = () => specs.filter((sp: string) => !!sp).length === selectedSpecs.length;

  if (!specs || !specs.filter((sp: string) => !!sp).length) return null;

  return (
    <div className={styles['sp-list']}>
      <label
        htmlFor="spec-sell-all"
        className={getClearClassNames([
          styles['select-sp-label'],
          styles['select-all-label'],
        ])}
      >
        <input
          id="spec-sell-all"
          type="checkbox"
          checked={isSelectAllChecked()}
          onChange={(e) => selectAll(e)}
        />
        <span>{tNLS('results.calculate.with.selected.specs.all')}</span>
      </label>
      {specs
        .filter((sp: string) => !!sp && sp in unitsForCalculateCount)
        .sort(sortByAlphabet)
        .map((sp: string) => (
          <label
            key={`${sp}-spec`}
            htmlFor={`${sp}-spec`}
            className={styles['select-sp-label']}
          >
            <input
              id={`${sp}-spec`}
              type="checkbox"
              checked={selectedSpecs.includes(sp)}
              onChange={(e) => handleChangeInput(e, sp)}
            />
            <span>{sp}</span>
            <span className={styles['units-count']}>{`(${unitsForCalculateCount[sp]})`}</span>
          </label>
        ))}
      <div className={styles['selected-count']}>
        {`${tNLS('results.calculate.specs.selected')} ${selectedSpecs.length}`}
      </div>
    </div>
  );
}