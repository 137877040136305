import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { makeUnitsGroupOperations } from '@/store/actions/admin/superset/unitsTree';
import {
  UnitActionsKeysByOperationType,
  UnitOperationsWithModal,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/useBUnitGroupAction';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import useGroupOperationNotify from './useGroupOperationNotify';
import LoadingStatus from '@/constants/enums';
// import styles from './BUnitActionsConfirmButton.module.sass';

interface BUnitActionsConfirmButtonProps {
  toggleModal: Dispatch<SetStateAction<boolean>>
  operationType: keyof typeof UnitActionsKeysByOperationType
}

function BUnitActionsConfirmButton({ toggleModal, operationType }: BUnitActionsConfirmButtonProps) {
  const tNLS = useNLS();
  const callGroupNotify = useGroupOperationNotify();
  const dispatch = useAdminDispatch();
  const { supersetId } = useSelector(selectSuperset);
  const { groupOperationProgress } = useAdminSelector((state) => state.admin.superset.unitsTree);

  const executeOperation = () => {
    toggleModal(false);
    if (operationType) {
      dispatch(
        makeUnitsGroupOperations({
          supersetId,
          operationType,
        }),
      )
        .then((response) => {
          const { units } = response.payload;
          callGroupNotify(operationType, Object.keys(units).length || 0, 'success');
        })
        .catch(() => {
          callGroupNotify(operationType, 0, 'error');
        });
    }
  };

  const handleButtonClick = () => {
    if (UnitOperationsWithModal.includes(operationType)) {
      toggleModal(true);
      return;
    }
    executeOperation();
  };

  return (
    <BUnitsMenuBtn
      isPreloader={groupOperationProgress === LoadingStatus.Loading}
      handleButtonClick={handleButtonClick}
    >
      {tNLS('tree.select.go')}
    </BUnitsMenuBtn>
  );
}

export default BUnitActionsConfirmButton;