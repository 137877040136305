// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-adding-questions--GJB2\\+{margin-bottom:1.25rem}.header-adding-questions--GJB2\\+ .instructions--Yqkpq .wrap-list-answers---HKIp{margin-top:1.25rem}.header-adding-questions--GJB2\\+ .btn-on-add-question--WFlLS{margin-top:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/AddingQuestions/AddingQuestionsHeader/AddingQuestionsHeader.module.sass"],"names":[],"mappings":"AAEA,iCACE,qBAAA,CAEE,gFACE,kBAAA,CACJ,6DACE,kBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.header-adding-questions\n  margin-bottom: func.rem(20)\n  .instructions\n    .wrap-list-answers\n      margin-top: func.rem(20)\n  .btn-on-add-question\n    margin-top: func.rem(20)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-adding-questions": "header-adding-questions--GJB2+",
	"instructions": "instructions--Yqkpq",
	"wrap-list-answers": "wrap-list-answers---HKIp",
	"btn-on-add-question": "btn-on-add-question--WFlLS"
};
export default ___CSS_LOADER_EXPORT___;
