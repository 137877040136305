import React from 'react';
import CreateCheckpoint from '@/components/App/Admin/Results/LoadResults/CreateCheckpoint/CreateCheckpoint';
import CalculateResults from '@/components/App/Admin/Results/LoadResults/CalculateResults/CalculateResults';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import styles from './ResultsControlBlock.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface DataResultsControlBlock {
  resultsNotCalculatedYet?: boolean;
}

function ResultsControlBlock({ resultsNotCalculatedYet }: DataResultsControlBlock) {
  const checkPermission = useCheckPermission();
  const tNLS = useNLS();

  if (!checkPermission('run_processor')) {
    return null;
  }

  return (
    <div className={styles['wrap-results-control-block']}>
      {resultsNotCalculatedYet && (
        <div>{tNLS('results.has.not.calculated')}</div>
      )}

      <div className={styles['wrap-result-control-btns']}>
        <CreateCheckpoint />
        <CalculateResults />
      </div>

    </div>
  );
}

export default ResultsControlBlock;