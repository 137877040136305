import React, { ReactNode, useMemo, useState } from 'react';
import useLocalStorageByAdmin from '../BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import useCheckPermission from '../hooks/checkPermission';
import FilterSupersetsTabs, { FilterSupersetsFlag } from '../ListSupersets/FilterSupersets/FilterSupersetsTabs';
import CreateSupersetHeader from '../ListSupersets/CreateSupersetHeader/CreateSupersetHeader';
import styles from './SupersetsListPage.module.sass';
import LinkTabs from '../UIElements/LinkTabs';
import { LinkTabInterface, TabsType } from '../UIElements/LinkTabs/types';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import MainContentContainer from '@/components/Common/BaseTemplate/MainContentContainer';

interface SupersetsListPageProps {
  children: (props: { filterFlag: FilterSupersetsFlag }) => ReactNode;
}

export default function SupersetsListPage({ children }: SupersetsListPageProps) {
  const checkPermission = useCheckPermission();
  const { isSuper } = useAdminSelector(selectAdmin);
  const [writeInLocal, readFromLocal] = useLocalStorageByAdmin<FilterSupersetsFlag>(
    'filter-superset-flag',
    false,
    true,
  );

  const links = useMemo<LinkTabInterface[]>(() => [{
    id: 'index',
    title: 'Your projects',
    link: '/admin/projects',
    replace: true,
    end: true,
    isHide: !isSuper,
  },
  {
    id: 'projectSettings',
    title: 'All projects',
    link: '/super/projects',
    replace: true,
    end: true,
    isHide: !isSuper,
  }], [isSuper]);

  const [filterFlag, setFilterFlag] = useState<FilterSupersetsFlag>(() => {
    const flagFromLocal = readFromLocal();
    let defaultFlag: FilterSupersetsFlag = flagFromLocal || FilterSupersetsFlag.ACTIVE;
    if (!checkPermission() && defaultFlag === FilterSupersetsFlag.TEMPLATES) defaultFlag = FilterSupersetsFlag.ACTIVE;
    if (flagFromLocal !== defaultFlag) writeInLocal(defaultFlag);
    return defaultFlag;
  });

  return (
    <>
      <div className={styles['header-content-container']}>
        <LinkTabs
        <TabsType.Links>
          type={TabsType.Links}
        >
          {links}
        </LinkTabs>
      </div>
      <MainContentContainer parentContainerStyle={{
        position: 'relative',
      }}
      >
        <div className={styles['content-superset-list']}>
          <CreateSupersetHeader />
          <section className={styles['section-list-supersets']}>
            <FilterSupersetsTabs
              filterFlag={filterFlag}
              setFilterFlag={setFilterFlag}
            />
            {children({ filterFlag })}
          </section>
        </div>
      </MainContentContainer>
    </>
  );
}