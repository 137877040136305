import React, { Dispatch, SetStateAction } from 'react';
import { UnitsSelectOptionsFlags } from '../../useSelectOption';
import BUnitSelectMenuRating from '../../BUnitSelectMenuRating/BUnitSelectMenuRating';
import BUnitsSelectMenuSpecs from '../../BUnitsSelecMenuSpecs/BUnitsSelectMenuSpecs';

interface BUnitSelectMenuAdditionalOptionProps {
  currentSelectValue: string;
  setCurrentSelectValue: Dispatch<SetStateAction<string>>;
  currentSelectOptionKey: UnitsSelectOptionsFlags;
}

export default function BUnitSelectMenuAdditionalOption({
  currentSelectOptionKey, currentSelectValue, setCurrentSelectValue,
}: BUnitSelectMenuAdditionalOptionProps) {
  switch (currentSelectOptionKey) {
    case UnitsSelectOptionsFlags.Spec:
      return (
        <BUnitsSelectMenuSpecs
          currentSelectSpec={currentSelectValue}
          setCurrentSelectSpec={setCurrentSelectValue}
        />
      );
    case UnitsSelectOptionsFlags.Score:
      return (
        <BUnitSelectMenuRating
          selectedRatingId={currentSelectValue}
          setSelectedRatingId={setCurrentSelectValue}
        />
      );
    case UnitsSelectOptionsFlags.NoScore:
      return (
        <BUnitSelectMenuRating
          selectedRatingId={currentSelectValue}
          setSelectedRatingId={setCurrentSelectValue}
        />
      );
    default:
      return null;
  }
}