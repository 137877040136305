import React, { useMemo, useState } from 'react';
import OpenSidebarButton from './OpenSidebarButton/OpenSidebarButton';
import superAdminRoutes from '../SuperAdmin/routes';
import SideBar from '../Sidebar';

export default function SuperAdminSidebar() {
  const [isOpen, toggleOpen] = useState<boolean>(false);

  const closeSidebar = () => {
    toggleOpen(false);
  };

  const visibleRoutes = useMemo(() => [...superAdminRoutes, { url: 'admin/projects', name: 'Список проектов' }].filter(({ isHidden }) => !isHidden), []);

  return (
    <SideBar
    // @ts-ignore
      links={visibleRoutes}
      isOpen={isOpen}
      closeSidebar={closeSidebar}
    >
      <OpenSidebarButton toggleOpen={toggleOpen} />
    </SideBar>
  );
}