import React from 'react';
import UserWidgets from '@/components/Common/BaseTemplate/Header/HeaderWidgets/UserWidgets';
import styles from './Header.module.sass';
import DemoModeInfo from '@/components/Common/BaseTemplate/Header/DemoModeInfo/DemoModeInfo';
import { usePlayerSelector } from '@/store/hooks/playerRedux';
import HeaderContainer from '@/components/Common/BaseTemplate/Header/HeaderContainer/HeaderContainer';
import { selectProjectName } from '@/store/selectors/user/projects';
import UserHeaderLogo from './HeaderLogo/UserHeaderLogo/UserHeaderLogo';

function UserHeader() {
  const projectName = usePlayerSelector(selectProjectName);

  return (
    <HeaderContainer
      widgets={<UserWidgets />}
      logo={<UserHeaderLogo />}
    >
      <div className={styles['header-project-name']}>{projectName}</div>
      <DemoModeInfo />
    </HeaderContainer>
  );
}

export default UserHeader;