// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actor-code--OttqY{display:inline-block;justify-content:center;font-size:22px;font-weight:bold;color:var(--main-color);background:var(--main-bg);padding:10px;margin:10px 0;border-radius:6px;-webkit-user-select:text;user-select:text;min-width:9.375rem}.actor-code--OttqY .code-separator--XDlDZ{-webkit-user-select:none;user-select:none;margin:0 3px}.actor-code--OttqY.header-code--YPz4x{font-size:14px;border:1px dashed var(--select-question-default-bg);text-align:center}@media screen and (max-width: 425px){.actor-code--OttqY.header-code--YPz4x{padding:2px 10px;margin:0}}.actor-code--OttqY.header-code--YPz4x .code-separator--XDlDZ{margin:0 1px}", "",{"version":3,"sources":["webpack://./src/components/App/User/Common/Code/Code.module.sass"],"names":[],"mappings":"AAIA,mBACE,oBAAA,CACA,sBAAA,CACA,cAAA,CACA,gBAAA,CACA,uBAAA,CACA,yBAAA,CACA,YAAA,CACA,aAAA,CACA,iBAAA,CACA,wBAAA,CAAA,gBAAA,CACA,kBAAA,CACA,0CACE,wBAAA,CAAA,gBAAA,CACA,YAAA,CACF,sCACE,cAAA,CACA,mDAAA,CACA,iBAAA,CACA,qCAJF,sCAKI,gBAAA,CACA,QAAA,CAAA,CACF,6DACE,YAAA","sourcesContent":["@use \"@/styles/functions\" as func\n@use '@/styles/mediaValues' as screen\n\n\n.actor-code\n  display: inline-block\n  justify-content: center\n  font-size: 22px\n  font-weight: bold\n  color: var(--main-color)\n  background: var(--main-bg)\n  padding: 10px\n  margin: 10px 0\n  border-radius: 6px\n  user-select: text\n  min-width: func.rem(150)\n  .code-separator\n    user-select: none\n    margin: 0 3px\n  &.header-code\n    font-size: 14px\n    border: 1px dashed var(--select-question-default-bg)\n    text-align: center\n    @media screen and (max-width: screen.$large-phone-screen)\n      padding: 2px 10px\n      margin: 0\n    .code-separator\n      margin: 0 1px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actor-code": "actor-code--OttqY",
	"code-separator": "code-separator--XDlDZ",
	"header-code": "header-code--YPz4x"
};
export default ___CSS_LOADER_EXPORT___;
