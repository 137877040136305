// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html[data-theme=dark] .cuts-chart--ytNfd{--stroke-color: #ffffff}html[data-theme=light] .cuts-chart--ytNfd{--stroke-color: #000000}.cuts-chart--ytNfd{position:relative;margin-top:1.25rem;margin-bottom:6.25rem}.cuts-chart--ytNfd.no-categories--1XzLT{margin-top:9.375rem}.cuts-chart--ytNfd.no-categories--1XzLT .svg-chart--xAQRU,.cuts-chart--ytNfd.no-categories--1XzLT .svg-unselected-chart--E2ZqJ{top:0}.cuts-chart--ytNfd.no-categories--1XzLT .wrap-statistics-values--tZlEm{top:-140px}.cuts-chart--ytNfd .svg-chart--xAQRU,.cuts-chart--ytNfd .svg-unselected-chart--E2ZqJ{position:absolute;top:50%;transform:translateY(-50%);z-index:10}.cuts-chart--ytNfd .svg-unselected-chart--E2ZqJ{fill-opacity:.5;stroke:var(--stroke-color)}.cuts-chart--ytNfd .wrap-statistics-values--tZlEm{position:absolute;top:-60px;right:0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/CutsChart/CutsChart.module.sass"],"names":[],"mappings":"AAGE,yCACE,uBAAA,CAGF,0CACE,uBAAA,CAEJ,mBACE,iBAAA,CACA,kBAAA,CACA,qBAAA,CACA,wCACE,mBAAA,CACA,+HACE,KAAA,CACF,uEACE,UAAA,CACJ,qFACE,iBAAA,CACA,OAAA,CACA,0BAAA,CACA,UAAA,CACF,gDACE,eAAA,CACA,0BAAA,CACF,kDACE,iBAAA,CACA,SAAA,CACA,OAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\nhtml[data-theme='dark']\n  .cuts-chart\n    --stroke-color: #ffffff\n\nhtml[data-theme='light']\n  .cuts-chart\n    --stroke-color: #000000\n\n.cuts-chart\n  position: relative\n  margin-top: func.rem(20)\n  margin-bottom: func.rem(100)\n  &.no-categories\n    margin-top: func.rem(150)\n    .svg-chart, .svg-unselected-chart\n      top: 0\n    .wrap-statistics-values\n      top: -140px\n  .svg-chart, .svg-unselected-chart\n    position: absolute\n    top: 50%\n    transform: translateY(-50%)\n    z-index: 10\n  .svg-unselected-chart\n    fill-opacity: 0.5\n    stroke: var(--stroke-color)\n  .wrap-statistics-values\n    position: absolute\n    top: -60px\n    right: 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cuts-chart": "cuts-chart--ytNfd",
	"no-categories": "no-categories--1XzLT",
	"svg-chart": "svg-chart--xAQRU",
	"svg-unselected-chart": "svg-unselected-chart--E2ZqJ",
	"wrap-statistics-values": "wrap-statistics-values--tZlEm"
};
export default ___CSS_LOADER_EXPORT___;
