// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".information-block--kqpAO{font-size:1rem;padding:0.3125rem 0.3125rem}.information-block--kqpAO:not(:first-child){border-left:.5px solid var(--admin-main-font-color)}.information-block--kqpAO .number--G\\+ngV{color:var(--main-color);margin-right:0.375rem}.information-block--kqpAO .title--JSi2m{color:var(--admin-main-font-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/BUnitsSectionHeader/UnitsHeaderInformation/UnitsInformationBlock/UnitsInformationBlock.module.sass"],"names":[],"mappings":"AAEA,0BACE,cAAA,CACA,2BAAA,CACA,4CACE,mDAAA,CACF,0CACE,uBAAA,CACA,qBAAA,CACF,wCACE,kCAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.information-block\n  font-size: func.rem(16)\n  padding: func.rem(5) func.rem(5)\n  &:not(:first-child)\n    border-left: 0.5px solid var(--admin-main-font-color)\n  .number\n    color: var(--main-color)\n    margin-right: func.rem(6)\n  .title\n    color: var(--admin-main-font-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"information-block": "information-block--kqpAO",
	"number": "number--G+ngV",
	"title": "title--JSi2m"
};
export default ___CSS_LOADER_EXPORT___;
