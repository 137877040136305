import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CalculateResultsModal from '@/components/App/Admin/Results/LoadResults/CalculateResults/CalculateResultsModal/CalculateResultsModal';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import useNLS from '@/components/App/Admin/hooks/hooks';
import selectStatusProcessorResult from '@/store/selectors/admin/superset/process/process';

function CalculateResults() {
  const [isModalOpen, toggleModal] = useState(false);
  const tNLS = useNLS();
  const { hasEvaluated } = useSelector(selectStatusProcessorResult);

  return (
    <>
      <CustomButton
        onClick={() => toggleModal(true)}
        styleTypeBtn="btn-grey btn-large"
      >
        {tNLS(hasEvaluated ? 'results.recalculate' : 'results.calculate')}
      </CustomButton>
      <CalculateResultsModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
    </>
  );
}

export default CalculateResults;