// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".specializations-list--Io3Nj{display:flex;align-items:center;flex-wrap:wrap;gap:0.625rem}.icon--W7F9a{font-size:14px}.gender-select--nEh3G{height:28px;width:150px}.gender-select-btn--ST\\+Y2{height:28px !important}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitFieldsSections.module.sass"],"names":[],"mappings":"AAEA,6BACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,YAAA,CAEF,aACE,cAAA,CAEF,sBACE,WAAA,CACA,WAAA,CAGF,2BACE,sBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.specializations-list\n  display: flex\n  align-items: center\n  flex-wrap: wrap\n  gap: func.rem(10)\n\n.icon\n  font-size: 14px\n\n.gender-select\n  height: 28px\n  width: 150px\n\n// разобраться почему не сработало без important\n.gender-select-btn\n  height: 28px !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"specializations-list": "specializations-list--Io3Nj",
	"icon": "icon--W7F9a",
	"gender-select": "gender-select--nEh3G",
	"gender-select-btn": "gender-select-btn--ST+Y2"
};
export default ___CSS_LOADER_EXPORT___;
