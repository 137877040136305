import React, {
  Dispatch, ReactNode, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react';
import styles from './BUnitContainer.module.sass';
import BUnitActionButtons from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitActionButtons';
import newBUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { getClearClassNames } from '@/helpers/helpers';
import UnitsTreeContext from '@/components/App/Admin/BUnitsSection/UnitsTree/UnitsTreeContext';
import BUnitStatusIcons from '../BUnitStatusIcons/BUnitStatusIcons';

interface BUnitContainerProps {
  children: ReactNode;
  isCardOpen?: boolean;
  isShowMenu: boolean;
  toggleMenu: Dispatch<SetStateAction<boolean>>;
  toggleInput?: Dispatch<SetStateAction<boolean>>;
}

function BUnitContainer({
  children, isShowMenu, toggleMenu, isCardOpen, toggleInput,
}: BUnitContainerProps) {
  const { unit, id, index } = useContext(newBUnitContext);
  const [isBacklight, toggleBacklight] = useState(false);
  const { searchingValue, setSearchingValue, isAnimation } = useContext(UnitsTreeContext);
  const unitRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!searchingValue) return;
    if (searchingValue.id !== id) return;

    setTimeout(() => {
      unitRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      toggleBacklight(true);
      if (setSearchingValue) {
        setSearchingValue(undefined);
      }
    }, 300);
  }, [id, isAnimation, searchingValue, setSearchingValue]);

  useEffect(() => {
    if (!isBacklight) return;

    setTimeout(() => toggleBacklight(false), 2000);
  }, [isBacklight]);

  return (
    <div
      className={getClearClassNames([
        styles['unit-container'],
        unit?.isunit === 1 && styles.actor,
        isBacklight && styles.backlight,
      ])}
      ref={unitRef}
      onMouseEnter={() => toggleMenu(true)}
      onMouseLeave={() => toggleMenu(false)}
    >
      {children}
      <BUnitStatusIcons />
      <BUnitActionButtons
        isHidden={!(isCardOpen || isShowMenu)}
        toggleInput={toggleInput}
      />
      {unit?.level !== 0 && (
      <div className={getClearClassNames([
        styles.radius,
        index !== 0 && styles['no-first'],
      ])}
      />
      )}
    </div>
  );
}

export default BUnitContainer;