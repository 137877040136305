import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ScoreTypeToggler.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import { selectScoreType } from '@/store/selectors/admin/superset/process/cuts/fourD';
import { PERCENTILE_SCORE_TYPE, ABSOLUTE_SCORE_TYPE } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import { setScoreType } from '@/store/actions/admin/superset/process/cuts/fourD';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface DataScoreTypeToggler {
  externalClasses?: {
    wrapScoreTypeToggler: string,
  };
}

function ScoreTypeToggler({ externalClasses }: DataScoreTypeToggler) {
  const dispatch = useDispatch();
  const scoreType = useSelector(selectScoreType);
  const tNLS = useNLS();

  const toggleScoreType = (sType: 'PERCENTILE' | 'ABSOLUTE') => {
    dispatch(setScoreType(sType));
  };

  return (
    <div className={getClearClassNames([
      styles['wrap-score-type-toggler'],
      externalClasses?.wrapScoreTypeToggler,
    ])}
    >
      <Tooltip id="btnScoreTypeToggler-Percentile" />
      <button
        type="button"
        className={getClearClassNames([
          'btn-without-style',
          styles['btn-score-type-toggler'],
          scoreType === PERCENTILE_SCORE_TYPE
            ? styles.on
            : null,
        ])}
        data-tooltip-id="btnScoreTypeToggler-Percentile"
        data-tooltip-content={tNLS('results.4d.percentile')}
        onClick={() => toggleScoreType(PERCENTILE_SCORE_TYPE)}
      >
        {' '}
        <span className={`icon-relative-percent ${styles['icon-type-factor-struct']}`} />
      </button>

      <Tooltip id="btnScoreTypeToggler-Absolute" />
      <button
        type="button"
        className={getClearClassNames([
          'btn-without-style',
          styles['btn-score-type-toggler'],
          scoreType === ABSOLUTE_SCORE_TYPE
            ? styles.on
            : null,
        ])}
        data-tooltip-id="btnScoreTypeToggler-Absolute"
        data-tooltip-content={tNLS('results.4d.absolute')}
        onClick={() => toggleScoreType(ABSOLUTE_SCORE_TYPE)}
      >
        {' '}
        <span
          className={`icon-average-score ${styles['icon-type-factor-struct']}`}
        />
      </button>

    </div>
  );
}

export default ScoreTypeToggler;