// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messages--HheoW{margin-bottom:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingErrorsAndWarns/UploadingErrorsAndWarns.module.sass"],"names":[],"mappings":"AAEA,iBACE,qBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.messages\n  margin-bottom: func.rem(20)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messages": "messages--HheoW"
};
export default ___CSS_LOADER_EXPORT___;
