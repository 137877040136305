// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-list-items--v1zWS .list-of-questions--45uxY{display:flex;flex-direction:column;gap:0.4375rem}.wrap-list-items--v1zWS .list-of-questions--45uxY .item-question--p\\+jXi{transition:background-color .3s ease 0s;border-radius:4px}.wrap-list-items--v1zWS .list-of-questions--45uxY .item-question--p\\+jXi:hover{background-color:var(--list-active-items-btn-active-bg)}.wrap-list-items--v1zWS .list-of-questions--45uxY .item-question--p\\+jXi.selected--KDXDv{background-color:var(--list-active-items-btn-active-bg)}.wrap-list-items--v1zWS .list-of-questions--45uxY .item-question--p\\+jXi .btn-select-item--DeQI4{display:block;width:100%;font-size:1rem;color:var(--list-active-items-btn-color);text-align:left;padding:5px 3px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/ListActiveItems/ListActiveItems.module.sass"],"names":[],"mappings":"AAGE,kDACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,yEACE,uCAAA,CACA,iBAAA,CACA,+EACE,uDAAA,CACF,yFACE,uDAAA,CACF,iGACE,aAAA,CACA,UAAA,CACA,cAAA,CACA,wCAAA,CACA,eAAA,CACA,eAAA","sourcesContent":["@use \"../../../../../../styles/functions\" as func\n\n.wrap-list-items\n  .list-of-questions\n    display: flex\n    flex-direction: column\n    gap: func.rem(7)\n    .item-question\n      transition: background-color 0.3s ease 0s\n      border-radius: 4px\n      &:hover\n        background-color: var(--list-active-items-btn-active-bg)\n      &.selected\n        background-color: var(--list-active-items-btn-active-bg)\n      .btn-select-item\n        display: block\n        width: 100%\n        font-size: func.rem(16)\n        color: var(--list-active-items-btn-color)\n        text-align: left\n        padding: 5px 3px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-list-items": "wrap-list-items--v1zWS",
	"list-of-questions": "list-of-questions--45uxY",
	"item-question": "item-question--p+jXi",
	"selected": "selected--KDXDv",
	"btn-select-item": "btn-select-item--DeQI4"
};
export default ___CSS_LOADER_EXPORT___;
