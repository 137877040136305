// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-links-other-surveys--foar7{margin-top:1.25rem}.wrap-links-other-surveys--foar7 .wrap-link-other-survey--0jzRV{display:flex;gap:0.9375rem;margin-bottom:1.25rem}.wrap-links-other-surveys--foar7 .wrap-link-other-survey--0jzRV:last-child{margin-bottom:0}.wrap-links-other-surveys--foar7 .wrap-link-other-survey--0jzRV .wrap-info--txMGG .surveyType--UcmFx{color:var(--admin-main-font-color)}.wrap-links-other-surveys--foar7 .wrap-link-other-survey--0jzRV .wrap-info--txMGG .link-to-start-survey--AcdDH{color:var(--secondary-grey)}.wrap-links-other-surveys--foar7 .wrap-link-other-survey--0jzRV .btn-copy-link--5pAY0{align-self:end}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Superset/HeaderSuperset/LinksForOtherSurveys/LinksForOtherSurveys.module.sass"],"names":[],"mappings":"AAEA,iCACE,kBAAA,CACA,gEACE,YAAA,CACA,aAAA,CACA,qBAAA,CACA,2EACE,eAAA,CAEA,qGACE,kCAAA,CACF,+GACE,2BAAA,CACJ,sFACE,cAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrap-links-other-surveys\n  margin-top: func.rem(20)\n  .wrap-link-other-survey\n    display: flex\n    gap: func.rem(15)\n    margin-bottom: func.rem(20)\n    &:last-child\n      margin-bottom: 0\n    .wrap-info\n      .surveyType\n        color: var(--admin-main-font-color)\n      .link-to-start-survey\n        color: var(--secondary-grey)\n    .btn-copy-link\n      align-self: end"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-links-other-surveys": "wrap-links-other-surveys--foar7",
	"wrap-link-other-survey": "wrap-link-other-survey--0jzRV",
	"wrap-info": "wrap-info--txMGG",
	"surveyType": "surveyType--UcmFx",
	"link-to-start-survey": "link-to-start-survey--AcdDH",
	"btn-copy-link": "btn-copy-link--5pAY0"
};
export default ___CSS_LOADER_EXPORT___;
