import React, { useContext } from 'react';
import styles from './ActionButton.module.sass';
import newBUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import BUnitStatusIcon, { IconType } from '../../BUnitStatusIcons/BUnitStatusIcon/BUnitStatusIcon';

const TooltipNlsByType = {
  [IconType.Download]: 'tree.icon.tooltip.download.report',
  [IconType.Link]: 'tree.icon.tooltip.personal.link',
  [IconType.Edit]: 'tree.icon.tooltip.edit.dep',
  [IconType.Delete]: 'tree.icon.tooltip.delete.person',
  [IconType.AddUnit]: 'tree.icon.tooltip.create.subunit',
  // [IconType.Incoming]: '',
  // [IconType.Outgoing]: '',
};

interface ActionButtonProps {
  handleActionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonType: IconType;
  className?: string;
  isHidden?: boolean;
}

function ActionButton({
  handleActionClick, buttonType, className = '', isHidden = false,
}: ActionButtonProps) {
  const { id } = useContext(newBUnitContext);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isHidden) return;
    handleActionClick(event);
  };

  return (
    <button
      className={`${styles['action-button']} ${className} ${
        isHidden && styles.hidden
      } btn-without-style`}
      type="button"
      disabled={isHidden}
      onClick={handleClick}
      data-tooltip-id={`${id}-${buttonType}`}
    >
      <BUnitStatusIcon
        iconType={buttonType}
        isVisible={!isHidden}
        nlsKey={TooltipNlsByType[buttonType] || ''}
      />
    </button>
  );
}

export default ActionButton;