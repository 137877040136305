// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-superset-list--GiCQL .section-list-supersets--wn4V4{margin-top:4.375rem}.header-content-container--ghIsK{position:relative;max-width:68.75rem;margin:0 auto}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetsListPage/SupersetsListPage.module.sass"],"names":[],"mappings":"AAIE,6DACE,mBAAA,CAEJ,iCACE,iBAAA,CACA,kBAAA,CACA,aAAA","sourcesContent":["@use 'src/styles/functions' as func\n@use 'src/styles/colors'\n\n.content-superset-list\n  .section-list-supersets\n    margin-top: func.rem(70)\n\n.header-content-container\n  position: relative\n  max-width: func.rem(1100)\n  margin: 0 auto\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-superset-list": "content-superset-list--GiCQL",
	"section-list-supersets": "section-list-supersets--wn4V4",
	"header-content-container": "header-content-container--ghIsK"
};
export default ___CSS_LOADER_EXPORT___;
