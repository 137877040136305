// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo--lc9P1 .logo-container--RM56O:not(:last-child){display:none}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/Header/HeaderLogo/UserHeaderLogo/UserHeaderLogo.module.sass"],"names":[],"mappings":"AAII,qDACE,YAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.logo\n  .logo-container\n    &:not(:last-child)\n      display: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo--lc9P1",
	"logo-container": "logo-container--RM56O"
};
export default ___CSS_LOADER_EXPORT___;
