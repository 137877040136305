// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn-upload-project-template--48XNk{display:block;margin:1.25rem auto 0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/UpploadResourcesForm/UploadResourcesForm.module.sass"],"names":[],"mappings":"AAEA,oCACE,aAAA,CACA,qBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.btn-upload-project-template\n  display: block\n  margin: func.rem(20) auto 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-upload-project-template": "btn-upload-project-template--48XNk"
};
export default ___CSS_LOADER_EXPORT___;
