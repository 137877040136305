// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sp-list--datIk{display:grid;grid-template-columns:repeat(auto-fill, minmax(12.5rem, 1fr));padding:0 1rem 0 1.625rem}.sp-list--datIk .selected-count--ChEKL{grid-column:1/-1}.sp-list--datIk .select-sp-label--FX5ba{width:fit-content;display:flex;gap:0.75rem;align-items:baseline;color:var(--main-color)}.sp-list--datIk .select-sp-label--FX5ba.select-all-label--8oiFS{grid-column:1/-1;padding:0.25rem 0}.sp-list--datIk .select-sp-label--FX5ba:hover{cursor:pointer}.sp-list--datIk .select-sp-label--FX5ba .units-count--EehLf{color:var(--secondary-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/LoadResults/CalculateResults/CalculateResultsModal/CalculateResultsModalContent/SelectSpecs/SelectSpecs.module.sass"],"names":[],"mappings":"AAEA,gBACE,YAAA,CACA,6DAAA,CACA,yBAAA,CACA,uCACE,gBAAA,CACF,wCACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,oBAAA,CACA,uBAAA,CACA,gEACE,gBAAA,CACA,iBAAA,CACF,8CACE,cAAA,CACF,4DACE,4BAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.sp-list\n  display: grid\n  grid-template-columns: repeat(auto-fill, minmax(func.rem(200), 1fr))\n  padding: 0 func.rem(16) 0 func.rem(26)\n  .selected-count\n    grid-column: 1 / -1\n  .select-sp-label\n    width: fit-content\n    display: flex\n    gap: func.rem(12)\n    align-items: baseline\n    color: var(--main-color)\n    &.select-all-label\n      grid-column: 1 / -1\n      padding: func.rem(4) 0\n    &:hover\n      cursor: pointer\n    .units-count\n      color: var(--secondary-color)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sp-list": "sp-list--datIk",
	"selected-count": "selected-count--ChEKL",
	"select-sp-label": "select-sp-label--FX5ba",
	"select-all-label": "select-all-label--8oiFS",
	"units-count": "units-count--EehLf"
};
export default ___CSS_LOADER_EXPORT___;
