import React, {
  ReactNode, useContext,

} from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import styles from './BUnitChildrenContainer.module.sass';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import AnimatedContainerByHeight from '@/components/Common/AnimatedContainerByHeight/AnimatedContainerByHeight';
import UnitsTreeContext from '../../UnitsTreeContext';

interface BUnitChildrenContainerProps {
  children: ReactNode;
  isOpen: boolean;
  type: BUnitContainerType;
}

export enum BUnitContainerType {
  Card = 'card',
  Branch = 'branch',
}

export default function BUnitChildrenContainer({ children, type, isOpen }: BUnitChildrenContainerProps) {
  const { units } = useAdminSelector(selectUnitsTreeData);
  const { searchingValue } = useContext(UnitsTreeContext);

  if (Object.keys(units).length < 2000) {
    return (
      <AnimatedContainerByHeight
        durationInMs={100}
        withoutAnimation={!!searchingValue}
        isOpen={isOpen}
        classNames={styles[type]}
      >
        {children}
      </AnimatedContainerByHeight>
    );
  }

  if (!isOpen) return null;

  return (
    <div className={styles[type]}>{children}</div>
  );
}