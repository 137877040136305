// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-fallback-superset-section--yehR0{display:flex;justify-content:center;align-items:center;padding:1.25rem 0;background:var(--main-bg);margin:1.25rem 0}.wrap-fallback-superset-section--yehR0 .wrap-message--K5uDS{display:flex;flex-direction:column;align-items:center;gap:0.625rem;font-size:1.25rem;font-weight:bold}.wrap-fallback-superset-section--yehR0 .wrap-message--K5uDS .icon-error--tC8S0 span:before{font-size:2.5rem}.wrap-fallback-superset-section--yehR0 .wrap-message--K5uDS .icon-error--tC8S0 span:nth-child(1):before{color:var(--error-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/FallbackSupersetSection/FallbackSupersetSection.module.sass"],"names":[],"mappings":"AAEA,uCACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,yBAAA,CACA,gBAAA,CACA,4DACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,iBAAA,CACA,gBAAA,CAGI,2FACE,gBAAA,CAEA,wGACE,wBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrap-fallback-superset-section\n  display: flex\n  justify-content: center\n  align-items: center\n  padding: func.rem(20) 0\n  background: var(--main-bg)\n  margin: func.rem(20) 0\n  .wrap-message\n    display: flex\n    flex-direction: column\n    align-items: center\n    gap: func.rem(10)\n    font-size: func.rem(20)\n    font-weight: bold\n    .icon-error\n      span\n        &:before\n          font-size: func.rem(40)\n        &:nth-child(1)\n          &:before\n            color: var(--error-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-fallback-superset-section": "wrap-fallback-superset-section--yehR0",
	"wrap-message": "wrap-message--K5uDS",
	"icon-error": "icon-error--tC8S0"
};
export default ___CSS_LOADER_EXPORT___;
