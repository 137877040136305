// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-type-fs-toggler--Cp3rF .btn-type-fs-toggler--PNOrX{border-radius:4px;padding:7px 0;transition:background-color .3s ease 0s;width:2.5rem}.wrap-type-fs-toggler--Cp3rF .btn-type-fs-toggler--PNOrX.percentile-fs--MZyI0{background-color:#2279fc}.wrap-type-fs-toggler--Cp3rF .btn-type-fs-toggler--PNOrX.percentile-fs--MZyI0:hover{background-color:#005de9}.wrap-type-fs-toggler--Cp3rF .btn-type-fs-toggler--PNOrX.percentile-fs--MZyI0:active{background-color:#4891ff}.wrap-type-fs-toggler--Cp3rF .btn-type-fs-toggler--PNOrX.absolute-fs--babbl{background-color:#27ae60}.wrap-type-fs-toggler--Cp3rF .btn-type-fs-toggler--PNOrX.absolute-fs--babbl:hover{background-color:#178546}.wrap-type-fs-toggler--Cp3rF .btn-type-fs-toggler--PNOrX.absolute-fs--babbl:active{background-color:#3dcd7a}.wrap-type-fs-toggler--Cp3rF .btn-type-fs-toggler--PNOrX .icon-type-factor-sctruct--mgRsU:before{font-size:1rem;color:#fff}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FactorsCuts/TypeFSToggler/TypeFSToggler.module.sass"],"names":[],"mappings":"AAGE,yDACE,iBAAA,CACA,aAAA,CACA,uCAAA,CACA,YAAA,CACA,8EACE,wBAAA,CACA,oFACE,wBAAA,CACF,qFACE,wBAAA,CACJ,4EACE,wBAAA,CACA,kFACE,wBAAA,CACF,mFACE,wBAAA,CAEF,iGACE,cAAA,CACA,UAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrap-type-fs-toggler\n  .btn-type-fs-toggler\n    border-radius: 4px\n    padding: 7px 0\n    transition: background-color 0.3s ease 0s\n    width: func.rem(40)\n    &.percentile-fs\n      background-color: #2279FC\n      &:hover\n        background-color: #005de9\n      &:active\n        background-color: #4891ff\n    &.absolute-fs\n      background-color: #27AE60\n      &:hover\n        background-color: #178546\n      &:active\n        background-color: #3dcd7a\n    .icon-type-factor-sctruct\n      &:before\n        font-size: func.rem(16)\n        color: #FFFFFF"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-type-fs-toggler": "wrap-type-fs-toggler--Cp3rF",
	"btn-type-fs-toggler": "btn-type-fs-toggler--PNOrX",
	"percentile-fs": "percentile-fs--MZyI0",
	"absolute-fs": "absolute-fs--babbl",
	"icon-type-factor-sctruct": "icon-type-factor-sctruct--mgRsU"
};
export default ___CSS_LOADER_EXPORT___;
