// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-content-bu-settings--DN2N9{overflow-y:visible;overflow-y:initial}.create-units-section--LBRX6{border-bottom:1px solid var(--admin-main-font-color);padding:14px 0}.create-units-section--LBRX6 .wrap-btn-settings-units--OKy9u{display:flex;gap:0.625rem}.create-units-section--LBRX6 .msg-no-units--SO\\+yw{display:inline-block;margin-top:1.25rem;color:var(--admin-main-font-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/SectionBusinessUnitsSettings/SectionBusinessUnitsSettings.module.sass"],"names":[],"mappings":"AAEA,kCACE,kBAAA,CAAA,kBAAA,CAEF,6BACE,oDAAA,CACA,cAAA,CACA,6DACE,YAAA,CACA,YAAA,CACF,mDACE,oBAAA,CACA,kBAAA,CACA,kCAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.modal-content-bu-settings\n  overflow-y: initial\n\n.create-units-section\n  border-bottom: 1px solid var(--admin-main-font-color)\n  padding: 14px 0\n  .wrap-btn-settings-units\n    display: flex\n    gap: func.rem(10)\n  .msg-no-units\n    display: inline-block\n    margin-top: func.rem(20)\n    color: var(--admin-main-font-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-content-bu-settings": "modal-content-bu-settings--DN2N9",
	"create-units-section": "create-units-section--LBRX6",
	"wrap-btn-settings-units": "wrap-btn-settings-units--OKy9u",
	"msg-no-units": "msg-no-units--SO+yw"
};
export default ___CSS_LOADER_EXPORT___;
