// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".article-toolbar-controls--jg4Zn{display:flex;align-content:center;justify-content:flex-end;align-items:center;flex:1}", "",{"version":3,"sources":["webpack://./src/components/App/Super/Articles/ArticleEditor/ArticleToolbar/ArticleToolbar.module.sass"],"names":[],"mappings":"AAAA,iCACI,YAAA,CACA,oBAAA,CACA,wBAAA,CACA,kBAAA,CACA,MAAA","sourcesContent":[".article-toolbar-controls\n    display: flex\n    align-content: center\n    justify-content: flex-end\n    align-items: center\n    flex: 1\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"article-toolbar-controls": "article-toolbar-controls--jg4Zn"
};
export default ___CSS_LOADER_EXPORT___;
