import React, {
  useMemo, useState,
} from 'react';
import styles from './NotificationsBar.module.sass';
import NotificationAnimatedContainer from '@/components/Common/NotificationsBar/NotificationContainer/NotificationAnimatedContainer';
import FilesNotifications from './FilesNotifications/FilesNotifications';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import useOutsideClick from '@/hooks/checkOutsideClick';

function NotificationsBar() {
  const [isHidden, toggleHidden] = useState(true);
  const { notifications, lastNotifyId } = useAdminSelector((state) => state.notify);

  const notificationsList = useMemo(
    () => [...notifications].reverse(),
    [notifications],
  );

  const panelRef = useOutsideClick<() => void, HTMLDivElement>(() => toggleHidden(true), !isHidden);

  return (
    <div
      className={styles['notification-panel']}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={`${styles['notifications-container']} ${
          isHidden && styles.hidden
        }`}
        ref={panelRef}
        onClick={() => {
          if (notifications.length === 1) return;
          if (!isHidden) return;
          toggleHidden(false);
        }}
      >
        {notificationsList.map((notify, index) => (
          <NotificationAnimatedContainer
            key={notify.id}
            notify={notify}
            isHidden={isHidden}
            withAnimation={index === 0 && notify.id === lastNotifyId}
            index={index}
          />
        ))}
        <FilesNotifications
          isHidden={isHidden}
          startIndex={notificationsList.length}
        />
      </div>
    </div>
  );
}

NotificationsBar.propTypes = {};

export default NotificationsBar;