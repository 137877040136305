// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo--6XcwF{max-width:100px;max-height:50px}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/Header/HeaderContainer/HeaderLogo/HeaderLogo.module.sass"],"names":[],"mappings":"AAEA,aACE,eAAA,CACA,eAAA","sourcesContent":["@use 'src/styles/functions'\n\n.logo\n  max-width: 100px\n  max-height: 50px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo--6XcwF"
};
export default ___CSS_LOADER_EXPORT___;
