// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spec-input--295o7{padding:0.125rem 0.5rem;background:var(--admin-background-interactive-elements);color:var(--main-color);outline:none;width:fit-content;font-size:1rem;font-weight:400;height:1.625rem;border-radius:0.5rem;min-width:1.5625rem}.spec-input--295o7.new--QRIP-{box-shadow:inset 0 0 0 2px var(--rt-color-success)}.spec-input--295o7.deleted--jwi-e{box-shadow:inset 0 0 0 2px var(--error-color)}.spec-input--295o7:hover:not(div){background:var(--admin-hover-background-interactive-elements);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/BUnitSpecInput/BUnitSpecInput.module.sass"],"names":[],"mappings":"AAEA,mBACE,uBAAA,CACA,uDAAA,CACA,uBAAA,CACA,YAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,oBAAA,CACA,mBAAA,CACA,8BACE,kDAAA,CACF,kCACE,6CAAA,CAEA,kCACE,6DAAA,CACA,cAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.spec-input\n  padding: func.rem(2) func.rem(8)\n  background: var(--admin-background-interactive-elements)\n  color: var(--main-color)\n  outline: none\n  width: fit-content\n  font-size: func.rem(16)\n  font-weight: 400\n  height: func.rem(26)\n  border-radius: func.rem(8)\n  min-width: func.rem(25)\n  &.new\n    box-shadow: inset 0 0 0 2px var(--rt-color-success)\n  &.deleted\n    box-shadow: inset 0 0 0 2px var(--error-color)\n  &:hover\n    &:not(div)\n      background: var(--admin-hover-background-interactive-elements)\n      cursor: pointer\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spec-input": "spec-input--295o7",
	"new": "new--QRIP-",
	"deleted": "deleted--jwi-e"
};
export default ___CSS_LOADER_EXPORT___;
