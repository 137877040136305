// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user-form--wmKi9{display:flex;flex-direction:column;padding:1rem 0.5rem;gap:1rem;flex-wrap:wrap}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/UsersListPage/UsersList/SuperAdminUser/SuperAdminUserForm/SuperAdminUserForm.module.sass"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,QAAA,CACA,cAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.user-form\n  display: flex\n  flex-direction: column\n  padding: func.rem(16) func.rem(8)\n  gap: func.rem(16)\n  flex-wrap: wrap\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-form": "user-form--wmKi9"
};
export default ___CSS_LOADER_EXPORT___;
