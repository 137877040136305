import React, { useCallback, useMemo, useState } from 'react';
import CustomSelect from '@/components/App/Admin/BUnitsSection/CustomSelect';
import styles from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/SelectMenu.module.sass';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import CustomSelectOption from '@/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectOption/CustomSelectOption';
import {
  BUnitSelectOption,
  SetCurrentSelectOptionType,
  UnitsSelectOptionsFlags,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectOption';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { getSelectOptionsWithEnums } from '../helpers';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectRatings } from '@/store/selectors/admin/superset/process/cuts/fourD';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { TreeUnit } from '@/store/types/admin/superset/unitsTree';

interface BUnitsSelectMenuOptionsProps {
  currentSelectOption: BUnitSelectOption;
  setCurrentSelectOption: SetCurrentSelectOptionType;
}
function BUnitsSelectMenuOptions({
  currentSelectOption,
  setCurrentSelectOption,
}: BUnitsSelectMenuOptionsProps) {
  const tNLS = useNLS();
  const [writeInLocalStorage] = useLocalStorageByAdmin('selectOptions');
  const [isOpen, toggleOpen] = useState(false);
  const ratings = useAdminSelector(selectRatings);
  const { units } = useAdminSelector(selectUnitsTreeData);

  const selectOptions = getSelectOptionsWithEnums(tNLS);

  const handleSelectOptions = (selectedOption: BUnitSelectOption) => {
    toggleOpen(false);
    setCurrentSelectOption(selectedOption);
    writeInLocalStorage(selectedOption);
  };

  const filterOptions = useCallback<(arg: BUnitSelectOption) => boolean>(({ key }) => {
    switch (key) {
      case UnitsSelectOptionsFlags.Score:
        return !!ratings.length;
      case UnitsSelectOptionsFlags.NoScore:
        return !!ratings.length;
      case UnitsSelectOptionsFlags.Spec:
        return !!Object.values(units).find((unit: TreeUnit) => !!unit.spec?.length);
      default:
        return true;
    }
  }, [ratings, units]);

  const filteredOptions = useMemo(() => selectOptions.filter(filterOptions), [filterOptions, selectOptions]);

  return (
    <CustomSelect
      activeValue={currentSelectOption.name}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      type="button"
      mainBtnClassNames={styles['select-option']}
    >
      {filteredOptions
        .map((option) => (
          <CustomSelectOption
            key={option.key}
            optionClassNames={styles['unit-menu-select']}
            onClick={() => handleSelectOptions(option)}
            isCurrentValue={option.key === currentSelectOption.key}
          >
            {option.name}
          </CustomSelectOption>
        ))}
    </CustomSelect>
  );
}

export default BUnitsSelectMenuOptions;