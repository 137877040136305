import React, { ReactNode, useMemo } from 'react';
import styles from './HeaderContainer.module.sass';
import './Header.sass';
import HeaderWidgetsContainer
  from '@/components/Common/BaseTemplate/Header/HeaderContainer/HeaderWidgetsContainer/HeaderWidgetsContainer';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import SuperAdminSidebar from '@/components/App/Admin/SuperAdminSidebar';

interface HeaderContainerProps {
  children?: React.ReactNode;
  widgets?: React.ReactNode;
  isSuper?: boolean;
  logo?: ReactNode;
}

function HeaderContainer({
  children, widgets, logo, isSuper,
}: HeaderContainerProps) {
  const isDev = () => {
    const { hostname } = window.location;
    if (hostname === 'stage.lost.report') return <div className={styles['is-dev']}>Stage</div>;
    if (hostname === 'localhost') return <div className={`${styles['is-dev']} ${styles.local}`}>Localhost</div>;
    return null;
  };

  const headerLogo = useMemo(() => {
    if (logo) return logo;
    if (isSuper) return <SuperAdminSidebar />;
    return <HeaderLogo />;
  }, [isSuper, logo]);

  return (
    <header className="header main-header-container">
      <div className="header-content">
        {headerLogo}
        {children}
        <HeaderWidgetsContainer>
          {widgets}
        </HeaderWidgetsContainer>
        {isDev()}
      </div>
    </header>
  );
}

export default HeaderContainer;