// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebar-link--DCNM\\+{font-size:func-rem(18);text-decoration:none;color:var(--main-color);border-radius:0.5rem;padding:0.25rem 0.5rem;transition:background linear .3s}.sidebar-link--DCNM\\+:hover,.sidebar-link--DCNM\\+.active--p4SgJ{color:var(--admin-btn-hover);background:var(--admin-btn-hover-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Sidebar/SidebarContainer/SidebarLink/SidebarLink.module.sass"],"names":[],"mappings":"AAEA,sBACE,sBAAA,CACA,oBAAA,CACA,uBAAA,CACA,oBAAA,CACA,sBAAA,CACA,gCAAA,CACA,gEACE,4BAAA,CACA,uCAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.sidebar-link\n  font-size: func-rem(18)\n  text-decoration: none\n  color: var(--main-color)\n  border-radius: func.rem(8)\n  padding: func.rem(4) func.rem(8)\n  transition: background linear 0.3s\n  &:hover, &.active\n    color: var(--admin-btn-hover)\n    background: var(--admin-btn-hover-color)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar-link": "sidebar-link--DCNM+",
	"active": "active--p4SgJ"
};
export default ___CSS_LOADER_EXPORT___;
