import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearCutsData } from '@/store/actions/admin/superset/process/cuts/cuts';
import { CutsMap, ItemsListByLang, UnitsCutsSliceInitialState } from '@/store/types/admin/superset/cuts/unitsCuts';
import { loadUnitCuts, setUnitsCutsItems } from '@/store/actions/admin/superset/process/cuts/unitsCuts';

const initialState: UnitsCutsSliceInitialState = {
  cuts: {},
  cachedCuts: {},
  items: {},
  direction: false,
  self: true,
};

const unitsCutsSlice = createSlice({
  name: 'admin/superset/process/cuts/unitsCutsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUnitCuts.fulfilled, (
        state,
        action: PayloadAction<{ SNACuts: CutsMap, direction: boolean, self: boolean, hashedParams: string }>,
      ) => {
        const {
          SNACuts, direction, self, hashedParams,
        } = action.payload;
        state.cuts = SNACuts;
        state.direction = direction;
        state.self = self;

        state.cachedCuts[hashedParams] = SNACuts;
      })
      .addCase(setUnitsCutsItems, (
        state,
        action: PayloadAction<ItemsListByLang>,
      ) => {
        state.items = action.payload;
      })
      .addCase(clearCutsData, () => initialState);
  },
});

export default unitsCutsSlice.reducer;