// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-superset-list--PaL8O .section-list-supersets--6WMwX{margin-top:4.375rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ListSupersets.module.sass"],"names":[],"mappings":"AAIE,6DACE,mBAAA","sourcesContent":["@use 'src/styles/functions' as func\n@use 'src/styles/colors'\n\n.content-superset-list\n  .section-list-supersets\n    margin-top: func.rem(70)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-superset-list": "content-superset-list--PaL8O",
	"section-list-supersets": "section-list-supersets--6WMwX"
};
export default ___CSS_LOADER_EXPORT___;
