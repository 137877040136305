import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';

// eslint-disable-next-line
export const getSuperSupersetsList = createAsyncThunk(
  'super/supersetsList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get('/api/admin/super/admlist');

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);