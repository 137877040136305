// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".admin-signIn--sS5DE{width:18.75rem;margin:0 auto;flex-direction:column}.admin-signIn--sS5DE h3{margin-bottom:0.625rem;text-align:center}.admin-signIn--sS5DE .form-admin-auth--iI5j0{display:flex;flex-direction:column;gap:0.625rem}.admin-signIn--sS5DE .form-admin-auth--iI5j0 .label-field--LoH-p{width:100%}.admin-signIn--sS5DE .form-admin-auth--iI5j0 .btn-on-signIn--SOAci{margin-top:0.625rem}.admin-signIn--sS5DE .link-switch-form--\\+KuzQ{display:block;text-align:center;margin-top:1.25rem;color:var(--main-color)}.admin-signIn--sS5DE .link-restore-password--Y7Gnj{display:block;font-size:0.875rem;text-align:center;margin-top:0.3125rem;color:var(--admin-main-font-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SignIn/SignIn.module.sass"],"names":[],"mappings":"AAEA,qBACE,cAAA,CACA,aAAA,CACA,qBAAA,CACA,wBACE,sBAAA,CACA,iBAAA,CACF,6CACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,iEACE,UAAA,CACF,mEACE,mBAAA,CACJ,+CACE,aAAA,CACA,iBAAA,CACA,kBAAA,CACA,uBAAA,CACF,mDACE,aAAA,CACA,kBAAA,CACA,iBAAA,CACA,oBAAA,CACA,kCAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.admin-signIn\n  width: func.rem(300)\n  margin: 0 auto\n  flex-direction: column\n  h3\n    margin-bottom: func.rem(10)\n    text-align: center\n  .form-admin-auth\n    display: flex\n    flex-direction: column\n    gap: func.rem(10)\n    .label-field\n      width: 100%\n    .btn-on-signIn\n      margin-top: func.rem(10)\n  .link-switch-form\n    display: block\n    text-align: center\n    margin-top: func.rem(20)\n    color: var(--main-color)\n  .link-restore-password\n    display: block\n    font-size: func.rem(14)\n    text-align: center\n    margin-top: func.rem(5)\n    color: var(--admin-main-font-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin-signIn": "admin-signIn--sS5DE",
	"form-admin-auth": "form-admin-auth--iI5j0",
	"label-field": "label-field--LoH-p",
	"btn-on-signIn": "btn-on-signIn--SOAci",
	"link-switch-form": "link-switch-form--+KuzQ",
	"link-restore-password": "link-restore-password--Y7Gnj"
};
export default ___CSS_LOADER_EXPORT___;
