// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".file-cover--obL96{position:absolute;display:flex;justify-content:center;align-items:center;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.5)}.file-cover--obL96:not(:hover) .remove-button--Ym5jA{display:none}.file-cover--obL96 .remove-button--Ym5jA{position:absolute}.file-cover--obL96 .remove-button--Ym5jA .remove-icon--16Wyy{display:block;font-size:2.5rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/SuperUploading/FilesForUploadList/FileForUpload/FileForUploadCover/FileForUploadCover.module.sass"],"names":[],"mappings":"AAEA,mBACE,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,yBAAA,CAEE,qDACE,YAAA,CACJ,yCACE,iBAAA,CACA,6DACE,aAAA,CACA,gBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.file-cover\n  position: absolute\n  display: flex\n  justify-content: center\n  align-items: center\n  top: 0\n  left: 0\n  width: 100%\n  height: 100%\n  background: rgb(0, 0, 0, 0.5)\n  &:not(:hover)\n    .remove-button\n      display: none\n  .remove-button\n    position: absolute\n    .remove-icon\n      display: block\n      font-size: func.rem(40)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-cover": "file-cover--obL96",
	"remove-button": "remove-button--Ym5jA",
	"remove-icon": "remove-icon--16Wyy"
};
export default ___CSS_LOADER_EXPORT___;
