import React, { HTMLAttributes, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import LrDarkLogo from 'static/img/lr-logo-dark.svg';
import AzimuthLightLogo from 'static/img/azimuth-logo-light.svg';
import AzimuthDarkLogo from 'static/img/azimuth-logo-dark.svg';
import LrFavicon from 'static/img/favicon/favicon-lr.svg';
import AzimuthFavicon from 'static/img/favicon/favicon-azimuth.svg';
import LrLightLogo from 'static/img/lr-logo-light.svg';
import useTheme from '@/theme/useTheme';
import LogoContainer
  from '@/components/Common/BaseTemplate/Header/HeaderContainer/HeaderLogo/LogoContainer/LogoContainer';
import styles from './HeaderLogo.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

const logo = {
  azimuth: {
    light: AzimuthLightLogo,
    dark: AzimuthDarkLogo,
  },
  'lost.report': {
    light: LrLightLogo,
    dark: LrDarkLogo,
  },
};

interface HeaderLogoProps extends HTMLAttributes<HTMLImageElement> {
  linkPath?: string
  src?: { light: string, dark: string }
}
function HeaderLogo({ linkPath, src, ...imgProps }: HeaderLogoProps) {
  const { theme } = useTheme();
  const { className, ...otherProps } = imgProps;

  const logoType = useMemo(() => (window.location.host.includes('bssl')
    || window.location.host.includes('azimuth')
    ? 'azimuth'
    : 'lost.report'), []);

  const [imgSrc, setImageSrc] = useState(src || logo[logoType]);

  const handleImageError = () => {
    setImageSrc(logo[logoType]);
  };

  return (
    <>
      <Helmet>
        <title>{logoType}</title>
        <link
          rel="icon"
          sizes="any"
          href={`${logoType === 'azimuth' ? AzimuthFavicon : LrFavicon}`}
        />
      </Helmet>
      <LogoContainer
        path={linkPath}
      >
        <img
          src={imgSrc[theme]}
          className={getClearClassNames([
            styles.logo,
            className,
          ])}
          alt=""
          onError={handleImageError}
          {...otherProps}
        />
      </LogoContainer>
    </>
  );
}

export default HeaderLogo;