// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input-label--X4qSs{display:flex;align-items:center;gap:0.75rem}.input-label--X4qSs .input--T70QY{border-radius:0.5rem;border:1px solid var(--secondary-color);padding:0.5rem 0.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/UsersListPage/UsersList/SuperAdminUser/SuperAdminUserForm/SuperAdminUserInput/SuperAdminUserInput.module.sass"],"names":[],"mappings":"AAEA,oBACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,kCACE,oBAAA,CACA,uCAAA,CACA,sBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.input-label\n  display: flex\n  align-items: center\n  gap: func.rem(12)\n  .input\n    border-radius: func.rem(8)\n    border: 1px solid var(--secondary-color)\n    padding: func.rem(8) func.rem(4)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-label": "input-label--X4qSs",
	"input": "input--T70QY"
};
export default ___CSS_LOADER_EXPORT___;
