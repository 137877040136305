import React, { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from './AdminMainListLink.module.sass';
import AdminMainListLinkDateContent from './AdminMainListDateContent/AdminMainListLinkDateContent';
import LinkDates from './types';

interface AdminMainListLinkProps extends LinkProps {
  linkTitle: string | ReactNode;
  dates?: LinkDates;
  metaInfo?: ReactNode;
}

export default function AdminMainListLink({
  linkTitle, dates, metaInfo, children, ...linkProps
}: AdminMainListLinkProps) {
  return (
    <ul className={styles.container}>
      <div className={styles.information}>
        <div className={styles['main-info']}>
          <Link
            className={styles.title}
            {...linkProps}
          >
            {linkTitle}
          </Link>
          <AdminMainListLinkDateContent dates={dates} />
        </div>
        {metaInfo && <div className={styles.meta}>{metaInfo}</div>}
      </div>
    </ul>
  );
}