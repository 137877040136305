import React, { Dispatch, SetStateAction } from 'react';
import styles from './CalculateResultsModalContent.module.sass';
import SelectCheckpoint from './SelectCheckpoint/SelectCheckpoint';
import SelectUnitFilter from './SelectUnitsFilter/SelectUnitFilter';
import { UnitsFilters } from './SelectUnitsFilter/SelectUnitFilter/SelectUnitFilterRadio';
import useLoadCalculateModalResources from './hooks/loadCalculateModalResources';
import Preloader from '@/components/App/Admin/SupersetSettings/Preloader';

interface CalculateResultsModalContentProps {
  selectedCheckpointId: string;
  setSelectedCheckpointId: Dispatch<SetStateAction<string>>;
  selectedSpecs: string[];
  selectedFilterId: UnitsFilters;
  setSelectedFilterId: Dispatch<SetStateAction<UnitsFilters>>;
  setSelectedSpecs: Dispatch<SetStateAction<string[]>>;
}

function CalculateResultsModalContent({
  selectedCheckpointId,
  setSelectedCheckpointId,
  selectedSpecs,
  setSelectedSpecs,
  selectedFilterId,
  setSelectedFilterId,
}: CalculateResultsModalContentProps) {
  const [isLoaded, checkpoints, selectedUnitsCount, unitsArray] = useLoadCalculateModalResources();

  if (!isLoaded) return <Preloader />;

  return (
    <div className={styles['modal-content']}>
      <SelectCheckpoint
        selectedCheckpointId={selectedCheckpointId}
        setSelectedCheckpointId={setSelectedCheckpointId}
        checkpoints={checkpoints}
      />
      { !!unitsArray.length && (
      <SelectUnitFilter
        selectedFilterId={selectedFilterId}
        setSelectedFilterId={setSelectedFilterId}
        selectedSpecs={selectedSpecs}
        setSelectedSpecs={setSelectedSpecs}
        selectedUnitsCount={selectedUnitsCount}
        unitsArray={unitsArray}
      />
      )}
    </div>
  );
}

export default CalculateResultsModalContent;