import React, {
  HTMLAttributes,
  useCallback, useEffect, useRef, useState,
} from 'react';
import './MainButton.sass';
import Macros from '@/components/App/User/Common/Macros/Macros';

interface MainButtonProps extends HTMLAttributes<HTMLButtonElement> {
  type?: 'primary' | 'secondary' | 'disabled';
  onClick: () => void;
  color?: string;
}

export default function MainButton({
  type = 'primary',
  color = '',
  ...buttonProps
}: MainButtonProps) {
  const [isClicked, toggleClicked] = useState<boolean>(false);
  const timeoutRef = useRef<Timeout>(null);
  const { onClick, children, ...otherProps } = buttonProps;

  const clickOn = useCallback(() => {
    toggleClicked(true);
    timeoutRef.current = setTimeout(() => toggleClicked(false), 10000);
  }, []);

  const clickOff = useCallback(() => {
    toggleClicked(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  useEffect(
    () => () => timeoutRef.current && clearTimeout(timeoutRef.current),
    [],
  );

  return (
    <button
      type="button"
      className={`main-button ${type}`}
      style={color && type !== 'disabled' ? { background: color } : {}}
      onClick={() => {
        if (isClicked) return;
        clickOn();
        onClick();
        clickOff();
      }}
      {...otherProps}
    >
      {typeof children === 'string' ? <Macros>{children}</Macros> : children}
    </button>
  );
}