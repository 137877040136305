import React from 'react';
import ListSupersets from '../ListSupersets';
import SupersetsListPage from '../../SupersetsListPage/SupersetsListPage';

export default function AdminSupersets() {
  return (
    <SupersetsListPage>
      {ListSupersets}
    </SupersetsListPage>
  );
}