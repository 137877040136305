// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-unit-form--JEavs{display:grid;grid-template-columns:min-content 1fr;align-items:center;width:100%;grid-gap:1rem 1rem}.create-unit-form--JEavs .checkbox--e4XUv{grid-column:1/-1}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/CreateBUnitForm/CreateBUnitForm.module.sass"],"names":[],"mappings":"AAEA,yBACE,YAAA,CACA,qCAAA,CACA,kBAAA,CACA,UAAA,CACA,kBAAA,CACA,0CACE,gBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.create-unit-form\n  display: grid\n  grid-template-columns: min-content 1fr\n  align-items: center\n  width: 100%\n  grid-gap: func.rem(16) func.rem(16)\n  .checkbox\n    grid-column: 1 / -1"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create-unit-form": "create-unit-form--JEavs",
	"checkbox": "checkbox--e4XUv"
};
export default ___CSS_LOADER_EXPORT___;
