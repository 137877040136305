// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-new-btn--pdPsp{width:1.625rem;height:1.625rem;padding:0.25rem 0.5rem;background:var(--admin-background-interactive-elements);color:var(--main-color);font-size:1rem;font-weight:400;border-radius:0.5rem}.create-new-btn--pdPsp:hover{cursor:pointer;background:var(--admin-hover-background-interactive-elements)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/CreateNewSpecBtn/CreateNewSpecBtn.module.sass"],"names":[],"mappings":"AAEA,uBACE,cAAA,CACA,eAAA,CACA,sBAAA,CACA,uDAAA,CACA,uBAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CACA,6BACE,cAAA,CACA,6DAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.create-new-btn\n  width: func.rem(26)\n  height: func.rem(26)\n  padding: func.rem(4) func.rem(8)\n  background: var(--admin-background-interactive-elements)\n  color: var(--main-color)\n  font-size: func.rem(16)\n  font-weight: 400\n  border-radius: func.rem(8)\n  &:hover\n    cursor: pointer\n    background: var(--admin-hover-background-interactive-elements)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create-new-btn": "create-new-btn--pdPsp"
};
export default ___CSS_LOADER_EXPORT___;
