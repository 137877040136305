import { MouseEvent, RefObject, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedObjectID } from '@/store/actions/admin/superset/process/cuts/fourD';

const useClickOutsideAndEsc = (
  windowRef: RefObject<HTMLElement>,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      if (windowRef.current && !windowRef.current.contains(e.target as Node)) {
        dispatch(setSelectedObjectID(null));
      }
    };

    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [windowRef]);
};

export default useClickOutsideAndEsc;