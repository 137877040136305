import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import axiosProcessing from '@/axiosConfig';
import {
  Rating, RatingResult, SelectedObject, SelectedParamIDs,
} from '@/store/types/admin/superset/cuts/fourD';
import { ABSOLUTE_SCORE_TYPE, PERCENTILE_SCORE_TYPE } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import { selectSelectedCriteriaInFormatToBeSent } from '@/store/selectors/admin/superset/process/cuts/cuts';
import { selectSelectedParamIDs } from '@/store/selectors/admin/superset/process/cuts/fourD';
import { AdminState } from '@/store/admin';
import { Criterion } from '@/store/types/admin/superset/cuts/criteria';

interface FormDataResultsByRatings {
  ratingX: string,
  ratingY: string,
  ratingSize: string,
  ratingColor: string,
  criterios?: Criterion[]
}

export interface ResponseRatingResult {
  id: string,
  name: string,
  perc: number,
  score: number,
  orgScore: {
    x: number,
    y: number,
    size: number,
    color: number,
  }
  color: string,
}

export interface ResponseRatingsResults {
  [key: string]: ResponseRatingResult[]
}

const combinedRatingsResultsData = (
  ratingsResults: ResponseRatingsResults,
  selectedParamIDs: SelectedParamIDs,
): RatingResult[] => {
  const {
    xRating, yRating, ratingSize, ratingColor,
  } = selectedParamIDs;

  const xData = ratingsResults[xRating];
  const yData = ratingsResults[yRating];
  const sizeData = ratingsResults[ratingSize];
  const colorData = ratingsResults[ratingColor] || null;

  return xData.reduce((acc, xItem) => {
    const yItem = yData.find(({ id: yID }) => yID === xItem.id);

    // If there is no data on the y axis
    if (!yItem) {
      return acc;
    }

    const sizeItem = sizeData.find(({ id: sizeID }) => sizeID === xItem.id);
    const sizeItemValues = {
      perc: sizeItem ? sizeItem.perc : 50,
      score: sizeItem ? sizeItem.score : 50,
      orgScore: sizeItem ? sizeItem.orgScore : null,
    };

    let colorItem = null;
    if (colorData) {
      colorItem = colorData.find(({ id: colorID }) => colorID === xItem.id);
    }

    acc.push({
      id: xItem.id,
      name: xItem.name,
      scores: {
        [PERCENTILE_SCORE_TYPE]: {
          xPerc: xItem.perc,
          yPerc: yItem.perc,
          sizePerc: sizeItemValues.perc,
        },
        [ABSOLUTE_SCORE_TYPE]: {
          xPerc: xItem.score,
          yPerc: yItem.score,
          sizePerc: sizeItemValues.score,
        },
      },
      // This is a block with data for displaying selected objects in tables. OrgScore - a special data format for displaying to users in the form of numbers (in tables).
      orgScore: {
        x: xItem.orgScore,
        y: yItem.orgScore,
        size: sizeItemValues.orgScore,
        color: colorItem ? colorItem.color : null,
      },
      color: colorItem ? colorItem.color : null,
    });

    return acc;
  }, [])
    // sort from more to less by size.
    .sort((a, b) => b.size - a.size);
};

export const loadRatingsFourD = createAsyncThunk<
Rating[],
void
>(
  'superset/admin/process/cuts/fourD/loadRatingsFourD',
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const { supersetId } = selectSuperset(state);
    // @ts-ignore
    const langID = selectCurrentLangID(state);

    try {
      const response = await axiosProcessing.get(
        `/api/admin/read4Dratings/${supersetId}/${langID}`,
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setSelectedParamIDs = createAction<SelectedParamIDs>(
  'superset/admin/process/cuts/fourD/setSelectedParamIDs',
);

export const getResultsByRatings = createAsyncThunk<
RatingResult[],
void
>(
  'superset/admin/process/cuts/fourD/getResultsByRatings',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;
    const { supersetId } = selectSuperset(state);
    const langID = selectCurrentLangID(state);
    const selectedCriteriaForSendingFormat = selectSelectedCriteriaInFormatToBeSent(state);
    const selectedParamIDs = selectSelectedParamIDs(state);

    const formParams: FormDataResultsByRatings = {
      ratingX: selectedParamIDs.xRating,
      ratingY: selectedParamIDs.yRating,
      ratingSize: selectedParamIDs.ratingSize,
      ...(selectedParamIDs.ratingColor && { ratingColor: selectedParamIDs.ratingColor }),
      criterios: selectedCriteriaForSendingFormat,
    };

    try {
      const response = await axiosProcessing.post(
        `/api/admin/read4DratingsScores/${supersetId}/${langID}`,
        formParams,
        { headers },
      );

      // console.log(response.data, 'response.data');

      return combinedRatingsResultsData(response.data, selectedParamIDs);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setScoreType = createAction<'PERCENTILE' | 'ABSOLUTE'>(
  'superset/admin/process/cuts/fourD/setScoreType',
);

export const setSelectionComplete = createAction<boolean>(
  'superset/admin/process/cuts/fourD/setSelectionComplete',
);

// object selected from list
export const setTargetedObjectID = createAction<string>(
  'superset/admin/process/cuts/fourD/setTargetedObjectID',
);

// object selected on the diagram
export const setSelectedObjectID = createAction<SelectedObject | null>(
  'superset/admin/process/cuts/fourD/setSelectedObjectID',
);