// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-title--tgkJn{font-weight:bold;margin-bottom:0.625rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingTemplateInfo/ErrorUploadingTemplate/ErrorUploadingTemplate.module.sass"],"names":[],"mappings":"AAEA,oBACE,gBAAA,CACA,sBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.error-title\n  font-weight: bold\n  margin-bottom: func.rem(10)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-title": "error-title--tgkJn"
};
export default ___CSS_LOADER_EXPORT___;
