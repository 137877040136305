import { useSelector } from 'react-redux';
import { RefObject, useEffect, useState } from 'react';
import { selectTargetedObjectID } from '@/store/selectors/admin/superset/process/cuts/fourD';

export const useGetDidTheCircleChooseStatus = (currentObjectID: string) => {
  const selectedObjectID = useSelector(selectTargetedObjectID);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(selectedObjectID === currentObjectID);
  }, [currentObjectID, selectedObjectID]);

  return isSelected;
};

export const useTargetingSelectedObject = (
  circleRef: RefObject<SVGCircleElement>,
  isSelected: boolean,
) => {
  useEffect(() => {
    if (!isSelected) return;

    if (circleRef.current) {
      circleRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [circleRef, isSelected]);
};