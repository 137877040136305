import { useCallback, useState } from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectActiveLangIDsSortedByPriority } from '@/store/selectors/admin/superset/projects';

const useSelectEmailTemplates = () => {
  const activeProjectLangsId = useAdminSelector(selectActiveLangIDsSortedByPriority);
  const { mailTemplates } = useAdminSelector((state) => state.admin.superset.supersetFields);
  const { languages } = useAdminSelector((state) => state.admin.superset.supersetFields);

  const selectDefaultTemplateLang = useCallback(() => {
    if (Object.keys(mailTemplates).length === 1) {
      return Object.keys(mailTemplates)[0];
    }

    for (let i = 0; i < activeProjectLangsId.length; i += 1) {
      const langId = activeProjectLangsId[i];
      if (mailTemplates[langId]) {
        return langId;
      }
    }

    const sortedLangsId = Object.keys(languages).sort(
      (A, B) => languages[A].priority - languages[B].priority,
    );

    for (let i = 0; i < sortedLangsId.length; i += 1) {
      const langId = sortedLangsId[i];
      if (mailTemplates[langId]) {
        return langId;
      }
    }

    return Object.keys(mailTemplates)[0];
  }, [activeProjectLangsId, languages, mailTemplates]);

  const [currentTemplateLang, setCurrentTemplateLang] = useState(selectDefaultTemplateLang);

  const selectDefaultTemplate = useCallback(
    () => {
      const templatesByLangId = Object.keys(mailTemplates[currentTemplateLang]);
      return templatesByLangId[0];
    },
    [currentTemplateLang, mailTemplates],
  );

  const [currentTemplateId, setCurrentTemplateId] = useState(selectDefaultTemplate);

  return [[currentTemplateLang, setCurrentTemplateLang], [currentTemplateId, setCurrentTemplateId]];
};

export default useSelectEmailTemplates;