import { useCallback } from 'react';
import { selectAdmin } from '@/store/selectors/admin/authentication';
import { useAdminSelector } from '@/store/hooks/adminRedux';

const useCheckPermission = () => {
  const admin = useAdminSelector(selectAdmin);
  const { admins } = useAdminSelector(
    (state) => state.admin.superset.supersetFields,
  );

  return useCallback(
    (key?: string): boolean => {
      if (admin.isSuper) return true;
      if (!key) return false;
      const { permissions } = admins[admin.adminId]
        ? admins[admin.adminId]
        : { permissions: {} };
      return !!permissions[key];
    },
    [admin, admins],
  );
};

export default useCheckPermission;