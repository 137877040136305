// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title-success--B6ulK{color:var(--admin-main-font-color);font-size:1rem;font-weight:600;margin-bottom:0.625rem}.comment-field--ZiYfi{display:flex;gap:0.625rem;padding-top:0.375rem;padding-right:1rem;align-items:center;width:auto}.comment-field--ZiYfi .input--sRr9k{border-radius:0.5rem;padding:0.25rem 0.375rem;border:1px solid var(--secondary-color);font-size:1rem;width:100%;color:var(--admin-main-font-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingTemplateInfo/SuccessUploadingTemplateInfo/SuccessUploadingTemplateInfo.module.sass"],"names":[],"mappings":"AAEA,sBACE,kCAAA,CACA,cAAA,CACA,eAAA,CACA,sBAAA,CACF,sBACE,YAAA,CACA,YAAA,CACA,oBAAA,CACA,kBAAA,CACA,kBAAA,CACA,UAAA,CACA,oCACE,oBAAA,CACA,wBAAA,CACA,uCAAA,CACA,cAAA,CACA,UAAA,CACA,kCAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.title-success\n  color: var(--admin-main-font-color)\n  font-size: 1rem\n  font-weight: 600\n  margin-bottom: func.rem(10)\n.comment-field\n  display: flex\n  gap: func.rem(10)\n  padding-top: func.rem(6)\n  padding-right: func.rem(16)\n  align-items: center\n  width: auto\n  .input\n    border-radius: func.rem(8)\n    padding: func.rem(4) func.rem(6)\n    border: 1px solid var(--secondary-color)\n    font-size: func.rem(16)\n    width: 100%\n    color: var(--admin-main-font-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title-success": "title-success--B6ulK",
	"comment-field": "comment-field--ZiYfi",
	"input": "input--sRr9k"
};
export default ___CSS_LOADER_EXPORT___;
