import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './CustomSelect.module.sass';
import DropdownList from '@/components/App/Admin/BUnitsSection/CustomSelect/DropdownList';
import CustomSelectByType from '@/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectByType/CustomSelectByType';

export default function CustomSelect({
  activeValue,
  type = 'button',
  mainBtnClassNames = '',
  children = [],
  isOpen,
  toggleOpen,
  onKeyUp = undefined,
}) {
  const currentDropdown = useRef(null);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        currentDropdown.current !== null
        && !currentDropdown.current.contains(e.target)
      ) {
        toggleOpen(false);
      }
    };
    if (isOpen) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isOpen, toggleOpen]);

  return (
    <div
      className={`${styles['dropdown-container']} ${
        isOpen && styles.opened
      } ${mainBtnClassNames}`}
      ref={currentDropdown}
    >
      <CustomSelectByType
        activeValue={activeValue}
        type={type}
        toggleOpen={toggleOpen}
        isOpen={isOpen}
        onKeyUp={onKeyUp}
      />
      {isOpen && <DropdownList>{children}</DropdownList>}
    </div>
  );
}

CustomSelect.propTypes = {
  activeValue: PropTypes.string.isRequired,
  type: PropTypes.string,
  mainBtnClassNames: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onKeyUp: PropTypes.func,
};