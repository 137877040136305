// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".article-block-list--NueAB{display:flex;flex-direction:column;align-items:stretch;justify-content:center;flex:9}", "",{"version":3,"sources":["webpack://./src/components/App/Super/Articles/ArticleEditor/ArticleBlockList/ArticleBlockList.module.sass"],"names":[],"mappings":"AAAA,2BACI,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,sBAAA,CACA,MAAA","sourcesContent":[".article-block-list\n    display: flex\n    flex-direction: column\n    align-items: stretch\n    justify-content: center\n    flex: 9\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"article-block-list": "article-block-list--NueAB"
};
export default ___CSS_LOADER_EXPORT___;
