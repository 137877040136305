// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blocks-section--sZP5G .list-blocks--T6HtG{display:flex;flex-direction:column;gap:0.75rem;padding-right:6.25rem}.blocks-section--sZP5G .list-blocks--T6HtG .item-block--Lxj4q .descr-block--vCG3x{color:#85878e;font-size:.875rem;margin-top:.3125rem;margin-left:1.5625rem}.blocks-section--sZP5G .list-blocks--T6HtG .item-block--Lxj4q .descr-block--vCG3x ul{margin-top:0.9375rem;display:flex;flex-direction:column;gap:0.3125rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionBlocks/SectionBlocks.module.sass"],"names":[],"mappings":"AAGE,2CACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,qBAAA,CAEE,kFACE,aAAA,CACA,iBAAA,CACA,mBAAA,CACA,qBAAA,CACA,qFACE,oBAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.blocks-section\n  .list-blocks\n    display: flex\n    flex-direction: column\n    gap: func.rem(12)\n    padding-right: func.rem(100)\n    .item-block\n      .descr-block\n        color: #85878E\n        font-size: 0.875rem\n        margin-top: 0.3125rem\n        margin-left: func.rem(25)\n        ul\n          margin-top: func.rem(15)\n          display: flex\n          flex-direction: column\n          gap: func.rem(5)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blocks-section": "blocks-section--sZP5G",
	"list-blocks": "list-blocks--T6HtG",
	"item-block": "item-block--Lxj4q",
	"descr-block": "descr-block--vCG3x"
};
export default ___CSS_LOADER_EXPORT___;
