// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".add-button--bemQZ{background:var(--admin-btn-blue);height:1.5rem;width:1.5rem;border-radius:0.5rem;color:#fff;justify-self:center}.add-button--bemQZ.disabled--IxKbB{background:none;color:var(--secondary-color);border:1px solid var(--secondary-color)}.add-button--bemQZ:hover{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/AddNewHowToButton/AddNewHowToButton.module.sass"],"names":[],"mappings":"AAEA,mBACE,gCAAA,CACA,aAAA,CACA,YAAA,CACA,oBAAA,CACA,UAAA,CACA,mBAAA,CACA,mCACE,eAAA,CACA,4BAAA,CACA,uCAAA,CACF,yBACE,cAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.add-button\n  background: var(--admin-btn-blue)\n  height: func.rem(24)\n  width: func.rem(24)\n  border-radius: func.rem(8)\n  color: #FFFFFF\n  justify-self: center\n  &.disabled\n    background: none\n    color: var(--secondary-color)\n    border: 1px solid var(--secondary-color)\n  &:hover\n    cursor: pointer"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add-button": "add-button--bemQZ",
	"disabled": "disabled--IxKbB"
};
export default ___CSS_LOADER_EXPORT___;
