import React, {
  Dispatch,
  SetStateAction, useMemo,
} from 'react';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';

import SelectSpecs from '../SelectSpecs/SelectSpecs';
import SelectUnitFilterRadio, { UnitsFilters } from './SelectUnitFilter/SelectUnitFilterRadio';
import { BackendUnit } from '@/store/types/admin/superset/unitsTree';

interface SelectUnitFilterProps {
  selectedFilterId: UnitsFilters;
  setSelectedFilterId: Dispatch<SetStateAction<UnitsFilters>>
  selectedSpecs: string[];
  setSelectedSpecs: Dispatch<SetStateAction<string[]>>;
  selectedUnitsCount: number;
  unitsArray: BackendUnit[];
}

export default function SelectUnitFilter({
  selectedFilterId,
  setSelectedFilterId,
  selectedSpecs,
  setSelectedSpecs,
  selectedUnitsCount,
  unitsArray,
}: SelectUnitFilterProps) {
  const { specs } = useAdminSelector(selectSuperset);

  const filteredSpecs = useMemo<string[]>(() => {
    if (!specs || !specs.length) return [];
    return specs.filter((sp: string) => !!sp).sort();
  }, [specs]);

  return (
    <section>
      {Object.values(UnitsFilters).map((value) => (
        <SelectUnitFilterRadio
          key={`calculate-modal-${value}`}
          id={value}
          selectedFilterId={selectedFilterId}
          setSelectedFilterId={setSelectedFilterId}
          selectedUnitsCount={selectedUnitsCount}
          filteredSpecs={filteredSpecs}
        />
      ))}

      {selectedFilterId === UnitsFilters.BySpec && (
      <SelectSpecs
        selectedSpecs={selectedSpecs}
        setSelectedSpecs={setSelectedSpecs}
        unitsArray={unitsArray}
      />
      )}
    </section>
  );
}