import {
  BUnitSelectOption,
  UnitSelectOptionsNlsKeys,
  UnitsSelectOptionsFlags,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectOption';
import {
  BUnitSelectAction,
  UnitsSelectActionFlags,
  UnitsSelectActionNlsByFlag,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectAction';
import {
  BUnitGroupAction,
  UnitActionsKeysByOperationType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/useBUnitGroupAction';
import { MailTemplatesByLang } from '@/store/types/admin/superset/superset';

export const getSelectActionByType = (type: UnitsSelectActionFlags, getNls: (arg: string) => string): BUnitSelectAction => ({
  key: type,
  name: getNls(UnitsSelectActionNlsByFlag[type]),
});

export const getSelectOptionByType = (type: UnitsSelectOptionsFlags, getNls: (arg: string) => string): BUnitSelectOption => ({
  key: type,
  name: getNls(UnitSelectOptionsNlsKeys[type]),
});

export const getOperationByType = (type: keyof typeof UnitActionsKeysByOperationType, getNLS: (arg: string) => string): BUnitGroupAction => ({
  name: getNLS(UnitActionsKeysByOperationType[type]),
  operationType: type,
});

export const getBUnitsOperations = (
  mailTemplates: MailTemplatesByLang,
  getNls: (arg: string) => string,
): BUnitGroupAction[] => Object.keys(UnitActionsKeysByOperationType).flatMap(
  (type) => {
    if (type === 'sendEmail' && !(mailTemplates && Object.keys(mailTemplates).length)) return [];
    return getOperationByType(type as keyof typeof UnitActionsKeysByOperationType, getNls);
  },
);

export const getSelectActonsWithEnums = (getNls: (arg: string) => string): BUnitSelectAction[] => Object.values(UnitsSelectActionFlags).map(
  (value) => getSelectActionByType(value, getNls),
);

export const getSelectOptionsWithEnums = (getNls: (arg: string) => string): BUnitSelectOption[] => Object.values(UnitsSelectOptionsFlags).map(
  (value) => getSelectOptionByType(value, getNls),
);