// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title---e6dc{text-align:right;color:var(--secondary-color)}.title---e6dc:hover{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/CreateBUnitForm/SelectTargetDepartment/SelectTargetDepartment.module.sass"],"names":[],"mappings":"AAEA,cACE,gBAAA,CACA,4BAAA,CACA,oBACE,cAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.title\n  text-align: right\n  color: var(--secondary-color)\n  &:hover\n    cursor: pointer\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title---e6dc"
};
export default ___CSS_LOADER_EXPORT___;
