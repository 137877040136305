import React, { useContext } from 'react';
import styles from './BUnitStatusIcons.module.sass';
import BUnitContext, { BUnitContextProps } from '../BUnitContext';
import BUnitStatusIcon, { IconType } from './BUnitStatusIcon/BUnitStatusIcon';
import { getClearClassNames } from '@/helpers/helpers';

// interface BUnitStatusIconsProps {

// }

export default function BUnitStatusIcons(/* {}: BUnitStatusIconsProps */) {
  const { unit } = useContext(BUnitContext) as Required<BUnitContextProps>;

  if (unit.isunit !== 0) return null;

  const { activeObject, activeSubject } = unit;

  return (
    <div className={styles['status-icons-container']}>
      <BUnitStatusIcon
        nlsKey=""
        iconType={IconType.Assessed}
        additionalClassNames={getClearClassNames([
          styles.active,
          !activeSubject && styles.deactivated,
        ])}
        isVisible
      />
      <BUnitStatusIcon
        iconType={IconType.Assesses}
        nlsKey=""
        additionalClassNames={getClearClassNames([
          styles.active,
          !activeObject && styles.deactivated,
        ])}
        isVisible
      />
    </div>
  );
}