import React, { useEffect } from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormatOptionLabelMeta } from 'react-select';
import BaseSelect from '@/components/Common/form/BaseSelect';
import CustomButton from '@/components/App/Admin/UIElements/CustomButton';
import {
  getResultsByRatings,
  setSelectedParamIDs,
} from '@/store/actions/admin/superset/process/cuts/fourD';
import { selectRatings, selectRatingsWithColors } from '@/store/selectors/admin/superset/process/cuts/fourD';
import ScoreTypeToggler from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/ScoreTypeToggler';
import styles from './FourDParamsForm.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { MAP_SOURS_NUMBER_TO_SOURCE_NLS } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import ColorParam from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/ColorParam';
import useTransformRatingToGroupedRatingsOptions
  from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/hooks/hooks';
import { Rating } from '@/store/types/admin/superset/cuts/fourD';

export interface FormValues {
  axisX: { value: string; label: string };
  axisY: { value: string; label: string };
  size: { value: string; label: string };
  color: { value: string, label: string };
}

export interface Option {
  label: string;
  value: string | number;
  sourceType: number,
}

export interface GroupedData {
  label: string,
  options: Option[],
}

function FourDParamsForm() {
  const dispatch = useDispatch();
  const ratings = useSelector(selectRatings);
  const ratingsWithColors = useSelector(selectRatingsWithColors);

  const { handleSubmit, control, reset } = useForm();

  const ratingOptions = useTransformRatingToGroupedRatingsOptions(ratings);
  const tNLS = useNLS();

  useEffect(() => {
    const getParamValue = (rating: Rating) => (
      {
        value: rating.id,
        label: rating.name,
        sourceType: rating.source,
      }
    );
    const ratingFallback = ratings[ratings.length - 1];

    reset({
      axisX: (ratings[0] && getParamValue(ratings[0])) || getParamValue(ratingFallback),
      axisY: (ratings[1] && getParamValue(ratings[1])) || getParamValue(ratingFallback),
      size: (ratings[2] && getParamValue(ratings[2])) || getParamValue(ratingFallback),
      ...(ratingsWithColors.length > 0
        ? { color: getParamValue(ratingsWithColors[0]) }
        : {}),
    });
  }, [ratings, ratingsWithColors, reset]);

  const onChangeDiagram:SubmitHandler<FormValues> = (formData) => {
    dispatch(setSelectedParamIDs({
      xRating: formData.axisX.value,
      yRating: formData.axisY.value,
      ratingSize: formData.size.value,
      ...(formData.color?.value && { ratingColor: formData.color.value }),
    }));

    dispatch(
      getResultsByRatings(),
    );
  };

  const changeOptionValue = (option: Option, { context }: FormatOptionLabelMeta<Option>) => {
    if (context === 'value') {
      return `${option.label}: ${tNLS(MAP_SOURS_NUMBER_TO_SOURCE_NLS[option.sourceType].short)}`;
    }

    return option.label;
  };

  const formatGroupLabel = (data: GroupedData) => (
    <div className={styles['wrap-base-select__group']}>
      <span className={styles['base-select__group_label']}>{data.label}</span>
      <span className={styles['base-select__group_options_length']}>{data.options.length}</span>
    </div>
  );

  return (
    <div className={styles['wrap-form']}>
      <ScoreTypeToggler />

      <form className={styles['fourD-params-form']} onSubmit={handleSubmit(onChangeDiagram)}>
        <div className={styles['wrap-params']}>
          <div className={styles['column-params']}>
            <Controller
              name="axisX"
              control={control}
              render={({ field }) => (
                <BaseSelect
                  id="axisXField"
                  {...field}
                  label={tNLS('results.4d.x')}
                  placeholder="Выберете параметр"
                  options={ratingOptions}
                  formatGroupLabel={formatGroupLabel}
                  isSearchable
                  formatOptionLabel={changeOptionValue}
                  classNames={{
                    container: () => styles['select-parameter'],
                  }}
                />
              )}
            />

            <Controller
              name="axisY"
              control={control}
              render={({ field }) => (
                <BaseSelect
                  id="axisYField"
                  {...field}
                  label={tNLS('results.4d.y')}
                  placeholder="Выберете параметр"
                  options={ratingOptions}
                  formatGroupLabel={formatGroupLabel}
                  isSearchable
                  formatOptionLabel={changeOptionValue}
                  classNames={{
                    container: () => styles['select-parameter'],
                  }}
                />
              )}
            />
          </div>

          <div className={styles['column-params']}>
            <Controller
              name="size"
              control={control}
              render={({ field }) => (
                <BaseSelect
                  id="sizeField"
                  {...field}
                  label={tNLS('results.4d.size')}
                  placeholder="Выберете параметр"
                  options={ratingOptions}
                  formatGroupLabel={formatGroupLabel}
                  isSearchable
                  formatOptionLabel={changeOptionValue}
                  classNames={{
                    container: () => styles['select-parameter'],
                  }}
                />
              )}
            />

            {ratingsWithColors.length > 0 && (
            <Controller
              name="color"
              control={control}
              render={({ field }) => (
                <ColorParam
                  ratings={ratingsWithColors}
                  field={{ ...field }}
                  externalClasses={{
                    container: () => styles['select-parameter'],
                  }}
                  formatGroupLabel={formatGroupLabel}
                  changeOptionValue={changeOptionValue}
                />
              )}
            />
            )}

          </div>
        </div>

        <CustomButton
          styleTypeBtn="btn-blue btn-large"
          typeBtnSubmit
          classNames={styles['btn-on-restore-account']}
        >
          Построить схему
        </CustomButton>
      </form>

    </div>
  );
}

export default FourDParamsForm;