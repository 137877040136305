// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--UBIDo{display:flex;flex-direction:column;min-height:100vh;overflow:hidden}.wrapper--UBIDo.without-select--0itCa{-webkit-user-select:none;user-select:none}.wrapper--UBIDo .main--LofFx{flex-grow:1}@media screen and (max-width: 768px){.wrapper--UBIDo .main--LofFx{margin-top:0}}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/BaseWrapper/BaseWrapper.module.sass"],"names":[],"mappings":"AAAA,gBACE,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,eAAA,CACA,sCACE,wBAAA,CAAA,gBAAA,CACF,6BACE,WAAA,CACA,qCAFF,6BAGI,YAAA,CAAA","sourcesContent":[".wrapper\n  display: flex\n  flex-direction: column\n  min-height: 100vh\n  overflow: hidden\n  &.without-select\n    user-select: none\n  .main\n    flex-grow: 1\n    @media screen and (max-width: 768px)\n      margin-top: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--UBIDo",
	"without-select": "without-select--0itCa",
	"main": "main--LofFx"
};
export default ___CSS_LOADER_EXPORT___;
