import React, { useMemo } from 'react';
import { usePlayerSelector } from '@/store/hooks/playerRedux';
import { selectActorTeamCode } from '@/store/selectors/user/actor';
import { Theme } from '@/theme/themeContext';
import HeaderLogo from '../../HeaderContainer/HeaderLogo/HeaderLogo';
import { getClearClassNames } from '@/helpers/helpers';
import styles from './UserHeaderLogo.module.sass';

export default function UserHeaderLogo() {
  const teamCode = usePlayerSelector(selectActorTeamCode);

  const logoSrc = useMemo(() => {
    if (!teamCode) return undefined;
    return (
      {
        dark: `/api/actor/${teamCode}/logo/${Theme.DARK}`,
        light: `/api/actor/${teamCode}/logo/${Theme.LIGHT}`,
      });
  }, [teamCode]);

  if (!teamCode) return null;

  return (
    <div className={getClearClassNames([
      'user-logo',
      styles.logo,
    ])}
    >
      <div className={styles['logo-container']}>
        <HeaderLogo
          src={logoSrc}
        />
      </div>
    </div>
  );
}