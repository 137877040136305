import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from './UnitForTransfer.module.sass';
import { selectTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import { UnitId } from '@/store/types/user/units';
import { UnitsSelectActionFlags } from '../../../../SelectMenu/useSelectAction';

interface UnitForChangeProps {
  name: string;
  departments?: string[];
  id: UnitId;
  spec: string[];
  actionType: 'transfer' | 'addSpec'
}

function UnitForChange({
  name,
  departments,
  id,
  spec,
  actionType,
}: UnitForChangeProps) {
  const dispatch = useDispatch();

  const unselectUnit = () => {
    dispatch(
      selectTreeUnits({
        unitsId: [id],
        selectValue: UnitsSelectActionFlags.Unselect,
      }),
    );
  };

  return (
    <li className={styles['unit-with-path']}>
      <button
        type="button"
        className={`btn-without-style ${styles['unselect-button']}`}
        onClick={() => unselectUnit()}
      >
        <span className="icon-remove-light" />
        {' '}
      </button>
      <div className={styles['unit-name']}>
        <span>{name}</span>
        {actionType === 'transfer' && departments && (
          <div className={styles['unit-path']}>{departments.join('->')}</div>
        )}
        {actionType === 'addSpec' && spec && (
          <div className={styles['unit-path']}>{spec.join(', ')}</div>
        )}
      </div>
    </li>
  );
}

UnitForChange.propTypes = {
  name: PropTypes.string.isRequired,
  departments: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
};

UnitForChange.defaultProps = {
  departments: undefined,
};

export default UnitForChange;