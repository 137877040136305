// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-copy-superset--7jOeK .form-select-master-superset--ksKoC{margin-bottom:1.25rem}.wrap-copy-superset--7jOeK .form-select-master-superset--ksKoC .option-superset--bfZ-T .wrap-option-label--DVLI6{display:flex;flex-direction:column;gap:0.625rem}.wrap-copy-superset--7jOeK .form-select-master-superset--ksKoC .option-superset--bfZ-T .wrap-option-label--DVLI6 .description--3ang9{color:#85878e;font-size:0.875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/CopySuperset/CopySuperset.module.sass"],"names":[],"mappings":"AAGE,+DACE,qBAAA,CAEE,iHACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,qIACE,aAAA,CACA,kBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.wrap-copy-superset\n  .form-select-master-superset\n    margin-bottom: func.rem(20)\n    .option-superset\n      .wrap-option-label\n        display: flex\n        flex-direction: column\n        gap: func.rem(10)\n        .description\n          color: #85878E\n          font-size: func.rem(14)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-copy-superset": "wrap-copy-superset--7jOeK",
	"form-select-master-superset": "form-select-master-superset--ksKoC",
	"option-superset": "option-superset--bfZ-T",
	"wrap-option-label": "wrap-option-label--DVLI6",
	"description": "description--3ang9"
};
export default ___CSS_LOADER_EXPORT___;
