import {
  MouseEvent, RefObject, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRatingsResultsByScoreType,
} from '@/store/selectors/admin/superset/process/cuts/fourD';
import { RatingResultByScoreType } from '@/store/types/admin/superset/cuts/fourD';
import calcCirclesAreaDimensions from '@/components/App/Admin/Results/Cuts/FourD/utils';
import { CONTAINER_PADDING } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import { setSelectionComplete } from '@/store/actions/admin/superset/process/cuts/fourD';

interface SelectionRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface UseSVGSelectionReturn {
  selectionRect: SelectionRect | null;
  onMouseDown: (e: MouseEvent<SVGElement>) => void;
  onMouseMove: (e: MouseEvent<SVGElement>) => void;
  onMouseUp: (e: MouseEvent<SVGElement>) => void;
  isSelecting: boolean;
}

export const useDrawingAreaSelectedObjects = (
  svgRef: RefObject<SVGSVGElement>,
) : UseSVGSelectionReturn => {
  const dispatch = useDispatch();
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStart, setSelectionStart] = useState(<{ x: number, y: number } | null>null);
  const [selectionRect, setSelectionRect] = useState(<SelectionRect | null>null);

  const getSVGCoordinates = (
    e: MouseEvent<SVGElement>,
    svg: SVGSVGElement,
  ) : { x: number; y: number } => {
    const ctm = svg.getScreenCTM();
    const point = new DOMPoint(e.clientX, e.clientY);
    const inverseCTM = ctm.inverse();
    const transformedPoint = point.matrixTransform(inverseCTM);

    return { x: transformedPoint.x, y: transformedPoint.y };
  };

  const onMouseDown = (e: MouseEvent<SVGElement>) => {
    if (!svgRef.current) return;

    const { x, y } = getSVGCoordinates(e, svgRef.current);
    setIsSelecting(true);
    setSelectionStart({ x, y });
    setSelectionRect(null);

    dispatch(setSelectionComplete(false));
  };

  const onMouseMove = (e: MouseEvent<SVGElement>) => {
    if (!isSelecting || !selectionStart || !svgRef.current) return;

    const { x, y } = getSVGCoordinates(e, svgRef.current);

    const rectX = Math.min(selectionStart.x, x);
    const rectY = Math.min(selectionStart.y, y);
    const rectWidth = Math.abs(x - selectionStart.x);
    const rectHeight = Math.abs(y - selectionStart.y);

    setSelectionRect({
      x: rectX, y: rectY, width: rectWidth, height: rectHeight,
    });
  };

  const onMouseUp = () => {
    // No real drag, handle as a simple click or no selection
    if (!selectionRect || (selectionRect.width <= 10 || selectionRect.height <= 10)) {
      dispatch(setSelectionComplete(false));
      setIsSelecting(false);
      setSelectionStart(null);
      setSelectionRect(null);

      return;
    }

    // if there was a real drag, complete the selection
    dispatch(setSelectionComplete(true));
    setIsSelecting(false);
    setSelectionStart(null);
  };

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    selectionRect,
    isSelecting,
  };
};

export const useGetSelectedObjectIDs = (
  svgContainerWidth: number,
  selectionRect: SelectionRect,
) => {
  const [selectedObjectIDs, setSelectedObjectIDs] = useState<string[]>([]);
  const { circlesAreaWidth, circlesAreaHeight } = calcCirclesAreaDimensions(svgContainerWidth);
  const ratingsResults: RatingResultByScoreType[] = useSelector(selectRatingsResultsByScoreType);

  useEffect(() => {
    if (!selectionRect) {
      setSelectedObjectIDs([]);
      return;
    }

    const newlySelected: string[] = [];

    ratingsResults.forEach((ratingItem) => {
      // Calculate cx, cy using CONTAINER_PADDING values, because CirclesArea has offset with that values.
      // By doing so, we adjust circle positions like for <g> in CirclesArea.
      const cx = (ratingItem.xPerc / 100) * circlesAreaWidth + CONTAINER_PADDING.left;
      const cy = (circlesAreaHeight - (ratingItem.yPerc / 100) * circlesAreaHeight) + CONTAINER_PADDING.top;

      if (
        cx >= selectionRect.x
        && cx <= (selectionRect.x + selectionRect.width)
        && cy >= selectionRect.y
        && cy <= (selectionRect.y + selectionRect.height)
      ) {
        newlySelected.push(ratingItem.id);
      }
    });

    setSelectedObjectIDs(newlySelected);
  }, [selectionRect, ratingsResults, circlesAreaWidth, circlesAreaHeight]);

  return selectedObjectIDs;
};