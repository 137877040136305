// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cloud-directory-item--RnT5V{display:flex;margin-left:10px}", "",{"version":3,"sources":["webpack://./src/components/App/Super/Cloud/CloudSuperset/CloudDirectory/CloudDirectory.module.sass"],"names":[],"mappings":"AAAA,6BACI,YAAA,CACA,gBAAA","sourcesContent":[".cloud-directory-item\n    display: flex\n    margin-left: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cloud-directory-item": "cloud-directory-item--RnT5V"
};
export default ___CSS_LOADER_EXPORT___;
