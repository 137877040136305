import React, { useCallback, useContext, useMemo } from 'react';
import styles from './BUnitActionButtons.module.sass';
import ActionButton from './ActionButton/ActionButton';
import { addNotification } from '@/store/reducers/notify';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectCurrentLangID } from '@/store/selectors/admin/superset/projects';
import { getProjectLink } from '@/helpers/helpers';
import { OPTION_BU_LINK, OPTION_PERSONAL_LINK } from '@/constants/admin';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import useFileDownload from '@/components/App/Admin/hooks/downloadFile';
import BUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import {
  ToggleIsInputType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/DepartmentUnit/DepartmentUnit';
import DeleteUnitActionButton
  from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitActionButtons/DeleteUnitActionButton/DeleteUnitActionButton';
import CreateNewUnitInDepartnentActionButton from './CreateNewUnitInDepartmentActionButton/CreateNewUnitInDepartnentActionButton';
import { IconType } from '../BUnitStatusIcons/BUnitStatusIcon/BUnitStatusIcon';

interface BUnitActionButtonsProps {
  isHidden: boolean;
  toggleInput?: ToggleIsInputType;
  classNames?: string;
}
function BUnitActionButtons({ classNames = styles['action-buttons-default'], isHidden, toggleInput }: BUnitActionButtonsProps) {
  const { unit, id } = useContext(BUnitContext) as Required<BUnitContextProps>;
  const checkPermission = useCheckPermission();
  const { webId } = useAdminSelector(selectSuperset);
  const dispatch = useAdminDispatch();
  const { isunit, secret } = unit;
  const currentLangID = useAdminSelector(selectCurrentLangID);
  const { supersetId } = useAdminSelector(selectSuperset);
  const files = useAdminSelector(
    (state) => state.admin.superset.supersetFields.files,
  );
  // @ts-ignore
  const processorFiles = useMemo(() => files?.processorFiles, [files]);
  const fileName = useMemo(
    () => `${currentLangID}_360_${isunit ? 'unit' : 'person'}_${id}.pdf`,
    [currentLangID, id, isunit],
  );

  const downloadFile = useFileDownload();

  const makePersonalLink = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let projectLink = getProjectLink(webId);
    projectLink += isunit === 0 ? `/a/${secret}` : `/u/${secret}`;
    if (event.altKey || event.metaKey) {
      navigator.clipboard.writeText(projectLink).then(() => dispatch(
        // @ts-ignore
        addNotification({
          type: 'success',
          title: 'Success',
          message: 'Link has copied to clipboard!',
        }),
      ));
      return;
    }
    // @ts-ignore
    window.open(projectLink, '_blank').focus();
  };

  const downloadPersonalReport = useCallback(() => {
    downloadFile({
      args: {
        supersetId,
        fileType: 'person',
        unitId: id,
        langId: currentLangID,
      },
    });
  }, [downloadFile, supersetId, currentLangID, id]);

  const isShowPersonalLink = useMemo(() => {
    if (!secret) return false;
    if (isunit && OPTION_BU_LINK) return true;
    return !!(!isunit && OPTION_PERSONAL_LINK);
  }, [isunit, secret]);

  const isReportAvailable = useMemo(
    () => processorFiles
      && processorFiles[fileName]
      && checkPermission('download_personal_report'),
    [checkPermission, fileName, processorFiles],
  );

  const renameClick = () => {
    if (toggleInput) toggleInput((prevState: boolean) => !prevState);
  };

  return (
    <div
      className={`${styles['unit-actions-list']} ${
        isHidden && styles.hidden
      } ${classNames}`}
    >
      <ActionButton
        buttonType={IconType.Download}
        className={styles['download-icon']}
        handleActionClick={() => downloadPersonalReport()}
        isHidden={!isReportAvailable}
      />
      <ActionButton
        handleActionClick={makePersonalLink}
        isHidden={!isShowPersonalLink}
        className={styles['icon-link']}
        buttonType={IconType.Link}
      />
      <DeleteUnitActionButton />
      {isunit === 1 && (
        <ActionButton
          buttonType={IconType.Edit}
          className={styles['unit-button-field']}
          handleActionClick={renameClick}
        />
      )}
      {isunit === 1 && (
        <CreateNewUnitInDepartnentActionButton />
      )}
    </div>
  );
}

export default BUnitActionButtons;