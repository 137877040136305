import React, { useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import BUnitContext, { BUnitContextProps } from '../../BUnitContext';
import styles from './BUnitStatusIcon.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface BUnitStatusIconProps {
  iconType: IconType;
  additionalClassNames?: string;
  isVisible: boolean;
  nlsKey: string;
}

export enum IconType {
  Download = 'pdf',
  Link = 'link',
  Edit = 'pencil',
  Delete = 'trash',
  AddUnit = 'plus',
  Assessed = 'assessed',
  Assesses = 'assesses',
}

export default function BUnitStatusIcon({
  iconType, additionalClassNames, isVisible, nlsKey,
}: BUnitStatusIconProps) {
  const tNLS = useNLS();
  const { id } = useContext(BUnitContext) as Required<BUnitContextProps>;

  return (
    <>
      {isVisible && (
      <Tooltip
        id={`${id}-${iconType}-icon`}
        place="top"
        content={tNLS(nlsKey)}
        className={styles.tooltip}
      />
      )}
      <span
        className={getClearClassNames([
          styles['status-icon'],
          `icon-${iconType}`,
          additionalClassNames,
        ])}
        data-tooltip-id={`${id}-${iconType}-icon`}
      />
    </>

  );
}