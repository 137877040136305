import React, {
  Dispatch, SetStateAction, useContext, useEffect, useMemo,
} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import styles from './BUnitRolesList.module.sass';
import { BUnitHowTo } from '@/store/types/admin/superset/unitsTree';
import BUnitAddHowTo
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/BUnitAddHowTo';
import BUnitCardFooterButtons
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFooter/BUnitCardFooterButtons';
import BUnitRolesGroup
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitRolesGroup/BUnitRolesGroup';
import useRolesList
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/rolesListHooks';
import SaveChangesModal
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/SaveChangesModal/SaveChangesModal';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { updateUnitRoles } from '@/store/actions/admin/superset/unitsTree';
import BUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import {
  HowToType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitCardHowToSection';
import UnitCardContext
, {
  BUnitCardContextProps,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/bUnitCardContext';
import BUnitNoneInfo from './BUnitNoneInfo/BUnitNoneInfo';

interface BUnitRolesListProps {
  roles: BUnitHowTo;
  type: HowToType;
  toggleUpdated: Dispatch<SetStateAction<boolean>>
}
function BUnitRolesList({ roles, type, toggleUpdated }: BUnitRolesListProps) {
  const { supersetId } = useAdminSelector(selectSuperset);
  const dispatch = useAdminDispatch();
  const { id } = useContext(BUnitContext) as Required<BUnitContextProps>;
  const { handleButtonClickInModal } = useContext(UnitCardContext) as Required<BUnitCardContextProps>;

  const {
    newRoles, deletedRoles, updatedRoles, ...rolesProps
  } = useRolesList();

  const rolesList = useMemo<BUnitHowTo>(
    () => ({ ...roles, ...updatedRoles }),
    [roles, updatedRoles],
  );

  const isUpdated = useMemo(() => {
    const isRolesUpdated = Object.keys(newRoles).length + Object.keys(deletedRoles).length + Object.keys(updatedRoles).length;
    return !!isRolesUpdated;
  }, [deletedRoles, newRoles, updatedRoles]);

  useEffect(() => {
    toggleUpdated(isUpdated);
  }, [isUpdated, toggleUpdated]);

  const props = {
    type,
    newRoles,
    deletedRoles,
    updatedRoles,
    ...rolesProps,
  };

  const handleReset = () => {
    rolesProps.resetUpdates();
    toggleUpdated(false);
    handleButtonClickInModal();
  };

  const saveUpdates = () => {
    const newUnitRoles = { ...updatedRoles, ...deletedRoles, ...newRoles };

    dispatch(updateUnitRoles({
      supersetId,
      unitId: id,
      unitData: newUnitRoles,
      type: type === HowToType.Objects ? 'estimator' : 'estimated',
    }))
      .then(unwrapResult)
      .then(handleReset)
      .catch(() => {});
    handleButtonClickInModal();
  };

  const buttonProps = {
    confirmClick: saveUpdates,
    isUnitUpdated: isUpdated,
    reset: handleReset,
  };

  return (
    <>
      <SaveChangesModal>
        <BUnitCardFooterButtons
          {...buttonProps}
        />
      </SaveChangesModal>
      <ul className={styles['how-to-card-list']}>
        <BUnitAddHowTo
          addNewRole={rolesProps.addRoles}
          type={type}
        />
        <BUnitRolesGroup
          rolesList={rolesList}
          {...props}
        />
        {!!Object.keys(newRoles).length && <div className={styles.separator} />}
        <BUnitRolesGroup
          rolesList={newRoles}
          isNew
          {...props}
        />
        <BUnitNoneInfo roles={roles} />
        <BUnitCardFooterButtons
          {...buttonProps}
        />
      </ul>
    </>
  );
}

export default BUnitRolesList;