// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".name--X2Lx5{text-align:right}.name--X2Lx5.soft--rGATX{color:var(--secondary-color)}.name--X2Lx5.deleted--GxMJV{text-decoration:line-through}.items-count--5CHY0{text-align:right}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitHowToElement/BUnitHowToElement.module.sass"],"names":[],"mappings":"AAEA,aACE,gBAAA,CACA,yBACE,4BAAA,CACF,4BACE,4BAAA,CAEJ,oBACE,gBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.name\n  text-align: right\n  &.soft\n    color: var(--secondary-color)\n  &.deleted\n    text-decoration: line-through\n\n.items-count\n  text-align: right\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "name--X2Lx5",
	"soft": "soft--rGATX",
	"deleted": "deleted--GxMJV",
	"items-count": "items-count--5CHY0"
};
export default ___CSS_LOADER_EXPORT___;
