// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-explanation-text--JIhQV{display:flex;align-items:center;justify-content:center;gap:0.625rem}.wrap-explanation-text--JIhQV .icon-tooltip--RZ19f{line-height:10px}.wrap-explanation-text--JIhQV .icon-tooltip--RZ19f span:before{font-size:1rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/CutsChart/Answers/CheckExplanation/CheckExplanation.module.sass"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,mDACE,gBAAA,CAEE,+DACE,cAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrap-explanation-text\n  display: flex\n  align-items: center\n  justify-content: center\n  gap: func.rem(10)\n  .icon-tooltip\n    line-height: 10px\n    span\n      &:before\n        font-size: func.rem(16)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-explanation-text": "wrap-explanation-text--JIhQV",
	"icon-tooltip": "icon-tooltip--RZ19f"
};
export default ___CSS_LOADER_EXPORT___;
