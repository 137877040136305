// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".webinar-date--tyjwI{width:fit-content}.label-check--hw95-{flex-direction:row !important}.label-check--hw95-{display:flex;gap:0.75rem}.label-check--hw95- .checkbox--dE8xi{border:0;padding:0}.text-area--0E82G{padding:0;border:0;border-radius:0}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/CreateWebinarPage/CreateWebinarForm/CreateWebinarFormField/CreateWebinarFormField.module.sass"],"names":[],"mappings":"AAEA,qBACE,iBAAA,CACF,oBAEE,6BACA,CAHF,oBACE,YAAA,CAEA,WAAA,CACA,qCACE,QAAA,CACA,SAAA,CACJ,kBACE,SAAA,CACA,QAAA,CACA,eAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.webinar-date\n  width: fit-content\n.label-check\n  display: flex\n  flex-direction: row !important\n  gap: func.rem(12)\n  .checkbox\n    border: 0\n    padding: 0\n.text-area\n  padding: 0\n  border: 0\n  border-radius: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"webinar-date": "webinar-date--tyjwI",
	"label-check": "label-check--hw95-",
	"checkbox": "checkbox--dE8xi",
	"text-area": "text-area--0E82G"
};
export default ___CSS_LOADER_EXPORT___;
