import React from 'react';
import { BUnitHowTo } from '@/store/types/admin/superset/unitsTree';
import BUnitNoneValue, { NoneKeys } from './BUnitNoneIndex/BUnitNoneValue';

interface BUnitNoneInfoProps {
  roles: BUnitHowTo;
}

export default function BUnitNoneInfo({ roles }: BUnitNoneInfoProps) {
  const { none } = roles;

  if (!none) return null;

  return (
    <>
      <BUnitNoneValue valueKey={NoneKeys.Min} value={none[NoneKeys.Min]} />
      <BUnitNoneValue valueKey={NoneKeys.Max} value={none[NoneKeys.Max]} />
      <BUnitNoneValue valueKey={NoneKeys.Count} value={none[NoneKeys.Count]} />
    </>
  );
}