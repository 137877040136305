import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosProcessing from '@/axiosConfig';
import {
  BackendUnit, CreateBUnitActionArgs, DeleteUnitActionArgs, GetRelatedUnitsActionArgs,
  GetSupersetUnitsActionArgs, MakeUnitsGroupOperationsActionArgs,
  MakeUnitsTreeActionArgs, SelectTreeUnitsNewArgs, UpdateTreeUnitsActionArgs, UpdateUnitRolesActionArgsArgs,
} from '@/store/types/admin/superset/unitsTree';
import { AdminState } from '@/store/admin';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { createPathByParentId } from '@/helpers/helpers';
import { UnitId } from '@/store/types/user/units';
import { UnitsSelectActionFlags } from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectAction';

export const getSupersetUnits = createAsyncThunk(
  'superset/unitsTree/getSupersetUnits',
  async ({ supersetId, flag }: GetSupersetUnitsActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get<BackendUnit[]>(
        `/api/admin/getNuklonUnits/${supersetId}/${flag}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const makeUnitsTree = createAction<MakeUnitsTreeActionArgs>('superset/unitsTree/makeUnitsTree');

export const getRelatedUnits = createAsyncThunk(
  'superset/unitsTree/getRelatedUnits',
  async ({ supersetId, unitId }: GetRelatedUnitsActionArgs, { rejectWithValue }) => {
    try {
      const response = await axiosProcessing.get(
        `/api/admin/getHowtoInfo/${supersetId}/${unitId}`,
      );
      return { ...response.data, unitId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateTreeUnits = createAsyncThunk(
  'superset/unitsTree/updateTreeUnits',
  async ({ supersetId, unitsData }: UpdateTreeUnitsActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;

      const { units } = state.admin.superset.unitsTree;

      const newUnitsData = unitsData.map((data) => {
        const { newParentId, ...unitData } = data;
        if (!newParentId) return data;

        return { ...unitData, departments: createPathByParentId(newParentId, units) };
      });

      await axiosProcessing.put(
        `/api/admin/updateNuklonUnits/${supersetId}`,
        newUnitsData,
        {
          headers,
        },
      );

      return unitsData;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const selectTreeUnits = createAsyncThunk(
  'superset/unitsTree/selectTreeUnitsNew',
  async (data: SelectTreeUnitsNewArgs, { getState, rejectWithValue }) => {
    try {
      const {
        selectValue, flag = '', unitsId = [], value = '',
      } = data;
      const state = getState() as AdminState;
      const { supersetId } = selectSuperset(state);
      const { headers } = state.admin.authentication;

      const apiUrl = `/api/admin/superset/${supersetId}/units/${selectValue}`;

      const responseData = {
        units: unitsId,
        param: flag,
        value,
      };

      const response = await axiosProcessing.post<Record<UnitId, boolean>>(apiUrl, responseData, { headers });

      return { units: response.data, isSelect: selectValue === UnitsSelectActionFlags.Select };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const makeUnitsGroupOperations = createAsyncThunk(
  'superset/makeUnitsGroupOperation',
  async (data: MakeUnitsGroupOperationsActionArgs, { getState, rejectWithValue }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      const response = await axiosProcessing.put(
        '/api/admin/groupOperation',
        data,
        {
          headers,
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createBUnit = createAsyncThunk(
  'superset/unitsTree/createBUnit',
  async (data: CreateBUnitActionArgs, { getState, rejectWithValue }) => {
    try {
      const { unitData, isLocal, targetDepartmentId } = data;
      const state = getState() as AdminState;
      const { supersetId } = selectSuperset(state);
      if (!isLocal) {
        const { headers } = state.admin.authentication;
        const response = await axiosProcessing.post<BackendUnit>(
          '/api/admin/createUnit',
          { ...unitData, supersetId },
          {
            headers,
          },
        );
        return { ...response.data, ...unitData, targetDepartmentId };
      }
      const order = [...state.admin.superset.unitsTree.order];
      const newId = `${Math.min(...order.map((id) => Number(id))) - 1}`;
      return { id: newId, ...unitData, targetDepartmentId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUnit = createAsyncThunk(
  'superset/unitsTree/deleteUnit',
  async ({ unitId }: DeleteUnitActionArgs, { rejectWithValue, getState }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      await axiosProcessing.delete('/api/admin/deleteUnit', {
        headers,
        data: { unitId },
      });

      return { unitId };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUnitRoles = createAsyncThunk(
  'superset/unitsTree/updateRoles',
  async (data: UpdateUnitRolesActionArgsArgs, { rejectWithValue, getState }) => {
    try {
      const state = getState() as AdminState;
      const { headers } = state.admin.authentication;
      const {
        unitData, supersetId, unitId, type,
      } = data;

      const form = {
        [type]: Object.keys(unitData).reduce((acc, id) => ({ ...acc, [id]: unitData[id].role }), {}),
      };

      await axiosProcessing.put(
        `/api/admin/updateNuklonUnits/${supersetId}`,
        [{
          ID: unitId,
          roles: form,
        }],
        { headers },
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);