import React, {
  ChangeEvent, Dispatch, SetStateAction, useCallback, useContext, useMemo, useState,
} from 'react';
import styles from './BUnitAddHowToInput.module.sass';
import BUnitAddHowToSearchDropdown
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/BUnitAddHowToInput/BUnitHowToSearchDropdown/BUnitAddHowToSearchDropdown';
import useOutsideClick from '@/hooks/checkOutsideClick';
import { TreeUnit } from '@/store/types/admin/superset/unitsTree';
import {
  HowToType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitCardHowToSection';
import { isOneStringEnterAnother } from '@/helpers/helpers';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import BUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import useDebounceSearch
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/BUnitAddHowToInput/BUnitHowToSearchDropdown/useDebounceSearch';

interface BUnitAddHowToInputProps {
  selectUnit: Dispatch<SetStateAction<string>>;
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>
  type: HowToType;
  slectedUnitId: string;
}

function BUnitAddHowToInput({
  inputValue, setInputValue, selectUnit, type, slectedUnitId,
}: BUnitAddHowToInputProps) {
  const [isOpen, toggleDropdown] = useState<boolean>(false);
  const { units } = useAdminSelector(selectUnitsTreeData);
  const { id: unitId, unit } = useContext(BUnitContext) as Required<BUnitContextProps>;

  const closeDropdown = useCallback(() => {
    toggleDropdown(false);
    setInputValue('');
  }, [setInputValue]);

  const containerRef = useOutsideClick<() => void, HTMLDivElement>(closeDropdown, isOpen);

  const preFilteredListValues = useMemo(() => Object.entries(units).filter(([id]) => !(+id < 0 || id === unitId || unit[type]?.id)), [type, unit, unitId, units]);

  const filterListFunc = useCallback(([id]: [string, TreeUnit], filterValue: string) => {
    const { name, email } = units[id];
    return (
      isOneStringEnterAnother(name, filterValue)
        || isOneStringEnterAnother(email, filterValue)
    );
  }, [units]);

  const { filteredList, filterValues } = useDebounceSearch<[string, TreeUnit]>({
    // @ts-ignore
    elementsList: preFilteredListValues,
    filterCallback: filterListFunc,
    minLenForFilter: 3,
    debounce: 350,
  });

  const inputHandleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    filterValues(e.target.value);
    if (!isOpen) toggleDropdown(true);
  }, [filterValues, isOpen, setInputValue]);

  const handleSelectUnit = useCallback((id: string) => {
    selectUnit(id);
    toggleDropdown(false);
    setInputValue('');
  }, [selectUnit, setInputValue]);

  return (
    <div
      ref={containerRef}
      className={styles['dropdown-container']}
    >
      <input
        className={styles['search-input']}
        value={inputValue}
        placeholder={units[slectedUnitId]?.name || ''}
        onChange={inputHandleChange}
        onClick={() => toggleDropdown(true)}
      />
      <button
        type="button"
        onClick={() => setInputValue('')}
      >
        {' '}
        <span className="icon-remove-light" />
      </button>
      <BUnitAddHowToSearchDropdown
        isOpen={isOpen && inputValue.length >= 3}
        selectUnit={handleSelectUnit}
        filteredList={filteredList}
      />
    </div>
  );
}

export default BUnitAddHowToInput;