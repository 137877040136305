// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-create-project-success--Po7is{display:flex;align-items:center;gap:20px;flex-direction:column}.wrap-create-project-success--Po7is .icon-success-noty--aAbft:before{font-size:3.75rem;color:#219653}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/SuccessMessage/SuccessMessage.module.sass"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,qBAAA,CAEE,qEACE,iBAAA,CACA,aAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.wrap-create-project-success\n  display: flex\n  align-items: center\n  gap: 20px\n  flex-direction: column\n  .icon-success-noty\n    &:before\n      font-size: func.rem(60)\n      color: #219653"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-create-project-success": "wrap-create-project-success--Po7is",
	"icon-success-noty": "icon-success-noty--aAbft"
};
export default ___CSS_LOADER_EXPORT___;
