import React from 'react';
import styles from './AddNewHowToButton.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

interface AddNewHowToButtonProps {
  isDisabled: boolean;
  addNewRole: () => void
}

function AddNewHowToButton({ isDisabled, addNewRole }: AddNewHowToButtonProps) {
  return (
    <button
      type="button"
      className={getClearClassNames([
        styles['add-button'],
        isDisabled && styles.disabled,
      ])}
      onClick={() => !isDisabled && addNewRole()}
    >
      +
    </button>
  );
}

export default AddNewHowToButton;