// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".article-modal--CGdlT{width:90%;height:100%}.article-modal--CGdlT .article-modal-container--zPQRb{display:flex;align-content:space-around;align-items:flex-start;justify-content:center}", "",{"version":3,"sources":["webpack://./src/components/App/Super/Articles/ArticleEditor/ArticleEditor.module.sass"],"names":[],"mappings":"AAAA,sBACI,SAAA,CACA,WAAA,CACA,sDACI,YAAA,CACA,0BAAA,CACA,sBAAA,CACA,sBAAA","sourcesContent":[".article-modal\n    width: 90%\n    height: 100%\n    .article-modal-container\n        display: flex\n        align-content: space-around\n        align-items: flex-start\n        justify-content: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"article-modal": "article-modal--CGdlT",
	"article-modal-container": "article-modal-container--zPQRb"
};
export default ___CSS_LOADER_EXPORT___;
