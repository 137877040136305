import React from 'react';
import AdminMainListLink from '@/components/App/Admin/AdminMainListLinkLink';
import WebinarListMetaInfo from './WebinarListMetaInfo/WebinarListMetaInfo';
import { WebinarType } from '@/store/types/admin/webinars';
import LinkDates from '@/components/App/Admin/AdminMainListLinkLink/types';

interface WebinarListLinkProps {
  webinar: WebinarType;
}

export default function WebinarListLink({ webinar }: WebinarListLinkProps) {
  const { createdAt, startTime } = webinar;

  const dates: LinkDates = [
    ['Дата создания:', createdAt],
    ['Дата начала:', startTime],
  ];

  return (
    <AdminMainListLink
      to={`${webinar.webinarKey}`}
      linkTitle={webinar.title}
      dates={dates}
      metaInfo={<WebinarListMetaInfo webinar={webinar} />}
    />
  );
}