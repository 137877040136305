// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mask--qlU2O{position:relative;min-height:30px;top:24px;background-color:hsla(0,0%,100%,0);border-radius:30px 30px 0 0;border-left:10px solid var(--main-bg);border-right:10px solid var(--main-bg);border-top:10px solid var(--main-bg);margin:0 auto;max-width:88.75rem;width:calc(95% + 20px);z-index:3}@media screen and (max-width: 768px){.mask--qlU2O{display:none}}.progress--MFLvW{position:sticky;opacity:1;z-index:1;top:calc(var(--header-height) - 4px)}@media screen and (max-width: 768px){.progress--MFLvW{top:0}}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/ProgressContainer/ProgressContainer.module.sass"],"names":[],"mappings":"AAEA,aACE,iBAAA,CACA,eAAA,CACA,QAAA,CACA,kCAAA,CAEA,2BAAA,CACA,qCAAA,CACA,sCAAA,CACA,oCAAA,CACA,aAAA,CACA,kBAAA,CAGA,sBAAA,CACA,SAAA,CACA,qCAhBF,aAiBI,YAAA,CAAA,CACJ,iBACE,eAAA,CACA,SAAA,CACA,SAAA,CACA,oCAAA,CACA,qCALF,iBAMI,KAAA,CAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.mask\n  position: relative\n  min-height: 30px\n  top: 24px\n  background-color: rgba(255, 255, 255, 0)\n  //opacity: 0.5\n  border-radius: 30px 30px 0 0\n  border-left: 10px solid var(--main-bg)\n  border-right: 10px solid var(--main-bg)\n  border-top: 10px solid var(--main-bg)\n  margin: 0 auto\n  max-width: func.rem(1420)\n  //border-right: 2px solid green\n  //border-left: 2px solid green\n  width: calc(95% + 20px)\n  z-index: 3\n  @media screen and (max-width: 768px)\n    display: none\n.progress\n  position: sticky\n  opacity: 1\n  z-index: 1\n  top: calc(var(--header-height) - 4px)\n  @media screen and (max-width: 768px)\n    top: 0\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mask": "mask--qlU2O",
	"progress": "progress--MFLvW"
};
export default ___CSS_LOADER_EXPORT___;
