// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-unit-button--f4rx1{position:absolute;z-index:99;right:0;top:0}.create-unit-button--f4rx1.floating--fzSDw{position:fixed}.create-unit-button--f4rx1 .open-modal-button--a5qo9{margin:0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitSection.module.sass"],"names":[],"mappings":"AAEA,2BACE,iBAAA,CACA,UAAA,CACA,OAAA,CACA,KAAA,CACA,2CACE,cAAA,CACF,qDACE,QAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.create-unit-button\n  position: absolute\n  z-index: 99\n  right: 0\n  top: 0\n  &.floating\n    position: fixed\n  .open-modal-button\n    margin: 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create-unit-button": "create-unit-button--f4rx1",
	"floating": "floating--fzSDw",
	"open-modal-button": "open-modal-button--a5qo9"
};
export default ___CSS_LOADER_EXPORT___;
