// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebar--2W6YS{position:fixed;top:var(--header-height);left:20px;display:flex;flex-direction:column;gap:0.25rem;padding:1rem 2rem;height:fit-content;width:fit-content;min-width:12.5rem;background:var(--main-bg);z-index:99999}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Sidebar/SidebarContainer/SidebarContainer.module.sass"],"names":[],"mappings":"AAEA,gBACE,cAAA,CACA,wBAAA,CACA,SAAA,CACA,YAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CACA,yBAAA,CACA,aAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.sidebar\n  position: fixed\n  top: var(--header-height)\n  left: 20px\n  display: flex\n  flex-direction: column\n  gap: func.rem(4)\n  padding: func.rem(16) func.rem(32)\n  height: fit-content\n  width: fit-content\n  min-width: func.rem(200)\n  background: var(--main-bg)\n  z-index: 99999\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "sidebar--2W6YS"
};
export default ___CSS_LOADER_EXPORT___;
