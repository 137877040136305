import React, { useEffect } from 'react';
import { useAdminDispatch } from '@/store/hooks/adminRedux';
import SupersetsListPage from '../../SupersetsListPage/SupersetsListPage';
import { getSuperSupersetsList } from '@/store/actions/super/supersets';
import SuperAdminSupersetsList from '../SuperAdminSupersetsList';

export default function SuperAdminSupersets() {
  const dispatch = useAdminDispatch();

  useEffect(() => {
    dispatch(getSuperSupersetsList());
    // eslint-disable-next-line
  }, []);

  return (
    <SupersetsListPage>
      {SuperAdminSupersetsList}
    </SupersetsListPage>
  );
}