import { useCallback } from 'react';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { addNotification } from '@/store/reducers/notify';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { parceMacros } from '@/helpers/helpers';

export enum GroupOperationMessage {
  transferUnits = 'tree.group.done.move',
  closeAccess = 'tree.group.done.access.closed',
  openAccess = 'tree.group.done.access.opened',
  closeOldLinks = 'tree.group.done.links.closed',
  returnToFirst = 'tree.group.done.returned.to.start',
  deactivateProtocols = 'tree.group.done.protocols.deactivated',
  activateProtocols = 'tree.group.done.protocols.activated',
  deleteProtocols = 'tree.group.done.protocols.deleted',
  activateAssessedProtocols = 'tree.group.done.inbound.protocols.activated',
  deactivateAssessedProtocols = 'tree.group.done.inbound.protocols.deactivated',
  sendEmail = 'tree.group.done.invitations.sent',
}

// TODO доделать типы

const useGroupOperationNotify = () => {
  const dispatch = useAdminDispatch();
  const tNLS = useNLS();
  const { units } = useAdminSelector(selectUnitsTreeData);

  const parseMessageMacro = useCallback((operationType: string, updatedUnitsCount: number, selectedUnitsCount: number): string | undefined => {
    // @ts-ignore
    const messageKey: string = GroupOperationMessage[operationType];
    const text = tNLS(messageKey) || undefined;

    if (!text) return undefined;

    const pattern = /\{(\w+)\}/g;

    const macroses = {
      N: updatedUnitsCount,
      T: selectedUnitsCount,
    };

    return parceMacros(text, pattern, macroses);
  }, [tNLS]);

  const getNotifyType = useCallback((defaultType: 'success' | 'error' | 'warning', updatedCount: number, selectedCount: number) => {
    switch (defaultType) {
      case 'error':
        return defaultType;
      case 'success':
        if (updatedCount === 0) return 'error';
        if (selectedCount > updatedCount) return 'warning';
        return defaultType;
      case 'warning':
        if (updatedCount === 0) return 'error';
        return 'warning';
      default:
        return defaultType;
    }
  }, []);

  const callNotify = useCallback((operationType: string, updatedUnitsCount: number, notifyType: 'success' | 'error' = 'success') => {
    const selectedCount = Object.values(units).filter(({ selected }) => !!selected).length;
    // @ts-ignore
    dispatch(addNotification({
      type: getNotifyType(notifyType, updatedUnitsCount, selectedCount),
      message: parseMessageMacro(operationType, updatedUnitsCount, selectedCount) || '',
      lifeTime: 30,
    }));
  }, [dispatch, getNotifyType, parseMessageMacro, units]);

  return callNotify;
};

export default useGroupOperationNotify;