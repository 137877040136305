import React from 'react';
import styles from './CustomSelectOptionLabel.module.sass';
import { getClearClassNames } from '@/helpers/helpers';

interface CustomSelectOptionLabelProps extends React.HTMLAttributes<HTMLDivElement> {

}

export default function CustomSelectOptionLabel({ ...props }: CustomSelectOptionLabelProps) {
  const { className, children, ...otherProps } = props;
  return (
    <div
      className={getClearClassNames([
        className,
        styles['custom-select-label'],
      ])}
      {...otherProps}
    >
      {children}
    </div>
  );
}