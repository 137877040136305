// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-header--qb1gw{display:flex;align-items:center;gap:0.9375rem}.content-header--qb1gw h1{color:var(--title-font)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/CreateSupersetHeader/CreateSupersetHeader.module.sass"],"names":[],"mappings":"AAEA,uBACE,YAAA,CACA,kBAAA,CACA,aAAA,CACA,0BACE,uBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.content-header\n  display: flex\n  align-items: center\n  gap: func.rem(15)\n  h1\n    color: var(--title-font)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-header": "content-header--qb1gw"
};
export default ___CSS_LOADER_EXPORT___;
