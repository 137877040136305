// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-form-container--zRh-o{display:flex;flex-direction:column;gap:2.5rem;justify-content:space-between;width:95%;margin:0 auto}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/CreateWebinarPage/CreateWebinarPage.module.sass"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,6BAAA,CACA,SAAA,CACA,aAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.create-form-container\n  display: flex\n  flex-direction: column\n  gap: func.rem(40)\n  justify-content: space-between\n  width: 95%\n  margin: 0 auto\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create-form-container": "create-form-container--zRh-o"
};
export default ___CSS_LOADER_EXPORT___;
