import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import parse from 'html-react-parser';
import styles from './ObjectInfoWindow.module.sass';
import { ParamName, Rating, SelectedObject } from '@/store/types/admin/superset/cuts/fourD';
import { AdminState } from '@/store/admin';
import { selectSelectedObjects, selectSelectedParams } from '@/store/selectors/admin/superset/process/cuts/fourD';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { MAP_SOURS_NUMBER_TO_SOURCE_NLS } from '@/components/App/Admin/Results/Cuts/FourD/constants';
import useClickOutsideAndEsc from '@/components/App/Admin/Results/Cuts/FourD/Diagram/ObjectInfoWindow/hooks';

interface DataObjectInfoWindow {
  selectedObject: SelectedObject,
  svgContainerRef: React.RefObject<SVGSVGElement>,
}

function ObjectInfoWindow({ svgContainerRef, selectedObject }: DataObjectInfoWindow) {
  const [selectedObjectData] = useSelector((state: AdminState) => (
    selectSelectedObjects(state, [selectedObject.id])
  ));

  const wrapperWindowRef = useRef<HTMLDivElement>(null);
  useClickOutsideAndEsc(wrapperWindowRef);

  // TODO . Эти селекторы и функции дублируется в компоненте ListSelectedItems. Вынести в отдельный hook ?
  const {
    xRating, yRating, ratingSize, ratingColor,
  } = useSelector(selectSelectedParams);
  const tNLS = useNLS();

  const getSourceName = (source: number) => (
    tNLS(MAP_SOURS_NUMBER_TO_SOURCE_NLS[source].header)
  );

  const truncateDecimal = (number: number) => {
    const truncated = Math.floor(number * 10) / 10;
    const twoDecimals = truncated.toFixed(2);

    return parseFloat(twoDecimals).toString();
  };

  const containerRect = svgContainerRef.current.getBoundingClientRect();

  // Circle center in container coords
  const circleCenterX = selectedObject.circleCenterX - containerRect.left;
  const circleCenterY = selectedObject.circleCenterY - containerRect.top;

  // Decide where the window itself goes, e.g. offset by +20
  const windowLeft = circleCenterX + 20;
  const windowTop = circleCenterY - 9;

  const getRatingItemTemplate = (
    rating: Rating,
    orgScore: number,
    perc: number,
    paramName: ParamName,
  ) => (
    <li className={styles['rating-item']}>

      <div className={styles['wrap-param-info']}>

        <div
          className={styles['rating-source']}
          data-tooltip-id={`tooltip-rating-source--${paramName}`}
        >
          <Tooltip id={`tooltip-rating-source--${paramName}`}>
            {parse(getSourceName(rating.source))}
          </Tooltip>

          <span
            className={`${styles['icon-rating-source']} icon-outgoing`}
          />

        </div>

        <span className={styles['rating-name']}>
          {rating.name}
        </span>

      </div>

      {orgScore ? (
        <div className={styles['wrap-values']}>
          <span className={styles['org-score']}>
            {truncateDecimal(orgScore)}
          </span>
          <span className={styles.perc}>
            {`${truncateDecimal(perc)}%`}
          </span>
        </div>
      ) : (
        <div className={styles['wrap-values']}>—</div>
      )}

    </li>
  );

  const getRatingItemColorTemplate = (
    rating: Rating,
    color: string,
    paramName: ParamName,
  ) => (
    <li className={`${styles['rating-item']} ${styles['rating-item--color']}`}>

      <div className={styles['wrap-param-info']}>

        <div
          className={styles['rating-source']}
          data-tooltip-id={`tooltip-rating-source--${paramName}`}
        >
          <Tooltip id={`tooltip-rating-source--${paramName}`}>
            {parse(getSourceName(rating.source))}
          </Tooltip>

          <span
            className={`${styles['icon-rating-source']} icon-outgoing`}
          />

        </div>

        <span className={styles['rating-name']}>
          {rating.name}
        </span>

      </div>

      <div className={styles['wrap-values']}>
        <div className={styles['color-value']} style={{ backgroundColor: color }} />
      </div>

    </li>
  );

  return (
    <div
      className={styles['wrap-object-info-window']}
      style={{
        position: 'absolute',
        left: windowLeft,
        top: windowTop,
      }}
      ref={wrapperWindowRef}
    >
      <div className={styles.pointer} />
      <div className={styles['wrap-object-content']}>
        <div className={styles['object-name']}>{selectedObjectData.name}</div>

        <ul className={styles['wrap-ratings']}>
          {getRatingItemTemplate(
            xRating,
            selectedObjectData.orgScore.x,
            selectedObjectData.xPerc,
            'xRating',
          )}

          {getRatingItemTemplate(
            yRating,
            selectedObjectData.orgScore.y,
            selectedObjectData.yPerc,
            'yRating',
          )}

          {getRatingItemTemplate(
            ratingSize,
            selectedObjectData.orgScore.size,
            selectedObjectData.size,
            'ratingSize',
          )}

          {ratingColor && getRatingItemColorTemplate(
            ratingColor,
            selectedObjectData.color,
            'ratingColor',
          )}
        </ul>
      </div>
    </div>
  );
}

export default ObjectInfoWindow;