import React from 'react';
import { FilterSupersetsFlag } from './FilterSupersets/FilterSupersetsTabs';
import FilteredSupersetsList from './FilteredSupersetsList/FilteredSupersetsList';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import LoadingStatus from '@/constants/enums';
import Preloader from '../SupersetSettings/Preloader';

interface SuperAdminSupersetsListProps {
  filterFlag: FilterSupersetsFlag;
}

export default function SuperAdminSupersetsList({ filterFlag }: SuperAdminSupersetsListProps) {
  const { supersetsList, loadStatus } = useAdminSelector((state) => state.super.supersets);

  if (loadStatus !== LoadingStatus.Received) return <Preloader />;

  return (
    <FilteredSupersetsList
      filterFlag={filterFlag}
      supersets={supersetsList}
    />
  );
}