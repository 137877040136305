import { AdminState } from '@/store/admin';
import {
  Rating, RatingResult, ScoreType, SelectedParams,
} from '@/store/types/admin/superset/cuts/fourD';

const getStoreContext = (state: AdminState) => state.admin.superset.process.cuts.fourD;

export const selectHasFourD = (state: AdminState) => (
  !!Object.keys(getStoreContext(state).ratings)
);

export const selectScoreType = (state: AdminState): ScoreType => (
  getStoreContext(state).scoreType
);

export const selectRatings = (state: AdminState) => getStoreContext(state).ratings;

export const selectRatingsWithColors = (state: AdminState) => (
  selectRatings(state).filter(({ colors }: { colors: string[] | null }) => colors)
);

// by scoreType
export const selectRatingsResultsByScoreType = (state: AdminState) => {
  const { ratingsResults } = getStoreContext(state);
  const scoreType = selectScoreType(state);

  return ratingsResults.map((item: RatingResult) => {
    const { xPerc, yPerc, sizePerc } = item.scores[scoreType];

    return {
      id: item.id,
      name: item.name,
      xPerc,
      yPerc,
      size: sizePerc,
      orgScore: item.orgScore,
      color: item.color,
    };
  });
};

export const selectIsThereDataForDiagram = (state: AdminState) => !!getStoreContext(state).ratingsResults;

export const selectSelectedParamIDs = (state: AdminState) => getStoreContext(state).selectedParamIDs;

export const selectSelectedParams = (state: AdminState) => {
  const selectedParamIDs = selectSelectedParamIDs(state);
  const ratings = selectRatings(state);
  const selectedRatingIDToRating: Partial<SelectedParams> = {};

  Object.entries(selectedParamIDs)
    .forEach(([paramName, ratingID]) => {
      selectedRatingIDToRating[paramName as keyof SelectedParams] = ratings.find((rating: Rating) => rating.id === ratingID);
    });

  return selectedRatingIDToRating;
};

export const selectIsSelectionCompleteStatus = (state: AdminState) => (
  getStoreContext(state).isSelectionComplete
);

export const selectSelectedObjects = (state: AdminState, objectIDs: string[]) => {
  const objectsResults = selectRatingsResultsByScoreType(state);

  return objectIDs.map((objectID: string) => (
    objectsResults.find(
      ({ id: objectResultID }: { id: string }) => objectResultID === objectID,
    )
  ));
};

export const selectTargetedObjectID = (state: AdminState) => (
  getStoreContext(state).targetedObjectID
);

export const selectSelectedObject = (state: AdminState) => (
  getStoreContext(state).selectedObject
);