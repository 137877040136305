import React, { Dispatch, SetStateAction } from 'react';
import RadioButton from '@/components/Common/form/RadioButton';
import styles from './SelectCheckpoint.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface SelectCheckpointProps {
  selectedCheckpointId: string;
  setSelectedCheckpointId: Dispatch<SetStateAction<string>>;
  checkpoints: { id: string, title: string, description: string }[];
}

export default function SelectCheckpoint({ selectedCheckpointId, setSelectedCheckpointId, checkpoints }: SelectCheckpointProps) {
  const tNLS = useNLS();
  const getLabelCheckpoint = (title: string, description: string) => (
    <div className={styles['wrap-option-label']}>
      {title && <span>{title}</span>}
      {description && <span className={styles.description}>{description}</span>}
    </div>
  );

  if (!checkpoints || !checkpoints.length) return null;

  return (
    <section>
      {checkpoints && (
      /* @ts-ignore  */
      <RadioButton
        name="Without checkpoint"
        id="default-checkpoint"
        // @ts-ignore
        label={getLabelCheckpoint(tNLS('results.calculate.no.checkpoint'))}
        externalClassNames={{}}
        onChange={() => setSelectedCheckpointId('')}
        checked={selectedCheckpointId === ''}
      />
      )}
      {/* @ts-ignore */}
      {checkpoints.map(({ id, title, description }) => (
        // @ts-ignore
        <RadioButton
          name={title}
          key={id}
          id={id}
          label={getLabelCheckpoint(title, description)}
          externalClassNames={{}}
          checked={id === selectedCheckpointId}
          onChange={() => setSelectedCheckpointId(id)}
        />
      ))}
    </section>
  );
}