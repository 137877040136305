import React, { useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './BUnitHowToElement.module.sass';
import { UnitId } from '@/store/types/user/units';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import BUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import RoleSelect
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitHowToElement/RoleSelect/RoleSelect';
import { getClearClassNames } from '@/helpers/helpers';
import DeleteRestoreButton
  from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitHowToElement/DeleteRestoreButton/DeleteRestoreButton';
import { RolesType } from '@/store/types/admin/superset/unitsTree';
import {
  DeleteRoleFunc, RestoreRoleFunc,
  UpdateRoleFunc,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/rolesListHooks';
import useNLS from '@/components/App/Admin/hooks/hooks';

interface BUnitHowToElementProps {
  id: UnitId;
  role: string;
  type: RolesType
  updateRole: UpdateRoleFunc;
  deleteRole: DeleteRoleFunc
  restoreRole: RestoreRoleFunc;
  isDeleted?: boolean;
  isNew?: boolean;
  isUpdated?: boolean;
  items: string[]
}

function BUnitHowToElement({
  id, role, type, updateRole, deleteRole, restoreRole, isDeleted = false, isNew = false, isUpdated = false, items,
}: BUnitHowToElementProps) {
  const tNLS = useNLS();
  const { units } = useAdminSelector(selectUnitsTreeData);
  const { unit, id: unitId } = useContext(BUnitContext) as Required<BUnitContextProps>;

  const handleChangeRole = (newRole: string) => {
    updateRole(id, newRole, type, isNew);
  };

  return (
    <>
      <Tooltip
        id={`${id}-${unitId}-role-tooltip`}
        content={tNLS('tree.card.roles.nitems')}
      />
      <div
        className={styles['items-count']}
        data-tooltip-id={`${id}-${unitId}-role-tooltip`}
      >
        {items?.length || 0}
      </div>
      <div
        className={getClearClassNames([
          styles.name,
          isDeleted && styles.deleted,
          type === 'soft' && styles.soft,
        ])}
      >
        {units[id].name}
      </div>
      <RoleSelect
        rolesList={unit.rolesList}
        currentRole={role}
        roleUnitId={id}
        changeRole={handleChangeRole}
      />
      <DeleteRestoreButton
        isRestoreButton={isDeleted || isUpdated}
        deleteHowTo={() => {
          deleteRole(id, type, isNew);
        }}
        restoreHowTo={() => {
          restoreRole(id, isUpdated);
        }}
      />
    </>
  );
}

export default BUnitHowToElement;