import React, { useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import { getClearClassNames } from '@/helpers/helpers';
import styles from './BUnitNoneValue.module.sass';
import useNLS from '@/components/App/Admin/hooks/hooks';
import BUnitContext, { BUnitContextProps } from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';

interface BUnitNoneIndexProps {
  valueKey: NoneKeys;
  value: number | undefined;
}

export enum NoneKeys {
  Max = 'maxNone',
  Min = 'minNone',
  Count = 'noneCount',
}

const TooltipByKey = {
  [NoneKeys.Max]: '',
  [NoneKeys.Min]: '',
  [NoneKeys.Count]: '',
};

export default function BUnitNoneValue({ valueKey, value = undefined }: BUnitNoneIndexProps) {
  const { id } = useContext(BUnitContext) as Required<BUnitContextProps>;
  const tNLS = useNLS();
  if (!value && value !== 0) return null;

  return (
    <>
      <Tooltip
        id={`${id}-${valueKey}-howTo`}
        place="top"
        content={tNLS(TooltipByKey[valueKey])}
      />
      <div
        className={getClearClassNames([
          styles.value,
          styles[valueKey],
        ])}
        data-tooltip-id={`${id}-${valueKey}-howTo`}
      >
        {value}
      </div>
    </>

  );
}