// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-container--MKC3d{position:relative;display:flex;justify-content:space-between;align-items:baseline;border-radius:0.75rem;padding:0.25rem 0.5rem;transition:background linear 300ms}.unit-container--MKC3d:hover,.unit-container--MKC3d.backlight--sgQdE{background:var(--admin-hover-background-interactive-elements)}.actor--6jSAJ{justify-content:space-between}.actor--6jSAJ .radius--CcvuW{position:absolute;bottom:50%;right:100%;width:1.875rem;height:3.75rem;border-radius:0 0 0 0.75rem;border-bottom:1px solid #838999;border-left:1px solid #838999}.actor--6jSAJ:first-child .radius--CcvuW{height:1.875rem}.actor--6jSAJ:first-child .radius--CcvuW.no-first--TIx1B{height:3.75rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContainer/BUnitContainer.module.sass"],"names":[],"mappings":"AAEA,uBACE,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,oBAAA,CACA,qBAAA,CACA,sBAAA,CACA,kCAAA,CACA,qEACE,6DAAA,CAEJ,cACE,6BAAA,CACA,6BACE,iBAAA,CACA,UAAA,CACA,UAAA,CACA,cAAA,CACA,cAAA,CACA,2BAAA,CACA,+BAAA,CACA,6BAAA,CAEA,yCACE,eAAA,CACA,yDACE,cAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.unit-container\n  position: relative\n  display: flex\n  justify-content: space-between\n  align-items: baseline\n  border-radius: func.rem(12)\n  padding: func.rem(4) func.rem(8)\n  transition: background linear 300ms\n  &:hover,  &.backlight\n    background: var(--admin-hover-background-interactive-elements)\n\n.actor\n  justify-content: space-between\n  .radius\n    position: absolute\n    bottom: 50%\n    right: 100%\n    width: func.rem(30)\n    height: func.rem(60)\n    border-radius: 0 0 0 func.rem(12)\n    border-bottom: 1px solid #838999\n    border-left: 1px solid #838999\n  &:first-child\n    .radius\n      height: func.rem(30)\n      &.no-first\n        height: func.rem(60)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-container": "unit-container--MKC3d",
	"backlight": "backlight--sgQdE",
	"actor": "actor--6jSAJ",
	"radius": "radius--CcvuW",
	"no-first": "no-first--TIx1B"
};
export default ___CSS_LOADER_EXPORT___;
