// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-fourD--fHXn3{padding-top:2.5rem}.no-ratings-message--D9xTF{text-align:center;margin-top:20px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FourD/FourD.module.sass"],"names":[],"mappings":"AAEA,mBACE,kBAAA,CAEF,2BACE,iBAAA,CACA,eAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrap-fourD\n  padding-top: func.rem(40)\n\n.no-ratings-message\n  text-align: center\n  margin-top: 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-fourD": "wrap-fourD--fHXn3",
	"no-ratings-message": "no-ratings-message--D9xTF"
};
export default ___CSS_LOADER_EXPORT___;
