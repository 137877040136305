// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".magic-section--0HVRZ{display:flex;flex-direction:column}.items-placeholder--QL4Vh{width:100%;height:300px;appearance:none;-webkit-appearance:none;-moz-appearance:none;box-shadow:none;border:1px solid var(--main-color);color:var(--main-color);background:var(--main-bg);margin:0 0 16px 0;padding:12px;font-size:16px;border-radius:8px;overflow-y:hidden;transition:height 50ms linear}.items-placeholder--QL4Vh:focus{outline:none}.blue-button--Ktg8h{align-self:flex-end;width:fit-content}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ItemsPlaseholder/ItemsPlaceholder.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAEF,0BACE,UAAA,CACA,YAAA,CACA,eAAA,CACA,uBAAA,CACA,oBAAA,CACA,eAAA,CACA,kCAAA,CACA,uBAAA,CACA,yBAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CACA,iBAAA,CACA,iBAAA,CACA,6BAAA,CACA,gCACE,YAAA,CAGJ,oBACE,mBAAA,CACA,iBAAA","sourcesContent":[".magic-section\n  display: flex\n  flex-direction: column\n\n.items-placeholder\n  width: 100%\n  height: 300px\n  appearance: none\n  -webkit-appearance: none\n  -moz-appearance: none\n  box-shadow: none\n  border: 1px solid var(--main-color)\n  color: var(--main-color)\n  background: var(--main-bg)\n  margin: 0 0 16px 0\n  padding: 12px\n  font-size: 16px\n  border-radius: 8px\n  overflow-y: hidden\n  transition: height 50ms linear\n  &:focus\n    outline: none\n\n\n.blue-button\n  align-self: flex-end\n  width: fit-content"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magic-section": "magic-section--0HVRZ",
	"items-placeholder": "items-placeholder--QL4Vh",
	"blue-button": "blue-button--Ktg8h"
};
export default ___CSS_LOADER_EXPORT___;
