// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-password-section--jaKQf .title-create-password-section--tCzK2{color:var(--error-color)}.create-password-section--jaKQf .title-create-password-section--tCzK2 .icon-warning-title--a75ez{margin-right:0.3125rem}.create-password-section--jaKQf .title-create-password-section--tCzK2 .icon-warning-title--a75ez span:first-child:before{color:var(--error-color)}.create-password-section--jaKQf .form-create-password--WN2R4{display:flex;flex-direction:column;gap:0.9375rem}.create-password-section--jaKQf .form-create-password--WN2R4 .label-field--bKYtp{width:100%}.create-password-section--jaKQf .form-create-password--WN2R4 .label-field--bKYtp input.password-dont-math--TsLlf{border:1px solid var(--error-color);outline:1px solid var(--error-color)}.create-password-section--jaKQf .form-create-password--WN2R4 .error-valid-msg--5K0DG{color:var(--error-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/AdminProfile/CreatePassword/CreatePassword.module.sass"],"names":[],"mappings":"AAGE,sEACE,wBAAA,CACA,iGACE,sBAAA,CAGI,yHACE,wBAAA,CACV,6DACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,iFACE,UAAA,CAEE,iHACE,mCAAA,CACA,oCAAA,CACN,qFACE,wBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.create-password-section\n  .title-create-password-section\n    color: var(--error-color)\n    .icon-warning-title\n      margin-right: func.rem(5)\n      span\n        &:first-child\n          &:before\n            color: var(--error-color)\n  .form-create-password\n    display: flex\n    flex-direction: column\n    gap: func.rem(15)\n    .label-field\n      width: 100%\n      input\n        &.password-dont-math\n          border: 1px solid var(--error-color)\n          outline: 1px solid var(--error-color)\n    .error-valid-msg\n      color: var(--error-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create-password-section": "create-password-section--jaKQf",
	"title-create-password-section": "title-create-password-section--tCzK2",
	"icon-warning-title": "icon-warning-title--a75ez",
	"form-create-password": "form-create-password--WN2R4",
	"label-field": "label-field--bKYtp",
	"password-dont-math": "password-dont-math--TsLlf",
	"error-valid-msg": "error-valid-msg--5K0DG"
};
export default ___CSS_LOADER_EXPORT___;
