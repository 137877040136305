import React, { useContext } from 'react';
import styles from './BUnitCheckBox.module.sass';
import { selectTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import BUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { useAdminDispatch } from '@/store/hooks/adminRedux';
import { UnitsSelectActionFlags } from '../../../../SelectMenu/useSelectAction';

export default function BUnitCheckBox() {
  const { unit, id } = useContext(BUnitContext);
  const dispatch = useAdminDispatch();

  const selectUnits = (isSelect: boolean) => {
    dispatch(
      selectTreeUnits({
        unitsId: [id],
        selectValue: isSelect ? UnitsSelectActionFlags.Select : UnitsSelectActionFlags.Unselect,
      }),
    );
  };

  return (
    <label
      htmlFor={`${id}-select-unit`}
      className={styles['checkbox-label']}
    >
      {' '}
      <input
        id={`${id}-select-unit`}
        className={styles['unit-checkbox']}
        type="checkbox"
        checked={unit.selected}
        onChange={() => selectUnits(!unit.selected)}
      />
    </label>
  );
}