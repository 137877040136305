// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-menu-container--ZO0Eq{position:relative;padding:1.5rem 0.75rem;width:37.5rem;background:var(--content-block-bg);border-radius:12px;border:1px solid var(--admin-hover-background-interactive-elements)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardContentContayner/BUnitCardContentContainer.module.sass"],"names":[],"mappings":"AAEA,4BACE,iBAAA,CACA,sBAAA,CACA,aAAA,CACA,kCAAA,CACA,kBAAA,CACA,mEAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.unit-menu-container\n  position: relative\n  padding: func.rem(24) func.rem(12)\n  width: func.rem(600)\n  background: var(--content-block-bg)\n  border-radius: 12px\n  border: 1px solid var(--admin-hover-background-interactive-elements)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-menu-container": "unit-menu-container--ZO0Eq"
};
export default ___CSS_LOADER_EXPORT___;
