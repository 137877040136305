// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".specs--vQJxZ{display:flex;flex-direction:row;flex-wrap:wrap;gap:0.75rem;margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitAddSpecsModal/EditSpecsInActionModal/EditSpecsInActionModal.module.sass"],"names":[],"mappings":"AAEA,cACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.specs\n  display: flex\n  flex-direction: row\n  flex-wrap: wrap\n  gap: func.rem(12)\n  margin-bottom: func.rem(16)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"specs": "specs--vQJxZ"
};
export default ___CSS_LOADER_EXPORT___;
