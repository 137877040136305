import React from 'react';
import useSupersets from '@/components/App/Admin/ListSupersets/useSupersets';
import { FilterSupersetsFlag } from '@/components/App/Admin/ListSupersets/FilterSupersets/FilterSupersetsTabs';
import FilteredSupersetsList from '@/components/App/Admin/ListSupersets/FilteredSupersetsList/FilteredSupersetsList';
import { useAdminSelector } from '@/store/hooks/adminRedux';
import { selectAvailableSupersets, selectSupersetsLoadingStatus } from '@/store/selectors/admin/superset/supersets';
import LoadingStatus from '@/constants/enums';
import Preloader from '../SupersetSettings/Preloader';

interface ListSupersetsProps {
  filterFlag: FilterSupersetsFlag;
}

function ListSupersets({ filterFlag }: ListSupersetsProps) {
  useSupersets();

  const availableSupersets = useAdminSelector(selectAvailableSupersets);
  const supersetsLoadingStatus = useAdminSelector(selectSupersetsLoadingStatus);

  if (supersetsLoadingStatus === LoadingStatus.Loading) return <Preloader />;

  return (
    <FilteredSupersetsList
      filterFlag={filterFlag}
      supersets={availableSupersets}
    />
  );
}

export default ListSupersets;