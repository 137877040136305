// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cloud-superset-item--nBYhT{font-size:1.3rem;display:flex;margin-left:10px}.cloud-superset-item--nBYhT details{width:100%;margin-bottom:5px}.cloud-superset-item--nBYhT details summary{display:flex;gap:5px}.cloud-superset-item--nBYhT details summary::before{content:url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M12.6 12 8 7.4 9.4 6l6 6-6 6L8 16.6z'/%3E%3C/svg%3E\");display:block}.cloud-superset-item--nBYhT details[open]>summary::before{transform:rotate(90deg)}.cloud-superset-item--nBYhT details .cloud-summary-header--ebxrm{width:100%;display:inline-flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/components/App/Super/Cloud/CloudSuperset/CloudSuperset.module.sass"],"names":[],"mappings":"AAAA,4BACI,gBAAA,CACA,YAAA,CACA,gBAAA,CACA,oCACI,UAAA,CACA,iBAAA,CACA,4CACI,YAAA,CACA,OAAA,CACA,oDACI,wLAAA,CACA,aAAA,CAEJ,0DACI,uBAAA,CAER,iEACI,UAAA,CACA,mBAAA,CACA,6BAAA","sourcesContent":[".cloud-superset-item\n    font-size: 1.3rem\n    display: flex\n    margin-left: 10px\n    details\n        width: 100%\n        margin-bottom: 5px\n        summary\n            display: flex\n            gap: 5px\n            &::before\n                content: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M12.6 12 8 7.4 9.4 6l6 6-6 6L8 16.6z'/%3E%3C/svg%3E\")\n                display: block\n        &[open]\n            > summary::before\n                transform: rotate(90deg)\n\n        .cloud-summary-header\n            width: 100%\n            display: inline-flex\n            justify-content: space-between\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cloud-superset-item": "cloud-superset-item--nBYhT",
	"cloud-summary-header": "cloud-summary-header--ebxrm"
};
export default ___CSS_LOADER_EXPORT___;
