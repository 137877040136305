// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".transfer-units-list--zIvgs{height:12.5rem;overflow-y:scroll;margin:0 0 0.5rem 0;border-radius:0.5rem;border:1px solid var(--secondary-color)}.transfer-units-list--zIvgs::-webkit-scrollbar{width:8px;margin-right:8px}.transfer-units-list--zIvgs::-webkit-scrollbar-track{background-color:rgba(0,0,0,.05);cursor:pointer}.transfer-units-list--zIvgs::-webkit-scrollbar-thumb{background:#2f80ed;border-radius:6px;margin-right:15px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu/BUnitTransferModal/UnitsForTransferList/UnitsForTransferList.module.sass"],"names":[],"mappings":"AAEA,4BACE,cAAA,CACA,iBAAA,CACA,mBAAA,CACA,oBAAA,CACA,uCAAA,CACA,+CACE,SAAA,CACA,gBAAA,CACF,qDACE,gCAAA,CACA,cAAA,CACF,qDACE,kBAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.transfer-units-list\n  height: func.rem(200)\n  overflow-y: scroll\n  margin: 0 0 func.rem(8) 0\n  border-radius: func.rem(8)\n  border: 1px solid var(--secondary-color)\n  &::-webkit-scrollbar\n    width: 8px\n    margin-right: 8px\n  &::-webkit-scrollbar-track\n    background-color: rgba(0,0,0,0.05)\n    cursor: pointer\n  &::-webkit-scrollbar-thumb\n    background: #2F80ED\n    border-radius: 6px\n    margin-right: 15px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transfer-units-list": "transfer-units-list--zIvgs"
};
export default ___CSS_LOADER_EXPORT___;
