// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".data-circle--EYwU6{stroke:#000;stroke-width:.5;cursor:pointer;transition:all .3s ease}.data-circle--EYwU6.data-circle-highlighted--1Q--f{stroke:#5041ff;transform-box:fill-box;transform-origin:center;animation:pulsate--dtUOZ 1.5s infinite}@keyframes pulsate--dtUOZ{0%{transform:scale(1);stroke-width:3px}50%{transform:scale(1.1);stroke-width:8px}100%{transform:scale(1);stroke-width:3px}}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FourD/Diagram/CirclesArea/DataCircle/DataCircle.module.sass"],"names":[],"mappings":"AAAA,oBACE,WAAA,CACA,eAAA,CACA,cAAA,CACA,uBAAA,CACA,mDACE,cAAA,CACA,sBAAA,CACA,uBAAA,CACA,sCAAA,CAEJ,0BACE,GACE,kBAAA,CACA,gBAAA,CAEF,IACE,oBAAA,CACA,gBAAA,CAEF,KACE,kBAAA,CACA,gBAAA,CAAA","sourcesContent":[".data-circle\n  stroke: #000000\n  stroke-width: 0.5\n  cursor: pointer\n  transition: all 0.3s ease\n  &.data-circle-highlighted\n    stroke: #5041ff\n    transform-box: fill-box\n    transform-origin: center\n    animation: pulsate 1.5s infinite\n\n@keyframes pulsate\n  0%\n    transform: scale(1)\n    stroke-width: 3px\n\n  50%\n    transform: scale(1.1)\n    stroke-width: 8px\n\n  100%\n    transform: scale(1)\n    stroke-width: 3px\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"data-circle": "data-circle--EYwU6",
	"data-circle-highlighted": "data-circle-highlighted--1Q--f",
	"pulsate": "pulsate--dtUOZ"
};
export default ___CSS_LOADER_EXPORT___;
