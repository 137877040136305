// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select-superset-tabs---hL-m{width:100%;margin-bottom:20px}.select-superset-tabs---hL-m .select-superset-labels--b2tt3 .select-superset-label--HuJ2L{background:var(--admin-btn-bg)}.select-superset-tabs---hL-m .select-superset-labels--b2tt3 .select-superset-label--HuJ2L:not(:last-child){border-right:2px solid var(--admin-filter-projects-border)}.select-superset-tabs---hL-m .select-superset-labels--b2tt3 .select-superset-label--HuJ2L .label-btn--6083k{color:var(--admin-btn-color);padding:0.9375rem 1.5625rem}.select-superset-tabs---hL-m .select-superset-labels--b2tt3 .select-superset-label--HuJ2L.active-label--1gZCj,.select-superset-tabs---hL-m .select-superset-labels--b2tt3 .select-superset-label--HuJ2L:hover{background-color:#2279fc}.select-superset-tabs---hL-m .select-superset-labels--b2tt3 .select-superset-label--HuJ2L.active-label--1gZCj .label-btn--6083k,.select-superset-tabs---hL-m .select-superset-labels--b2tt3 .select-superset-label--HuJ2L:hover .label-btn--6083k{color:#fff}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/FilterSupersets/FilterSupersetsTabs.module.sass"],"names":[],"mappings":"AAEA,6BACE,UAAA,CACA,kBAAA,CAEE,0FACE,8BAAA,CACA,2GACE,0DAAA,CACF,4GACE,4BAAA,CACA,2BAAA,CACF,8MACE,wBAAA,CACA,kPACE,UAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.select-superset-tabs\n  width: 100%\n  margin-bottom: 20px\n  .select-superset-labels\n    .select-superset-label\n      background: var(--admin-btn-bg)\n      &:not(:last-child)\n        border-right: 2px solid var(--admin-filter-projects-border)\n      .label-btn\n        color: var(--admin-btn-color)\n        padding: func.rem(15) func.rem(25)\n      &.active-label, &:hover\n        background-color: #2279FC\n        .label-btn\n          color: #FFFFFF"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-superset-tabs": "select-superset-tabs---hL-m",
	"select-superset-labels": "select-superset-labels--b2tt3",
	"select-superset-label": "select-superset-label--HuJ2L",
	"label-btn": "label-btn--6083k",
	"active-label": "active-label--1gZCj"
};
export default ___CSS_LOADER_EXPORT___;
