// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-adding-formulations--NH9gy{display:flex;flex-direction:column;gap:1.25rem}.form-adding-formulations--NH9gy .wrap-formulation-field--Cm3WX .wrap-custom-input--7pMFN .label-input-formulation--H4CEO{width:100%}.form-adding-formulations--NH9gy .wrap-formulation-field--Cm3WX .field-error-message--RhoD1{display:inline-block;margin-top:0.625rem;font-family:\"Inter Medium\",sans-serif;color:var(--error-color);font-size:0.875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/AddingQuestions/ModalAddQuestion/ModalAddQuestion.module.sass"],"names":[],"mappings":"AAEA,iCACE,YAAA,CACA,qBAAA,CACA,WAAA,CAGI,0HACE,UAAA,CACJ,4FACE,oBAAA,CACA,mBAAA,CACA,qCAAA,CACA,wBAAA,CACA,kBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.form-adding-formulations\n  display: flex\n  flex-direction: column\n  gap: func.rem(20)\n  .wrap-formulation-field\n    .wrap-custom-input\n      .label-input-formulation\n        width: 100%\n    .field-error-message\n      display: inline-block\n      margin-top: func.rem(10)\n      font-family: 'Inter Medium', sans-serif\n      color: var(--error-color)\n      font-size: func.rem(14)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-adding-formulations": "form-adding-formulations--NH9gy",
	"wrap-formulation-field": "wrap-formulation-field--Cm3WX",
	"wrap-custom-input": "wrap-custom-input--7pMFN",
	"label-input-formulation": "label-input-formulation--H4CEO",
	"field-error-message": "field-error-message--RhoD1"
};
export default ___CSS_LOADER_EXPORT___;
