import React from 'react';
import AdminMainListLink from '../../../AdminMainListLinkLink';
import SupersetStageInfo from '@/components/App/Admin/ListSupersets/FilteredSupersetsList/SupersetStageInfo/SupersetStageInfo';
import { Superset } from '@/store/types/admin/supersets';
import { SuperAdminSuperset } from '@/store/types/super/supersets/supersets';
import LinkDates from '../../../AdminMainListLinkLink/types';

interface SupersetListLinkProps {
  superset: Superset | SuperAdminSuperset;
  index: number;
}

function SupersetListLink({ superset, index }: SupersetListLinkProps) {
  const {
    id, createdAt, name, stage, useFeedBack, lastActive,
  } = superset;

  const dates: LinkDates = [
    ['Создан: ', createdAt],
    ['Последний протокол: ', lastActive as string],
  ];

  return (
    <AdminMainListLink
      key={id}
      to={`/admin/project/${id}`}
      linkTitle={`${index}. ${name}`}
      metaInfo={<SupersetStageInfo currentStage={stage} useFeedback={useFeedBack} />}
      dates={dates}
    />
  );
}

export default SupersetListLink;