// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-btns--BmQuY{display:flex;justify-content:center;gap:0.625rem;margin-top:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/ResultUploading/ResultUploadingTemplates/UploadingTemplateButtons/UploadingTemplateButtons.module.sass"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.wrap-btns\n  display: flex\n  justify-content: center\n  gap: func.rem(10)\n  margin-top: func.rem(20)\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-btns": "wrap-btns--BmQuY"
};
export default ___CSS_LOADER_EXPORT___;
