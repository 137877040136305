// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-nps-score--VQXO1{display:flex;position:absolute;right:1.25rem;top:-5rem}.wrap-nps-score--VQXO1 .icon-nps-score--J9Xhm:before{font-size:1.75rem}.wrap-nps-score--VQXO1 .nps-scores-info--hJ7ih{position:relative}.wrap-nps-score--VQXO1 .nps-scores-info--hJ7ih .icon-nps-score--J9Xhm{position:absolute;top:0;left:0}.wrap-nps-score--VQXO1 .nps-scores-info--hJ7ih .wrap-type-of-values--WdhWf{display:flex;flex-direction:column;margin-left:2.1875rem;margin-top:0.1875rem;font-size:0.875rem}.wrap-nps-score--VQXO1 .wrap-tooltip-nps-score--NPDsm{display:flex;flex-direction:column;max-width:25rem;gap:0.3125rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/CutsChart/NPSscore/NPSscore.module.sass"],"names":[],"mappings":"AAEA,uBACE,YAAA,CACA,iBAAA,CACA,aAAA,CACA,SAAA,CAEE,qDACE,iBAAA,CACJ,+CACE,iBAAA,CACA,sEACE,iBAAA,CACA,KAAA,CACA,MAAA,CACF,2EACE,YAAA,CACA,qBAAA,CACA,qBAAA,CACA,oBAAA,CACA,kBAAA,CAEJ,sDACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,aAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.wrap-nps-score\n  display: flex\n  position: absolute\n  right: func.rem(20)\n  top: func.rem(-80)\n  .icon-nps-score\n    &:before\n      font-size: func.rem(28)\n  .nps-scores-info\n    position: relative\n    .icon-nps-score\n      position: absolute\n      top: 0\n      left: 0\n    .wrap-type-of-values\n      display: flex\n      flex-direction: column\n      margin-left: func.rem(35)\n      margin-top: func.rem(3)\n      font-size: func.rem(14)\n\n  .wrap-tooltip-nps-score\n    display: flex\n    flex-direction: column\n    max-width: func.rem(400)\n    gap: func.rem(5)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-nps-score": "wrap-nps-score--VQXO1",
	"icon-nps-score": "icon-nps-score--J9Xhm",
	"nps-scores-info": "nps-scores-info--hJ7ih",
	"wrap-type-of-values": "wrap-type-of-values--WdhWf",
	"wrap-tooltip-nps-score": "wrap-tooltip-nps-score--NPDsm"
};
export default ___CSS_LOADER_EXPORT___;
