import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import parse from 'html-react-parser';
import styles from './ListSelectedItems.module.sass';
import { useGetSelectedObjectIDs } from '@/components/App/Admin/Results/Cuts/FourD/hooks';
import {
  selectIsSelectionCompleteStatus,
  selectSelectedObjects, selectSelectedParams,
} from '@/store/selectors/admin/superset/process/cuts/fourD';
import { AdminState } from '@/store/admin';
import { ParamName, Rating, RatingResultByScoreType } from '@/store/types/admin/superset/cuts/fourD';
import { setTargetedObjectID } from '@/store/actions/admin/superset/process/cuts/fourD';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { MAP_SOURS_NUMBER_TO_SOURCE_NLS } from '@/components/App/Admin/Results/Cuts/FourD/constants';

interface ListSelectedItemsProps {
  svgContainerWidth: number;
  selectionRect?: { x: number; y: number; width: number; height: number } | null;
}

function ListSelectedItems({ svgContainerWidth, selectionRect } : ListSelectedItemsProps) {
  const dispatch = useDispatch();
  const isSelectionComplete = useSelector(selectIsSelectionCompleteStatus);
  const selectedObjectIDs = useGetSelectedObjectIDs(
    svgContainerWidth,
    selectionRect,
  );
  const selectedObjects = useSelector((state: AdminState) => (
    selectSelectedObjects(state, selectedObjectIDs)
  ));

  const {
    xRating, yRating, ratingSize, ratingColor,
  } = useSelector(selectSelectedParams);
  const tNLS = useNLS();

  const truncateDecimal = (number: number) => {
    const truncated = Math.floor(number * 10) / 10;
    const twoDecimals = truncated.toFixed(2);

    return parseFloat(twoDecimals).toString();
  };

  const getSourceName = (source: number) => (
    tNLS(MAP_SOURS_NUMBER_TO_SOURCE_NLS[source].header)
  );

  const renderObjectsListHeader = () => {
    const getColTemplate = (rating: Rating, paramName: ParamName) => (
      <div className={`${styles.col}`}>

        <div className={styles['wrap-rating-name']}>
          {rating.name}

          <Tooltip id={`tooltip-rating-source--${paramName}`}>
            {parse(getSourceName(rating.source))}
          </Tooltip>

          <div
            className={styles['rating-source']}
            data-tooltip-id={`tooltip-rating-source--${paramName}`}
          >
            <span
              className={`${styles['icon-rating-source']} icon-outgoing`}
            />

          </div>
        </div>

      </div>
    );

    return (
      <li className={`${styles.row} ${styles.header}`}>
        <div className={`${styles.col} ${styles['col object-name']}`} />

        {getColTemplate(xRating, 'xRating')}
        {getColTemplate(yRating, 'yRating')}
        {getColTemplate(ratingSize, 'ratingSize')}
        {ratingColor && getColTemplate(ratingColor, 'ratingColor')}
      </li>
    );
  };

  const toSelectObjectOnDiagram = (id: string) => {
    dispatch(setTargetedObjectID(id));
  };

  const renderObjectsList = (objects: RatingResultByScoreType[]) => (
    objects.map((object) => {
      const {
        id, name, xPerc, yPerc, orgScore, size, color,
      } = object;

      return (
        <li
          className={`${styles.row} ${styles['item-object']}`}
          key={id}
        >
          <button
            type="button"
            onClick={() => toSelectObjectOnDiagram(id)}
          >
            <div className={`${styles.col} ${styles['col object-name']}`}>
              {name}
            </div>

            <div className={`${styles.col} ${styles['col-value']}`}>
              <div className={styles['wrap-values']}>
                <span className={styles['org-score']}>
                  {truncateDecimal(orgScore.x)}
                </span>
                <span className={styles.perc}>
                  {`${truncateDecimal(xPerc)}%`}
                </span>
              </div>
            </div>

            <div className={`${styles.col} ${styles['col-value']}`}>
              <div className={styles['wrap-values']}>
                <span className={styles['org-score']}>
                  {truncateDecimal(orgScore.y)}
                </span>
                <span className={styles.perc}>
                  {`${truncateDecimal(yPerc)}%`}
                </span>
              </div>
            </div>

            <div className={`${styles.col} ${styles['col-value']}`}>
              {orgScore.size ? (
                <div className={styles['wrap-values']}>
                  <span className={styles['org-score']}>
                    {truncateDecimal(orgScore.size)}
                  </span>
                  <span className={styles.perc}>
                    {`${truncateDecimal(size)}%`}
                  </span>
                </div>
              ) : (
                <div className={styles['wrap-values']}>—</div>
              )}

            </div>

            {ratingColor && (
            <div className={`${styles.col} ${styles['col-value']} ${styles['col-value--color']}`}>
              <div className={styles['color-value']} style={{ backgroundColor: color }} />
            </div>
            )}

          </button>

        </li>
      );
    })
  );

  if (isSelectionComplete && selectedObjectIDs.length > 0) {
    return (
      <div className={styles['wrap-list-selected-objects']}>
        <ul className={styles['list-of-selected-object']}>
          {renderObjectsListHeader()}
          {renderObjectsList(selectedObjects)}
        </ul>
      </div>
    );
  }

  return <div>Нет выбранных людей</div>;
}

export default ListSelectedItems;