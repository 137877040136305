import { getTimeDifferenceInSeconds } from '@/helpers/helpers';
import {
  SentProtocol, Protocols, ProtocolsTime, Relations, RelationComments, RelationsTime,
} from '@/store/types/user/protocols';
import {
  SetAnswerInLocalProps, UnsavedProtocolsType, ReadAnswersFromLocalStorageProps, UpdatedDataType,
} from './types';

export const ANSWERS_KEY = (actorId: string, projectId: string) => `${actorId}-${projectId}-unsaved-answers`;

const filterProtocol = (protocol: SentProtocol, protocols: Protocols, protocolsTime: ProtocolsTime): boolean => {
  const { itemId, answer, time } = protocol;
  const localAnswer = protocols?.[itemId];
  const localTime = protocolsTime?.[itemId];

  if (!localTime || !localAnswer) return true;

  const timeDiff = getTimeDifferenceInSeconds({ time: localTime }) + Number(time);

  if (timeDiff < 0 && localAnswer !== answer) {
    delete protocols[itemId];
    delete protocolsTime[itemId];
    return false;
  }

  return true;
};

const filterRelation = (protocol: SentProtocol, relations: Relations | RelationComments, relationsTime: RelationsTime) => {
  const { itemId, unitId, time } = protocol;

  if (!unitId) return true;

  const localTime = relationsTime?.[itemId]?.[unitId];
  const localAnswer = relations?.[itemId]?.[unitId];

  if (!localTime || !localAnswer) return true;

  const timeDiff = getTimeDifferenceInSeconds({ time: localTime }) + Number(time);

  if (timeDiff < 0) {
    delete relations[itemId][unitId];
    delete relationsTime[itemId][unitId];
    if (!Object.keys(relations[itemId]).length) delete relations[itemId];
    if (!Object.keys(relationsTime[itemId]).length) delete relationsTime[itemId];
    return false;
  }

  return true;
};

export const setAnswersInLocalStorage = ({
  actorId,
  projectId,
  ...state
}: SetAnswerInLocalProps) => {
  if (!actorId || !projectId) return;
  const localKey = ANSWERS_KEY(actorId, projectId);
  const {
    updatedItems, updatedRelations, updatedRelationsComments, relationsTime, protocolsTime, commentsTime,
  } = state;

  const ObjForSave: UnsavedProtocolsType = {
    protocols: updatedItems,
    relations: updatedRelations,
    relationsComments: updatedRelationsComments,
    protocolsTime,
    relationsTime,
    commentsTime,
  };

  localStorage.setItem(
    localKey,
    JSON.stringify(ObjForSave),
  );
};

export const addAnswersFromLocal = ({ actorId, projectId, protocolsArray = [] }: ReadAnswersFromLocalStorageProps): UpdatedDataType => {
  // TODO переделать в класс
  const defaultReturn = {
    updatedItems: {},
    updatedRelations: {},
    updatedRelationsComments: {},
    protocolsTime: {},
    relationsTime: {},
    commentsTime: {},
    filteredProtocols: protocolsArray,
  };

  if (!actorId || !projectId) return defaultReturn;

  const localKey = ANSWERS_KEY(actorId, projectId);

  const localAnswers: UnsavedProtocolsType = JSON.parse(
    localStorage.getItem(localKey) || 'null',
  );

  if (!localAnswers) return defaultReturn;

  const {
    protocols = {}, relations = {}, relationsComments = {}, protocolsTime = {}, relationsTime = {}, commentsTime = {},
  } = localAnswers;

  const filteredProtocols = protocolsArray.filter((protocol) => {
    const {
      isComment, unitId,
    } = protocol;

    if (!unitId) return filterProtocol(protocol, protocols, protocolsTime);

    if (isComment) return filterRelation(protocol, relationsComments, commentsTime);

    return filterRelation(protocol, relations, relationsTime);
  });

  return {
    updatedItems: protocols,
    updatedRelations: relations,
    updatedRelationsComments: relationsComments,
    protocolsTime,
    relationsTime,
    commentsTime,
    filteredProtocols,
  };
};