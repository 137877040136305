// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".value--\\+99UB{text-align:left}.value--\\+99UB.minNone--0oL1t{grid-column:1}.value--\\+99UB.maxNone--3fUQa{grid-column:2}.value--\\+99UB.noneCount--87534{grid-column:3}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitNoneInfo/BUnitNoneIndex/BUnitNoneValue.module.sass"],"names":[],"mappings":"AAEA,eACE,eAAA,CACA,8BACE,aAAA,CACF,8BACE,aAAA,CACF,gCACE,aAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.value\n  text-align: left\n  &.minNone\n    grid-column: 1\n  &.maxNone\n    grid-column: 2\n  &.noneCount\n    grid-column: 3\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"value": "value--+99UB",
	"minNone": "minNone--0oL1t",
	"maxNone": "maxNone--3fUQa",
	"noneCount": "noneCount--87534"
};
export default ___CSS_LOADER_EXPORT___;
