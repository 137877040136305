import React, { Dispatch, SetStateAction } from 'react';
import styles from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/SelectMenu.module.sass';
import BUnitsSelectMenuActions from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/BUnitsSelectMenuActions/BUnitsSelectMenuActions';
import BUnitsSelectMenuOptions from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitsSelectMenuOptions/BUnitsSelectMenuOptions';
import {
  BUnitSelectAction,
  SetCurrentSelectActionType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectAction';
import {
  BUnitSelectOption,
  SetCurrentSelectOptionType,
} from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectOption';
import BUnitSelectMenuAdditionalOption from '../BUnitsSelectMenuOptions/BUnitSelectMenuAdditionalOption/BUnitSelectMenuAdditionalOption';

interface BUnitsSelectMenuSelectorsProps {
  currentSelectAction: BUnitSelectAction;
  currentSelectOption: BUnitSelectOption;
  setCurrentSelectAction: SetCurrentSelectActionType;
  setCurrentSelectOption: SetCurrentSelectOptionType;
  currentSelectValue: string;
  setCurrentSelectValue: Dispatch<SetStateAction<string>>;
}
function BUnitsSelectMenuSelectors({
  currentSelectAction,
  setCurrentSelectAction,
  currentSelectOption,
  setCurrentSelectOption,
  currentSelectValue,
  setCurrentSelectValue,
}: BUnitsSelectMenuSelectorsProps) {
  return (
    <>
      <div className={styles['select-buttons']}>
        <BUnitsSelectMenuActions
          currentSelectAction={currentSelectAction}
          setCurrentSelectAction={setCurrentSelectAction}
        />
        <BUnitsSelectMenuOptions
          currentSelectOption={currentSelectOption}
          setCurrentSelectOption={setCurrentSelectOption}
        />
      </div>
      <BUnitSelectMenuAdditionalOption
        currentSelectOptionKey={currentSelectOption.key}
        currentSelectValue={currentSelectValue}
        setCurrentSelectValue={setCurrentSelectValue}
      />
    </>
  );
}

export default BUnitsSelectMenuSelectors;