import { Dispatch, SetStateAction, useState } from 'react';
import useLocalStorageByAdmin from '@/components/App/Admin/BUnitsSection/UnitsTree/hooks/adminLocalStorageSaver';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { getSelectOptionByType } from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/helpers';

export type SetCurrentSelectOptionType = Dispatch<SetStateAction<BUnitSelectOption>>;

export enum UnitsSelectOptionsFlags {
  All = 'all', // все
  Started = 'started', // приступившие
  Finished = 'finished', // завершившие
  NotStarted = 'notStarted', // не приступавшие
  Spec = 'spec', // по специализациям
  Focus = 'focus', // оцениваемые
  Respondent = 'respondent', // оценивающие
  HasReport = 'hasReport', // имеющие отчет
  Score = 'inRating', // Входят в группу рейтинга
  NoScore = 'notInRating', // Не входят в группу рейтинга
}

export type UnitSelectOptionsNlsKeysType = Record<UnitsSelectOptionsFlags, string>;

export const UnitSelectOptionsNlsKeys: UnitSelectOptionsNlsKeysType = {
  [UnitsSelectOptionsFlags.All]: 'tree.select.all',
  [UnitsSelectOptionsFlags.Started]: 'tree.select.started',
  [UnitsSelectOptionsFlags.Finished]: 'tree.select.finished',
  [UnitsSelectOptionsFlags.NotStarted]: 'tree.select.didntstart',
  [UnitsSelectOptionsFlags.Spec]: 'tree.select.byspec',
  [UnitsSelectOptionsFlags.Focus]: 'tree.select.foci',
  [UnitsSelectOptionsFlags.Respondent]: 'tree.select.respondents',
  [UnitsSelectOptionsFlags.HasReport]: 'tree.select.bypersreports',
  [UnitsSelectOptionsFlags.Score]: 'tree.select.byscore',
  [UnitsSelectOptionsFlags.NoScore]: 'tree.select.noscore',
};

export interface BUnitSelectOption {
  key: UnitsSelectOptionsFlags;
  name: string;
}

const useSelectOption = (): [BUnitSelectOption, SetCurrentSelectOptionType] => {
  const [, readFromLocalStorage] = useLocalStorageByAdmin<BUnitSelectOption>('selectOptions');
  const tNLS = useNLS();
  const [currentSelectOption, setCurrentSelectOption] = useState<BUnitSelectOption>(
    () => readFromLocalStorage() || getSelectOptionByType(UnitsSelectOptionsFlags.All, tNLS),
  );

  return [currentSelectOption, setCurrentSelectOption];
};

export default useSelectOption;