// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper-container--tVHcF{padding:0 15px}.wrapper-container--tVHcF .wrap-admin-profile--7VmxH{max-width:21.875rem;margin:0 auto;background:var(--content-block-bg);border-radius:20px;padding:20px}.wrapper-container--tVHcF .wrap-admin-profile--7VmxH .title--OuimL{font-size:1.75rem;margin-bottom:1.25rem}.admin-profile-section{border-top:.5px solid var(--dark-grey-line-color);padding:0.625rem 0 1.25rem 0}.admin-profile-section .title-section{color:var(--admin-main-font-color);font-size:1rem;font-weight:600;margin-bottom:1.25em}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/AdminProfile/AdminProfile.module.sass"],"names":[],"mappings":"AAEA,0BACE,cAAA,CACA,qDACE,mBAAA,CACA,aAAA,CACA,kCAAA,CACA,kBAAA,CACA,YAAA,CACA,mEACE,iBAAA,CACA,qBAAA,CACN,uBACE,iDAAA,CACA,4BAAA,CACA,sCACE,kCAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrapper-container\n  padding: 0 15px\n  .wrap-admin-profile\n    max-width: func.rem(350)\n    margin: 0 auto\n    background: var(--content-block-bg)\n    border-radius: 20px\n    padding: 20px\n    .title\n      font-size: func.rem(28)\n      margin-bottom: func.rem(20)\n:global(.admin-profile-section)\n  border-top: 0.5px solid var(--dark-grey-line-color)\n  padding: func.rem(10) 0 func.rem(20) 0\n  :global(.title-section)\n    color: var(--admin-main-font-color)\n    font-size: func.rem(16)\n    font-weight: 600\n    margin-bottom: func.em(20, 16)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper-container": "wrapper-container--tVHcF",
	"wrap-admin-profile": "wrap-admin-profile--7VmxH",
	"title": "title--OuimL"
};
export default ___CSS_LOADER_EXPORT___;
