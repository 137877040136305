// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".admin-restore-account--OhH1s{width:18.75rem;margin:0 auto;flex-direction:column}.admin-restore-account--OhH1s h3{margin-bottom:0.625rem;text-align:center}.admin-restore-account--OhH1s p{color:#85878e;margin-bottom:0.625rem}.admin-restore-account--OhH1s .label-field--SpWFC{width:100%}.admin-restore-account--OhH1s .btn-on-restore-account--fWigx{margin-top:1.25rem;width:100%}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/RestoreAccount/RestoreAccount.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAGA,8BACE,cAAA,CACA,aAAA,CACA,qBAAA,CACA,iCACE,sBAAA,CACA,iBAAA,CACF,gCACE,aCTuB,CDUvB,sBAAA,CACF,kDACE,UAAA,CACF,6DACE,kBAAA,CACA,UAAA","sourcesContent":["@use 'src/styles/functions' as func\n@use 'src/styles/colors'\n\n.admin-restore-account\n  width: func.rem(300)\n  margin: 0 auto\n  flex-direction: column\n  h3\n    margin-bottom: func.rem(10)\n    text-align: center\n  p\n    color: colors.$pale-gray-text-secondary\n    margin-bottom: func.rem(10)\n  .label-field\n    width: 100%\n  .btn-on-restore-account\n    margin-top: func.rem(20)\n    width: 100%","$pale-gray: #6D6F75\n$pale-gray-text: #9E9FA3\n$pale-gray-text-secondary: #85878E"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin-restore-account": "admin-restore-account--OhH1s",
	"label-field": "label-field--SpWFC",
	"btn-on-restore-account": "btn-on-restore-account--fWigx"
};
export default ___CSS_LOADER_EXPORT___;
