import React from 'react';
import CustomSelectOption from '../../../../CustomSelect/CustomSelectOption/CustomSelectOption';
import { Option } from '@/components/App/Admin/Results/Cuts/FourD/FourDParamsForm/FourDParamsForm';
import CustomSelectOptionLabel from '../../../../CustomSelect/CustomSelectOptionLabel/CustomSelectOptionLabel';
import styles from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/SelectMenu.module.sass';

interface BUnitSelectMenuRatingSectionProps {
  options: Option[]
  selectRatingId: (id: string, name: string) => void;
  selectedRatingId: string;
  sectionLabel: string;
}

export default function BUnitSelectMenuRatingSection({
  options, selectRatingId, selectedRatingId, sectionLabel,
}: BUnitSelectMenuRatingSectionProps) {
  return (
    <>
      <CustomSelectOptionLabel
        className={styles['unit-menu-select']}
      >
        {sectionLabel}
      </CustomSelectOptionLabel>
      {options.map(({ value, label }) => (

        <CustomSelectOption
          key={value}
          optionClassNames={styles['unit-menu-select']}
          onClick={() => selectRatingId(`${value}`, label)}
          isCurrentValue={value === selectedRatingId}
        >
          {label}
        </CustomSelectOption>
      ))}
    </>
  );
}