// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-btn--Wvv79{display:flex;flex-direction:column;align-items:baseline;font-size:1rem;border-radius:0.5rem;text-align:left}.dropdown-btn--Wvv79:hover{cursor:pointer;background:var(--admin-hover-background-interactive-elements)}.dropdown-btn--Wvv79 .job-title--k98aE{font-size:0.75rem;color:var(--secondary-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/BUnitAddHowToInput/BUnitHowToSearchDropdown/AddHowToSearchDropdownBtn/AddHowToSearchDropdownBtn.module.sass"],"names":[],"mappings":"AAEA,qBACE,YAAA,CACA,qBAAA,CACA,oBAAA,CACA,cAAA,CACA,oBAAA,CACA,eAAA,CACA,2BACE,cAAA,CACA,6DAAA,CACF,uCACE,iBAAA,CACA,4BAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.dropdown-btn\n  display: flex\n  flex-direction: column\n  align-items: baseline\n  font-size: func.rem(16)\n  border-radius: func.rem(8)\n  text-align: left\n  &:hover\n    cursor: pointer\n    background: var(--admin-hover-background-interactive-elements)\n  .job-title\n    font-size: func.rem(12)\n    color: var(--secondary-color)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-btn": "dropdown-btn--Wvv79",
	"job-title": "job-title--k98aE"
};
export default ___CSS_LOADER_EXPORT___;
