import React from 'react';
import styles from './AdminMainListLinkDateContent.module.sass';
import { getClearClassNames } from '@/helpers/helpers';
import LinkDates from '../types';

interface AdminMainListLinkDateContentProps {
  dates?: LinkDates;
}

function AdminMainListLinkDateContent({
  dates,
}: AdminMainListLinkDateContentProps) {
  if (!dates?.length) return null;

  return (
    <div className={styles['dates-container']}>
      {dates
        .filter(([,date]) => !!date)
        .map(([title, date]) => (
          <React.Fragment
            key={date}
          >
            {title && <span className={styles.title}>{title}</span>}
            <span className={getClearClassNames([
              !title && styles['without-title'],
            ])}
            >
              {date}
            </span>
          </React.Fragment>
        ))}
    </div>
  );
}

export default AdminMainListLinkDateContent;