// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-bunit-modal--W1OsQ{overflow:visible !important;overflow:initial !important;max-height:none !important;max-height:initial !important}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/CreateBUnitModal.module.sass"],"names":[],"mappings":"AAAA,2BACE,2BAAA,CAAA,2BAAA,CACA,0BAAA,CAAA,6BAAA","sourcesContent":[".create-bunit-modal\n  overflow: unset !important\n  max-height: unset !important\n\n//.modal-buttons\n//  margin-left: 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create-bunit-modal": "create-bunit-modal--W1OsQ"
};
export default ___CSS_LOADER_EXPORT___;
