// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".widgets-container--RoHHE{display:flex;margin-left:auto}@media screen and (max-width: 768px){.widgets-container--RoHHE{gap:1.5rem}}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/Header/HeaderContainer/HeaderWidgetsContainer/HeaderWidgetsContainer.module.sass"],"names":[],"mappings":"AAEA,0BACE,YAAA,CACA,gBAAA,CACA,qCAHF,0BAII,UAAA,CAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.widgets-container\n  display: flex\n  margin-left: auto\n  @media screen and (max-width: 768px)\n    gap: func.rem(24)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgets-container": "widgets-container--RoHHE"
};
export default ___CSS_LOADER_EXPORT___;
