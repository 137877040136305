// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".circle-progress--G9gt\\+{height:100%;width:100%}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/SuperUploading/FilesForUploadList/FileForUpload/FileForUploadCover/CircleProgress/CircleProgress.module.sass"],"names":[],"mappings":"AAEA,yBACE,WAAA,CACA,UAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.circle-progress\n  height: 100%\n  width: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle-progress": "circle-progress--G9gt+"
};
export default ___CSS_LOADER_EXPORT___;
