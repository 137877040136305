// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dates-container--3P9UD{display:grid;grid-template-columns:max-content auto;grid-gap:0.75rem 0.5rem}.dates-container--3P9UD .title--j7w-v{text-align:right}.dates-container--3P9UD .without-title--mU3zW{grid-columns:1/-1}.dates-container--3P9UD span{font-size:0.875rem;color:var(--secondary-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/AdminMainListLinkLink/AdminMainListDateContent/AdminMainListLinkDateContent.module.sass"],"names":[],"mappings":"AAEA,wBACE,YAAA,CACA,sCAAA,CACA,uBAAA,CACA,sCACE,gBAAA,CACF,8CACE,iBAAA,CACF,6BACE,kBAAA,CACA,4BAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.dates-container\n  display: grid\n  grid-template-columns: max-content auto\n  grid-gap: func.rem(12) func.rem(8)\n  .title\n    text-align: right\n  .without-title\n    grid-columns: 1 / -1\n  span\n    font-size: func.rem(14)\n    color: var(--secondary-color)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dates-container": "dates-container--3P9UD",
	"title": "title--j7w-v",
	"without-title": "without-title--mU3zW"
};
export default ___CSS_LOADER_EXPORT___;
