// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".superset-stage--pd57-{font-size:0.875rem;color:var(--admin-main-font-color);margin-top:0.7142857143em;margin-left:2.1428571429em}.superset-stage--pd57- .selected-stage--ukDqA{color:var(--font-active-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/FilteredSupersetsList/SupersetStageInfo/SupersetStageInfo.module.sass"],"names":[],"mappings":"AAEA,uBACE,kBAAA,CACA,kCAAA,CACA,yBAAA,CACA,0BAAA,CACA,8CACE,8BAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.superset-stage\n  font-size: func.rem(14)\n  color: var(--admin-main-font-color)\n  margin-top: func.em(10, 14)\n  margin-left: func.em(30, 14)\n  .selected-stage\n    color: var(--font-active-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"superset-stage": "superset-stage--pd57-",
	"selected-stage": "selected-stage--ukDqA"
};
export default ___CSS_LOADER_EXPORT___;
