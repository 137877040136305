// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".how-to-card-list--AY9ax{display:grid;grid-template-columns:1fr 6fr 4fr 1fr;align-items:center;width:100%;grid-gap:1rem 1rem}.how-to-card-list--AY9ax .separator--iT6PY{width:100%;border-bottom:1px solid var(--secondary-color);grid-column:1/-1}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitRolesList.module.sass"],"names":[],"mappings":"AAEA,yBACE,YAAA,CACA,qCAAA,CACA,kBAAA,CACA,UAAA,CACA,kBAAA,CACA,2CACE,UAAA,CACA,8CAAA,CACA,gBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.how-to-card-list\n  display: grid\n  grid-template-columns: 1fr 6fr 4fr 1fr\n  align-items: center\n  width: 100%\n  grid-gap: func.rem(16) func.rem(16)\n  .separator\n    width: 100%\n    border-bottom: 1px solid var(--secondary-color)\n    grid-column: 1 / -1\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"how-to-card-list": "how-to-card-list--AY9ax",
	"separator": "separator--iT6PY"
};
export default ___CSS_LOADER_EXPORT___;
