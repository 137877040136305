// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-field-uploading-resources--3gNo8{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/UpploadResourcesForm/UploadResourcesInput/UploadResourcesInput.module.sass"],"names":[],"mappings":"AAAA,uCACE,YAAA,CACA,sBAAA","sourcesContent":[".wrap-field-uploading-resources\n  display: flex\n  justify-content: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-field-uploading-resources": "wrap-field-uploading-resources--3gNo8"
};
export default ___CSS_LOADER_EXPORT___;
