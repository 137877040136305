import React from 'react';
import Articles from '../../Super/Articles/Articles';
import Cloud from '../../Super/Cloud/Cloud';
import Users from '../../Super/Users/Users';
import Webinars from './Webinars';
import UsersListPage from './UsersListPage';
import SuperAdminSupersets from '../ListSupersets/SuperAdminSupersets/SuperAdminSupersets';

const superAdminRoutes = [
  {
    url: '/super/admlist', path: 'admlist', name: 'Список адмиинистраторов', element: <UsersListPage />, isHidden: false,
  },
  {
    url: '/super/users', path: 'users', name: 'Пользователи', element: <Users />, isHidden: false,
  },
  {
    url: '/super/cloud', path: 'cloud', name: 'Облако', element: <Cloud />, isHidden: false,
  },
  {
    url: '/super/webinars', path: 'webinars/*', name: 'Вебинары', element: <Webinars />, isHidden: false,
  },
  {
    url: '/super/articles', path: 'articles', name: 'Статьи', element: <Articles />, isHidden: false,
  },
  {
    urL: '/super/projects', path: 'projects', name: 'Cписок проектов', element: <SuperAdminSupersets />, isHidden: true,
  },
];

export default superAdminRoutes;