import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './FloatingContainer.module.sass';

function FloatingContainer({
  children,
  checkFloating,
  notFixedClassNames = styles['floating-container'],
  fixedClassNames = styles.floating,
  isCalcPosition = false,
  defaultPositions = {
    right: '0px',
    top: '0px',
    left: 'unset',
    bottom: 'unset',
  },
}) {
  const floatingRef = useRef(null);
  const [isFloating, toggleFloating] = useState(false);
  const [floatingStyles, setFloatingStyles] = useState({
    top: '0px',
    left: '0px',
  });

  useEffect(() => {
    const handleScroll = () => {
      if (!floatingRef.current) return;
      if (checkFloating() && !isFloating) {
        if (isCalcPosition) {
          const { top, left } = floatingRef.current.getBoundingClientRect();
          setFloatingStyles({ top: `${top}px`, left: `${left}px` });
        }
        toggleFloating(true);
      } else if (!checkFloating() && isFloating) {
        toggleFloating(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [checkFloating, defaultPositions, isCalcPosition, isFloating]);

  return (
    <div
      ref={floatingRef}
      style={
        isFloating
          ? {
            top: floatingStyles.top,
            left: floatingStyles.left,
            right: 'unset',
            bottom: 'unset',
          }
          : defaultPositions
      }
      className={`${notFixedClassNames} ${isFloating && fixedClassNames}`}
    >
      {children}
    </div>
  );
}

FloatingContainer.propTypes = {
  children: PropTypes.element.isRequired,
  checkFloating: PropTypes.func.isRequired,
  fixedClassNames: PropTypes.string,
  notFixedClassNames: PropTypes.string,
  isCalcPosition: PropTypes.bool,
  defaultPositions: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
    bottom: PropTypes.string,
  }),
};

export default FloatingContainer;