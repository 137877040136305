import React, { useMemo, useState } from 'react';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import CreateBUnitModal from '@/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/CreateBUnitModal';
import FloatingContainer from '@/components/App/Admin/BUnitsSection/FloatingContainer/FloatingContainer';
import styles from './CreateBUnitSection.module.sass';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';

interface CreateBUnitSectionProps {
  containerRef?: React.RefObject<HTMLDivElement>;
}

function CreateBUnitSection({ containerRef = null }: CreateBUnitSectionProps) {
  const [isShowingModal, toggleModal] = useState(false);
  const checkPermission = useCheckPermission();

  const header = useMemo(
    () => document.querySelector('.main-header-container'),
    [],
  );

  const checkFloating = () => {
    if (!containerRef) return false;
    if (!containerRef.current) return false;
    if (!header) return false;
    const { bottom: headerBottom } = header.getBoundingClientRect();
    const { top } = containerRef.current.getBoundingClientRect();
    return top <= headerBottom + 20;
  };

  if (!checkPermission('create_units')) return null;

  return (
    <>
      <CreateBUnitModal
        isShowingModal={isShowingModal}
        toggleModal={toggleModal}
      />
      <FloatingContainer
        checkFloating={checkFloating}
        notFixedClassNames={styles['create-unit-button']}
        fixedClassNames={styles.floating}
        isCalcPosition
      >
        <BUnitsMenuBtn
          handleButtonClick={() => toggleModal(true)}
          additionalClassNames={styles['open-modal-button']}
        >
          +
        </BUnitsMenuBtn>
      </FloatingContainer>
    </>
  );
}

export default CreateBUnitSection;