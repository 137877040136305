// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select-btn--obAKr{display:flex;width:max-content;position:relative;gap:0.5rem;padding:0.25rem 0.5rem;text-align:left;font-size:1rem;border-radius:0.5rem}.select-btn--obAKr:hover{background:var(--admin-hover-background-interactive-elements)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/CardSelectDropdown/CardSelectDropdownButton/CardSelectDropdownButton.module.sass"],"names":[],"mappings":"AAEA,mBACE,YAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAAA,CACA,sBAAA,CACA,eAAA,CACA,cAAA,CACA,oBAAA,CACA,yBACE,6DAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.select-btn\n  display: flex\n  width: max-content\n  position: relative\n  gap: func.rem(8)\n  padding: func.rem(4) func.rem(8)\n  text-align: left\n  font-size: func.rem(16)\n  border-radius: func.rem(8)\n  &:hover\n    background: var(--admin-hover-background-interactive-elements)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-btn": "select-btn--obAKr"
};
export default ___CSS_LOADER_EXPORT___;
