// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".webinar-label--lwz4e{display:flex;flex-direction:column;gap:0.375rem}.webinar-label--lwz4e .title--yFWDR{font-size:1.125rem}.input-container--WiMxj{border:1px solid var(--secondary-color);border-radius:0.5rem;padding:0.5rem 0.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/WebinarInputContainer/WebinarInputContainer.module.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,oCACE,kBAAA,CAEJ,wBACE,uCAAA,CACA,oBAAA,CACA,sBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.webinar-label\n  display: flex\n  flex-direction: column\n  gap: func.rem(6)\n  .title\n    font-size: func.rem(18)\n\n.input-container\n  border: 1px solid var(--secondary-color)\n  border-radius: func.rem(8)\n  padding: func.rem(8) func.rem(4)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"webinar-label": "webinar-label--lwz4e",
	"title": "title--yFWDR",
	"input-container": "input-container--WiMxj"
};
export default ___CSS_LOADER_EXPORT___;
