// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-list-item--AUu8d.current-value--FlvFT{background:var(--admin-btn-hover)}.dropdown-list-item--AUu8d.current-value--FlvFT .dropdown-button--c12uP{color:var(--admin-btn-hover-color)}.dropdown-list-item--AUu8d:hover{cursor:pointer;background:var(--admin-btn-hover);color:var(--admin-btn-hover-color)}.dropdown-list-item--AUu8d:not(:first-child){border-top:.5px solid var(--return-code-input-border)}.dropdown-list-item--AUu8d:last-child{border-radius:0 0 8px 8px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/CustomSelect/CustomSelectOption/CustomSelectOption.module.sass"],"names":[],"mappings":"AAGE,gDACE,iCAAA,CACA,wEACE,kCAAA,CACJ,iCACE,cAAA,CACA,iCAAA,CACA,kCAAA,CACF,6CACE,qDAAA,CACF,sCACE,yBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.dropdown-list-item\n  &.current-value\n    background: var(--admin-btn-hover )\n    .dropdown-button\n      color: var(--admin-btn-hover-color)\n  &:hover\n    cursor: pointer\n    background: var(--admin-btn-hover )\n    color: var(--admin-btn-hover-color)\n  &:not(:first-child)\n    border-top: 0.5px solid var(--return-code-input-border)\n  &:last-child\n    border-radius: 0 0 8px 8px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-list-item": "dropdown-list-item--AUu8d",
	"current-value": "current-value--FlvFT",
	"dropdown-button": "dropdown-button--c12uP"
};
export default ___CSS_LOADER_EXPORT___;
