import React, { useState } from 'react';
import styles from './SelectMenu.module.sass';
import SelectMenuTitle from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/SelectMenuTitle/SelectMenuTitle';
import BUnitsSelectMenuSelectors from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitSelectMenuOptionsSelectors/BUnitsSelectMenuSelectors';
import BUnitsSelectMenuConfirmButton from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/BUnitsSelectMenuConfirmButton/BUnitsSelectMenuConfirmButton';
import BUnitActionMenu from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitActionMenu';
import useCheckPermission from '@/components/App/Admin/hooks/checkPermission';
import useSelectAction from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectAction';
import useSelectOption from '@/components/App/Admin/BUnitsSection/UnitsTree/SelectMenu/useSelectOption';

interface SelectMenuProps {
  isTreeLoaded: boolean;
}
export default function SelectMenu({ isTreeLoaded }: SelectMenuProps) {
  const [currentSelectAction, setCurrentSelectAction] = useSelectAction();
  const [currentSelectOption, setCurrentSelectOption] = useSelectOption();
  const [currentSelectValue, setCurrentSelectValue] = useState('');

  const checkPermission = useCheckPermission();

  if (!isTreeLoaded || (!checkPermission('modify_units') && !checkPermission('group_operations'))) return null;

  return (
    <section className={styles['tree-actions']}>
      <div className={styles['select-menu-section']}>
        <SelectMenuTitle />
        <div className={styles['buttons-block']}>
          <div className={styles['select-block']}>
            <BUnitsSelectMenuSelectors
              setCurrentSelectOption={setCurrentSelectOption}
              setCurrentSelectAction={setCurrentSelectAction}
              currentSelectAction={currentSelectAction}
              currentSelectOption={currentSelectOption}
              currentSelectValue={currentSelectValue}
              setCurrentSelectValue={setCurrentSelectValue}
            />
          </div>
          <BUnitsSelectMenuConfirmButton
            currentSelectAction={currentSelectAction}
            currentSelectOption={currentSelectOption}
            selectValue={currentSelectValue}
          />
        </div>
      </div>
      {checkPermission('group_operations') && <BUnitActionMenu />}
    </section>
  );
}