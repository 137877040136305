import {
  ItemRelations,
  Protocol,
  Protocols, ProtocolsTime, RelationComments, Relations, RelationsTime, SentProtocol,
} from '@/store/types/user/protocols';
import { addAnswersFromLocal } from './protocolsLocalStorage';
import { ParseProtocolsIntoArrayProps, DataType } from './types';

const addRelation = (protocol: SentProtocol, relations: Relations | RelationComments) => {
  const {
    itemId, answer, unitId,
  } = protocol;
  if (!(itemId in relations)) relations[itemId] = {};

  relations[itemId][unitId as string] = answer;
};

type ParseRelationFuncType = (
  protocol: SentProtocol,
  relations: Relations,
  relationsComments: RelationComments,
  degreeItems: string[],
) => void;

const parseRelation: ParseRelationFuncType = (protocol, relations, relationsComments) => {
  const { isComment } = protocol;

  if (isComment) {
    addRelation(protocol, relationsComments);
    return;
  }

  addRelation(protocol, relations);
};

const parseRelationsInArray = (relations: Relations | RelationComments, relationsTime: RelationsTime, isComments: boolean): SentProtocol[] => {
  const parseRelations = (acc: SentProtocol[], item: [string, ItemRelations]) => {
    const [itemId, itemRelations] = item;
    const itemRelationsArr: SentProtocol[] = Object.entries(itemRelations).reduce((rels: SentProtocol[], [unitId, answer]) => {
      const time = relationsTime[itemId][unitId];
      return [...rels, {
        itemId, unitId, answer, time, isComment: isComments,
      }];
    }, []);

    return [...acc, ...itemRelationsArr];
  };

  return Object.entries(relations).reduce((acc: SentProtocol[], value) => parseRelations(acc, value), []);
};

const parseProtocolsInArray = (protocols: Protocols, protocolsTime: ProtocolsTime): SentProtocol[] => {
  const parseProtocols = (acc: SentProtocol[], [itemId, answer]: [string, Protocol]) => {
    const time = protocolsTime[itemId];
    return [...acc, { itemId, answer, time }];
  };

  return Object.entries(protocols).reduce(parseProtocols, []);
};

export const parseUpdatedAnswersToArray = ({ ...state }: ParseProtocolsIntoArrayProps): SentProtocol[] => {
  const {
    updatedItems, updatedRelations, updatedRelationsComments, relationsTime, protocolsTime, commentsTime,
  } = state;

  const protocolsArray: SentProtocol[] = parseProtocolsInArray(updatedItems, protocolsTime);

  const relationsArray: SentProtocol[] = parseRelationsInArray(updatedRelations, relationsTime, false);

  const commentsArray: SentProtocol[] = parseRelationsInArray(updatedRelationsComments, commentsTime, true);

  return [...protocolsArray, ...relationsArray, ...commentsArray];
};

const parceProtocolsArray = (protocolsPayload: SentProtocol[], degreeItemsByPagesFromState: DegreeItemsByPages, actorId: string, projectId: string): DataType => {
  const protocols: Protocols = {};
  const relations: Relations = {};
  const relationsComments: RelationComments = {};

  const { filteredProtocols, ...localAnswers } = addAnswersFromLocal({ actorId, projectId, protocolsArray: protocolsPayload });

  const parseDegreeItems = (acc: string[], pageKey: string): string[] => [...acc, ...Object.keys(degreeItemsByPagesFromState[pageKey])];

  const degreeItems: string[] = Object.keys(degreeItemsByPagesFromState).reduce(parseDegreeItems, []);

  filteredProtocols.forEach((protocol) => {
    const {
      unitId, itemId, answer,
    } = protocol;
    if (!unitId) {
      protocols[itemId] = answer;
      return;
    }

    parseRelation(protocol, relations, relationsComments, degreeItems);
  });

  if (!localAnswers) return { protocols, relations, relationsComments };

  const {
    updatedItems, updatedRelations, updatedRelationsComments,
  } = localAnswers;

  Object.entries(updatedRelations).forEach(([itemId, itemRelation]) => {
    relations[itemId] = { ...relations[itemId], ...itemRelation };
  });

  Object.entries(updatedRelationsComments).forEach(([itemId, relationComments]) => {
    relationsComments[itemId] = { ...relationsComments[itemId], ...relationComments };
  });

  return {
    protocols: { ...protocols, ...updatedItems },
    relations,
    relationsComments,
    ...localAnswers,
  };
};

export default parceProtocolsArray;