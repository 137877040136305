import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SupersetSettings from './SupersetSettings';
import MainContentContainer from '@/components/Common/BaseTemplate/MainContentContainer';
import Superset from '@/components/App/Admin/Superset';
import LoadSuperset from '@/components/App/Admin/LoadSuperset';
import AdminProfile from '@/components/App/Admin/AdminProfile';
import Results from '@/components/App/Admin/Results';
import LogInfo from '@/components/App/Admin/LogInfo';
import BUnitsSection from '@/components/App/Admin/BUnitsSection/BUnitsSection';
import AddingQuestions from '@/components/App/Admin/AddingQuestions';
import Redirect from '@/components/App/Redirect/Redirect';
import FillEmptyItemsSection from '@/components/App/Admin/ItemsPlaseholder/FillEmptyItemsSection';
import InitSuperset from '@/components/App/Admin/InitSuperset';
import RedirectAdminWithoutPermission from './RedirectWithoutPermission/RedirectWithoutPermission';
import { MainContentContainerTypes } from '@/components/Common/BaseTemplate/MainContentContainer/MainContentContainer';
import AdminSupersets from './ListSupersets/AdminSupersets/AdminSupersets';

function Admin() {
  return (
    <Routes>
      <Route path="" element={<Redirect path="projects" />} />
      <Route path="logInfo" element={<MainContentContainer />}>
        <Route index element={<LogInfo />} />
      </Route>
      <Route
        path="projects"
        element={(
          <AdminSupersets />
        )}
      />

      <Route
        path="project/:id/*"
        element={(
          <LoadSuperset>
            <InitSuperset>
              <MainContentContainer typeContainer={MainContentContainerTypes.Admin} />
            </InitSuperset>
          </LoadSuperset>
        )}
      >
        <Route path="" element={<Superset />} />
        <Route path="projectSettings/*" element={<SupersetSettings />} />
        <Route path="addingQuestions/*" element={<AddingQuestions />} />
        <Route
          path="unitsTree/*"
          element={(
            <RedirectAdminWithoutPermission
              ruleKey="view_tree"
            >
              <BUnitsSection />
            </RedirectAdminWithoutPermission>
          )}
        />
        <Route
          path="results/*"
          element={(
            <RedirectAdminWithoutPermission
              ruleKey="view_results"
            >
              <Results />
            </RedirectAdminWithoutPermission>
          )}
        />
        <Route path="fillEmptyItems" element={<FillEmptyItemsSection />} />
      </Route>
      <Route path="profile" element={<AdminProfile />} />
    </Routes>
  );
}

export default Admin;