// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spec--BqSi8{position:relative;width:fit-content;border-radius:0.5rem}.spec--BqSi8 .spec-tag--17rAM{position:absolute;font-size:0.375rem;border-radius:3.125rem;border:1px solid;top:-6px;left:-8px;color:#3ccc76;background:var(--admin-hover-background-interactive-elements);padding:0.0625rem 0.125rem}.spec--BqSi8 .remove-spec-btn--jA9gi{padding:0;border-radius:100%;background:var(--main-bg);position:absolute;right:-4px;top:-6px}.spec--BqSi8 .remove-spec-btn--jA9gi:hover{cursor:pointer;background:var(--admin-hover-background-interactive-elements)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/BUnitSpec.module.sass"],"names":[],"mappings":"AAEA,aACE,iBAAA,CACA,iBAAA,CACA,oBAAA,CACA,8BACE,iBAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,QAAA,CACA,SAAA,CACA,aAAA,CACA,6DAAA,CACA,0BAAA,CACF,qCACE,SAAA,CACA,kBAAA,CACA,yBAAA,CACA,iBAAA,CACA,UAAA,CACA,QAAA,CACA,2CACE,cAAA,CACA,6DAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.spec\n  position: relative\n  width: fit-content\n  border-radius: func.rem(8)\n  .spec-tag\n    position: absolute\n    font-size: func.rem(6)\n    border-radius: func.rem(50)\n    border: 1px solid\n    top: -6px\n    left: -8px\n    color: #3CCC76\n    background: var(--admin-hover-background-interactive-elements)\n    padding: func.rem(1) func.rem(2)\n  .remove-spec-btn\n    padding: 0\n    border-radius: 100%\n    background: var(--main-bg)\n    position: absolute\n    right: -4px\n    top: -6px\n    &:hover\n      cursor: pointer\n      background: var(--admin-hover-background-interactive-elements)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spec": "spec--BqSi8",
	"spec-tag": "spec-tag--17rAM",
	"remove-spec-btn": "remove-spec-btn--jA9gi"
};
export default ___CSS_LOADER_EXPORT___;
