// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-header-results--388xY .wrap-control-results-block--kE6pe{display:flex;gap:1.25rem;margin-bottom:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/HeaderResults/HeaderCuts.module.sass"],"names":[],"mappings":"AAGE,+DACE,YAAA,CACA,WAAA,CACA,qBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.wrap-header-results\n  .wrap-control-results-block\n    display: flex\n    gap: func.rem(20)\n    margin-bottom: func.rem(20)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-header-results": "wrap-header-results--388xY",
	"wrap-control-results-block": "wrap-control-results-block--kE6pe"
};
export default ___CSS_LOADER_EXPORT___;
