// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-option-label--t-MFU{display:flex;flex-direction:column;gap:0.625rem}.wrap-option-label--t-MFU .description--iYiYr{color:#85878e;font-size:0.875rem}.modal-content--7vbEg{margin:0 0 1.5rem 0}.modal-content--7vbEg section{padding:1rem 0}.modal-content--7vbEg section:not(:first-child){border-top:solid 1px var(--secondary-color)}.select-specs--UKH9n{display:flex;gap:0.75rem;color:var(--main-color)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/LoadResults/CalculateResults/CalculateResultsModal/CalculateResultsModalContent/CalculateResultsModalContent.module.sass"],"names":[],"mappings":"AAEA,0BACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,8CACE,aAAA,CACA,kBAAA,CAEJ,sBACE,mBAAA,CACA,8BACE,cAAA,CACA,gDACE,2CAAA,CAEN,qBACE,YAAA,CACA,WAAA,CACA,uBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrap-option-label\n  display: flex\n  flex-direction: column\n  gap: func.rem(10)\n  .description\n    color: #85878E\n    font-size: func.rem(14)\n\n.modal-content\n  margin: 0 0 func.rem(24) 0\n  section\n    padding: func.rem(16) 0\n    &:not(:first-child)\n      border-top: solid 1px var(--secondary-color)\n\n.select-specs\n  display: flex\n  gap: func.rem(12)\n  color: var(--main-color)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-option-label": "wrap-option-label--t-MFU",
	"description": "description--iYiYr",
	"modal-content": "modal-content--7vbEg",
	"select-specs": "select-specs--UKH9n"
};
export default ___CSS_LOADER_EXPORT___;
