// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox-label--02Z3\\+{padding:0 0.5rem 0 0}.checkbox-label--02Z3\\+ .unit-checkbox--f-bkc{position:relative;z-index:1}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/EmployeeUnit/EmployeeUnitFields/BUnitCheckBox/BUnitCheckBox.module.sass"],"names":[],"mappings":"AAEA,wBACE,oBAAA,CACA,8CACE,iBAAA,CACA,SAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.checkbox-label\n  padding: 0 func.rem(8) 0 0\n  .unit-checkbox\n    position: relative\n    z-index: 1\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-label": "checkbox-label--02Z3+",
	"unit-checkbox": "unit-checkbox--f-bkc"
};
export default ___CSS_LOADER_EXPORT___;
