import React from 'react';
import { useSelector } from 'react-redux';
import styles from './HeaderCuts.module.sass';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import ResultsControlBlock from '@/components/App/Admin/Results/Cuts/HeaderResults/ResultsControlBlock';

interface DataHeaderResults {
  resultsNotCalculatedYet?: boolean;
}

function HeaderResults({ resultsNotCalculatedYet }: DataHeaderResults) {
  const { name: supersetName } = useSelector(selectSuperset);

  return (
    <div className={styles['wrap-header-results']}>

      <ResultsControlBlock resultsNotCalculatedYet={resultsNotCalculatedYet} />

      <h2>{supersetName}</h2>
    </div>
  );
}

export default HeaderResults;