import React from 'react';
import styles from './BUnitsMenuBtn.module.sass';
import Preloader from '../../../SupersetSettings/Preloader';
import { getClearClassNames } from '@/helpers/helpers';

interface BUnitsMenuBtnProps {
  handleButtonClick?: () => void;
  children: string | React.ReactNode;
  additionalClassNames?: string;
  type?: 'confirm' | 'reject';
  isActive?: boolean;
  btnActionType?: HTMLButtonElement['type'];
  formId?: string;
  isPreloader?: boolean;
}

function BUnitsMenuBtn({
  handleButtonClick = () => null,
  children,
  additionalClassNames = '',
  type = 'confirm',
  isActive = true,
  btnActionType = 'button',
  formId = '',
  isPreloader = false,
}: BUnitsMenuBtnProps) {
  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={btnActionType}
      className={getClearClassNames([
        styles['units-menu-btn'],
        styles[`${type}`],
        !isActive && styles['not-active'],
        additionalClassNames,
      ])}
      disabled={!isActive}
      onClick={() => !isPreloader && handleButtonClick()}
      {...(formId ? { form: formId } : {})} // Добавление атрибута form только если formId задан
    >
      {isPreloader && (
      <div className={styles['preloader-container']}>
        <Preloader externalClass={styles.preloader} />
      </div>
      )}
      <div className={getClearClassNames([
        styles['content-container'],
        isPreloader && styles.hidden,
      ])}
      >
        {children}
      </div>
    </button>
  );
}

export default BUnitsMenuBtn;