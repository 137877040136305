import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import {
  selectActorProfile,
  selectActorProject,
} from '@/store/selectors/user/actor';
import ResultLink from '@/components/App/User/Common/ResultLink/ResultLink';
import { removeTextInsideTags } from '@/helpers/helpers';
import Code from '@/components/App/User/Common/Code/Code';
import { usePlayerSelector } from '@/store/hooks/playerRedux';
import { ActorProfile } from '@/store/types/user/actor';

interface MacrosProps {
  children: string;
}

interface MacroParams {
  [key: string]: string;
}

interface ElementsByMacros {
  [key: string]: React.ReactNode;
}

export default function Macros({ children }: MacrosProps) {
  const macrosPattern = /({[^}]+})/g;

  const actorProfile = usePlayerSelector(selectActorProfile) as ActorProfile;
  const project = usePlayerSelector(selectActorProject);
  const { teamCode } = useParams();

  const macrosParams: MacroParams = useMemo(
    () => ({
      PROJECTTITLE: project?.title || '',
      PROJECTNAME: project?.name || '',
      COMPANY: actorProfile?.company || '',
      NAME: actorProfile?.name || '',
      LINK: `${window.location.origin}/go/${teamCode}`,
    }),
    [project, actorProfile, teamCode],
  );
  // todo Сделать генерацию ключа
  const elementsByMacros: ElementsByMacros = useMemo(
    () => ({
      CODE: <Code key={nanoid()} />,
      RESULT: <ResultLink key={nanoid()} />,
    }),
    [],
  );

  const changeActorInvite = (p: string) => {
    const actorInvite = JSON.parse(localStorage.getItem('actorInvite'));
    if (!actorInvite) return p;
    let text = p;
    Object.keys(actorInvite).forEach((macros) => {
      if (!text.includes(`{${macros}}`)) return;
      const InviteCodeParts = text.split(`{${macros}}`);
      const theme = localStorage.getItem('theme');
      const isLight = theme === 'light' ? 1 : 0;
      const link = `${actorInvite[macros]}?theme=${isLight}`;

      text = `${InviteCodeParts[0]}${link}${InviteCodeParts[1]}`;
    });
    return text;
  };

  const extractStyles = (str: string): [string, string] => {
    const styleRegex = /<style>([\s\S]*?)<\/style>/gs;
    const styles: string[] = [];
    const noStylesStr = str.replace(styleRegex, (match, content) => {
      styles.push(`<style>${content.replace(/’/g, "'")}</style>`);
      return '';
    });
    const allStyles = `${styles.join('').replace(/\n/g, '')}`;
    return [noStylesStr, allStyles];
  };

  const changeMacros = (part: string) => {
    const macro = part.replace(/[{}]/g, '');
    if (macro.includes(':')) {
      const replaceCase = macro.split(':');
      return project?.settings?.[replaceCase[0]]?.substitutions?.[replaceCase[1]] || part;
    }
    if (macro in macrosParams) return macrosParams[macro];
    return elementsByMacros[macro] || part;
  };

  try {
    const [textWithoutStyles, textStyles = ''] = extractStyles(children);
    let replacedText: string = removeTextInsideTags(textWithoutStyles);
    replacedText = changeActorInvite(replacedText);
    const replacedElements = replacedText.split(macrosPattern).map((part) => changeMacros(part));

    const mergedElements = replacedElements.reduce<React.ReactNode[]>((acc, current) => {
      const lastElement = acc[acc.length - 1];

      if (typeof current === 'string' && typeof lastElement === 'string') {
        acc[acc.length - 1] = lastElement + current; // Объединяем строки
      } else {
        acc.push(current); // Добавляем React-элемент или новую строку
      }

      return acc;
    }, []);

    return [parse(textStyles), ...mergedElements.map((part) => (typeof part === 'string' ? parse(part) : part))];
  } catch (e) {
    return parse(removeTextInsideTags(children));
  }
}