import React from 'react';
import PropTypes from 'prop-types';
import Criteria from '@/components/App/Admin/Results/Cuts/WrapperCuts/Criteria';
import TabsItems from '@/components/App/Admin/Results/Cuts/TabsItems';

// General parts for each type of cuts
function WrapperCuts({ children: cutsPage }) {
  return (
    <>
      <Criteria />
      <TabsItems />
      {cutsPage}
    </>
  );
}

WrapperCuts.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WrapperCuts;