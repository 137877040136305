// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-fs-switcher--ZprCV{margin-bottom:1.25rem}.wrap-fs-switcher--ZprCV .fs-switcher-container--kYaN4{width:500px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FactorsCuts/FSswitcher/FSswitcher.module.sass"],"names":[],"mappings":"AAEA,yBACE,qBAAA,CACA,uDACE,WAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrap-fs-switcher\n  margin-bottom: func.rem(20)\n  .fs-switcher-container\n    width: 500px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-fs-switcher": "wrap-fs-switcher--ZprCV",
	"fs-switcher-container": "fs-switcher-container--kYaN4"
};
export default ___CSS_LOADER_EXPORT___;
