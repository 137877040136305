// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-content-list-fields--u5oF1 .form-fields--NAcGg{display:flex;flex-wrap:wrap;gap:0.625rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionFieldActivity/ModalSelectingFields/ModalSelectingFields.module.sass"],"names":[],"mappings":"AAGE,sDACE,YAAA,CACA,cAAA,CACA,YAAA","sourcesContent":["@use \"../../../../../../styles/functions\" as func\n\n.modal-content-list-fields\n  .form-fields\n    display: flex\n    flex-wrap: wrap\n    gap: func.rem(10)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-content-list-fields": "modal-content-list-fields--u5oF1",
	"form-fields": "form-fields--NAcGg"
};
export default ___CSS_LOADER_EXPORT___;
