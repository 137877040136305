import { useEffect, useState } from 'react';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { getSupersetUnits } from '@/store/actions/admin/superset/unitsTree';
import { getCheckpoints } from '@/store/actions/admin/superset/process/process';
import { BackendUnit } from '@/store/types/admin/superset/unitsTree';

type UseLoadCalculateModalResourcesReturn = [boolean, { id: string, title: string, description: string }[], number, BackendUnit[]];
const useLoadCalculateModalResources = (): UseLoadCalculateModalResourcesReturn => {
  const dispatch = useAdminDispatch();
  const { hasTree, supersetId } = useAdminSelector(selectSuperset);
  const [checkpoints, setCheckpoints] = useState< { id: string, title: string, description: string }[] | undefined>(undefined);
  const [unitsArray, setUnitsArray] = useState<BackendUnit[]>([]);
  const [isUnitsLoaded, toggleUnitsLoaded] = useState<boolean>(!hasTree);
  const [isCheckpointsLoaded, toggleCheckpointsLoaded] = useState<boolean>(false);
  const [selectedUnitsCount, setSelectedUnitsCount] = useState(0);

  useEffect(() => {
    if (isUnitsLoaded) return;
    dispatch(getSupersetUnits({ supersetId, flag: 'all' }))
      .then(({ payload }: { payload: BackendUnit[] }) => {
        setUnitsArray(payload);
        setSelectedUnitsCount(payload?.filter((unit) => unit.selected).length || 0);
      })
      .catch(() => {})
      .finally(() => {
        toggleUnitsLoaded(true);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // @ts-ignore
    dispatch(getCheckpoints({ supersetId }))
      .then(({ payload }) => {
        setCheckpoints(payload);
      })
      .catch(() => {})
      .finally(() => {
        toggleCheckpointsLoaded(true);
      });

    // eslint-disable-next-line
  }, []);

  return [isUnitsLoaded && isCheckpointsLoaded, checkpoints, selectedUnitsCount, unitsArray];
};

export default useLoadCalculateModalResources;