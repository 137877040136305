import React, { useMemo } from 'react';
import BUnitsMenuBtn from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnitsBlueBtn/BUnitsMenuBtn';
import useNLS from '@/components/App/Admin/hooks/hooks';
import { selectTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import { useAdminDispatch, useAdminSelector } from '@/store/hooks/adminRedux';
import { BUnitSelectAction } from '../useSelectAction';
import { BUnitSelectOption, UnitsSelectOptionsFlags } from '../useSelectOption';
import LoadingStatus from '@/constants/enums';

interface BUnitSelectMenuConfirmButtonProps {
  currentSelectAction: BUnitSelectAction,
  currentSelectOption: BUnitSelectOption,
  selectValue?: string;
}

function BUnitsSelectMenuConfirmButton({
  currentSelectAction,
  currentSelectOption,
  selectValue = '',
}: BUnitSelectMenuConfirmButtonProps) {
  const tNLS = useNLS();
  const dispatch = useAdminDispatch();
  const { selectOperationProgress } = useAdminSelector((state) => state.admin.superset.unitsTree);

  const isButtonActive = useMemo(() => {
    if (selectValue) return true;
    if (currentSelectOption.key === UnitsSelectOptionsFlags.Spec) return false;
    if (currentSelectOption.key === UnitsSelectOptionsFlags.Score) return false;
    if (currentSelectOption.key === UnitsSelectOptionsFlags.NoScore) return false;
    return true;
  }, [currentSelectOption.key, selectValue]);

  const selectUnits = () => {
    dispatch(
      selectTreeUnits({
        selectValue: currentSelectAction.key,
        flag: currentSelectOption.key,
        value: selectValue,
      }),
    );
  };

  return (
    <BUnitsMenuBtn
      handleButtonClick={selectUnits}
      isActive={isButtonActive}
      isPreloader={selectOperationProgress === LoadingStatus.Loading}
    >
      {tNLS('tree.select.go')}
    </BUnitsMenuBtn>
  );
}

export default BUnitsSelectMenuConfirmButton;