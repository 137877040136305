import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from '@/components/App/Admin/ListSupersets/ListSupersets.module.sass';
import SupersetListLink from '@/components/App/Admin/ListSupersets/FilteredSupersetsList/SupersetListLink/SupersetListLink';
import { Superset } from '@/store/types/admin/supersets';
import { Stage } from '@/types/admin';
import { FilterSupersetsFlag } from '../FilterSupersets/FilterSupersetsTabs';
import { SuperAdminSuperset } from '@/store/types/super/supersets/supersets';

interface FilteredSupersetsListProps {
  filterFlag: FilterSupersetsFlag
  supersets: Superset[] | SuperAdminSuperset[];
}

function FilteredSupersetsList({ filterFlag, supersets }: FilteredSupersetsListProps) {
  const filterSuperset = useCallback(
    (superset: Superset) => {
      const { stage, isTemplate } = superset;
      if (filterFlag === FilterSupersetsFlag.TEMPLATES) {
        return isTemplate;
      }
      if (filterFlag === FilterSupersetsFlag.ARCHIVE) {
        return stage === Stage.ARCHIVE;
      }
      if (filterFlag === FilterSupersetsFlag.ACTIVE) {
        return stage < Stage.ARCHIVE;
      }
      return true;
    },
    [filterFlag],
  );

  const filteredSupersets = useMemo<Superset[]>(() => supersets?.filter(filterSuperset), [supersets, filterSuperset]);

  return (
    <ul className={styles['list-supersets']}>
      {filteredSupersets.map((superset, i) => (
        <SupersetListLink
          key={`list-${superset.id}`}
          index={filteredSupersets.length - i}
          superset={superset}
        />
      ))}
    </ul>
  );
}

FilteredSupersetsList.propTypes = {
  filterFlag: PropTypes.string.isRequired,
};

export default FilteredSupersetsList;