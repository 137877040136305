// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-answer-option-labels--23Q5z{margin:1.25rem 0}.wrap-answer-option-labels--23Q5z .list-of-answer-option-labels--Rlze9{display:flex;width:100%;align-items:center;text-align:center}.wrap-answer-option-labels--23Q5z .list-of-answer-option-labels--Rlze9 .answer-option-label--zBVZO{flex:1 0 0%;font-size:0.875rem;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/FactorsCuts/AbsoluteFS/AnswerOptionLabels/AnswerOptionLabels.module.sass"],"names":[],"mappings":"AAEA,kCACE,gBAAA,CACA,uEACE,YAAA,CACA,UAAA,CACA,kBAAA,CACA,iBAAA,CACA,mGACE,WAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrap-answer-option-labels\n  margin: func.rem(20) 0\n  .list-of-answer-option-labels\n    display: flex\n    width: 100%\n    align-items: center\n    text-align: center\n    .answer-option-label\n      flex: 1 0 0%\n      font-size: func.rem(14)\n      font-weight: bold"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-answer-option-labels": "wrap-answer-option-labels--23Q5z",
	"list-of-answer-option-labels": "list-of-answer-option-labels--Rlze9",
	"answer-option-label": "answer-option-label--zBVZO"
};
export default ___CSS_LOADER_EXPORT___;
