// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-section--n3BLQ{width:100%}.form-section--n3BLQ .section-content--tPxv3{display:flex;justify-content:space-between;gap:3.125rem}.form-section--n3BLQ .fields-container--pTfHW{display:flex;flex-direction:column;width:35%;gap:1.25rem}.form-section--n3BLQ .content-preview--l98-Y{width:65%}", "",{"version":3,"sources":["webpack://./src/components/App/Webinar/CreateWebinarPage/CreateWebinarForm/WebinarContentSection/WebinarContentSection.module.sass"],"names":[],"mappings":"AAEA,qBACE,UAAA,CACA,6CACE,YAAA,CACA,6BAAA,CACA,YAAA,CACF,8CACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,WAAA,CACF,6CACE,SAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.form-section\n  width: 100%\n  .section-content\n    display: flex\n    justify-content: space-between\n    gap: func.rem(50)\n  .fields-container\n    display: flex\n    flex-direction: column\n    width: 35%\n    gap: func.rem(20)\n  .content-preview\n    width: 65%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-section": "form-section--n3BLQ",
	"section-content": "section-content--tPxv3",
	"fields-container": "fields-container--pTfHW",
	"content-preview": "content-preview--l98-Y"
};
export default ___CSS_LOADER_EXPORT___;
