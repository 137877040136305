// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-tabs-items--RBFP2{margin-top:1.25rem}.wrap-tabs-items--RBFP2 .tabs-items--GYION.has-only-one-item--SPu3R .panes-labels--9Mu4i .pane-label--OiYya{border-radius:8px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/TabsItems/TabsItems.module.sass"],"names":[],"mappings":"AAEA,wBACE,kBAAA,CAIM,4GACE,iBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.wrap-tabs-items\n  margin-top: func.rem(20)\n  .tabs-items\n    &.has-only-one-item\n      .panes-labels\n        .pane-label\n          border-radius: 8px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-tabs-items": "wrap-tabs-items--RBFP2",
	"tabs-items": "tabs-items--GYION",
	"has-only-one-item": "has-only-one-item--SPu3R",
	"panes-labels": "panes-labels--9Mu4i",
	"pane-label": "pane-label--OiYya"
};
export default ___CSS_LOADER_EXPORT___;
