// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".svg-circle--AoZVr{stroke-width:0.5rem;stroke-linecap:round;fill:rgba(0,0,0,0);transition:all .1s ease-in-out}.svg-circle--AoZVr.background--XG-n\\+{stroke:#989ba4}.svg-circle--AoZVr.fill--H4PpL{stroke:#fff}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/SuperUploading/FilesForUploadList/FileForUpload/FileForUploadCover/CircleProgress/SvgCircle/SvgCircle.module.sass"],"names":[],"mappings":"AAEA,mBACE,mBAAA,CACA,oBAAA,CACA,kBAAA,CACA,8BAAA,CACA,sCACE,cAAA,CACF,+BACE,WAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.svg-circle\n  stroke-width: func.rem(8)\n  stroke-linecap: round\n  fill: transparent\n  transition: all 0.1s ease-in-out\n  &.background\n    stroke: #989BA4\n  &.fill\n    stroke: #FFF\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg-circle": "svg-circle--AoZVr",
	"background": "background--XG-n+",
	"fill": "fill--H4PpL"
};
export default ___CSS_LOADER_EXPORT___;
