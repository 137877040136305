// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-container--CwTCR{grid-column:2/3;position:relative;display:flex;justify-content:space-between;border:1px solid var(--secondary-color);padding:0.125rem 0rem 0.125rem 0.5rem;border-radius:0.5rem;color:var(--main-color)}.dropdown-container--CwTCR button{font-size:1rem}.dropdown-container--CwTCR .search-input--4hPO7{font-size:1rem;width:100%;outline:none}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitAddHowTo/BUnitAddHowToInput/BUnitAddHowToInput.module.sass"],"names":[],"mappings":"AAEA,2BACE,eAAA,CACA,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,uCAAA,CACA,qCAAA,CACA,oBAAA,CACA,uBAAA,CACA,kCACE,cAAA,CACF,gDACE,cAAA,CACA,UAAA,CACA,YAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.dropdown-container\n  grid-column: 2/3\n  position: relative\n  display: flex\n  justify-content: space-between\n  border: 1px solid var(--secondary-color)\n  padding: func.rem(2) func.rem(0) func.rem(2) func.rem(8)\n  border-radius: func.rem(8)\n  color: var(--main-color)\n  button\n    font-size: func.rem(16)\n  .search-input\n    font-size: func.rem(16)\n    width: 100%\n    outline: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-container": "dropdown-container--CwTCR",
	"search-input": "search-input--4hPO7"
};
export default ___CSS_LOADER_EXPORT___;
