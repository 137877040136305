// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select-container--yYVeo{position:relative}.select-container--yYVeo .role-select--G8\\+yh{display:flex;gap:0.375rem;align-items:baseline;width:fit-content}.select-container--yYVeo .role-select--G8\\+yh .arrow--0V8eh{width:0.375rem;height:0.375rem}.select-container--yYVeo .role-select--G8\\+yh:hover{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardHowTo/BUnitRolesList/BUnitHowToElement/RoleSelect/RoleSelect.module.sass"],"names":[],"mappings":"AAEA,yBACE,iBAAA,CACA,8CACE,YAAA,CACA,YAAA,CACA,oBAAA,CACA,iBAAA,CACA,4DACE,cAAA,CACA,eAAA,CACF,oDACE,cAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.select-container\n  position: relative\n  .role-select\n    display: flex\n    gap: func.rem(6)\n    align-items: baseline\n    width: fit-content\n    .arrow\n      width: func.rem(6)\n      height: func.rem(6)\n    &:hover\n      cursor: pointer\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-container": "select-container--yYVeo",
	"role-select": "role-select--G8+yh",
	"arrow": "arrow--0V8eh"
};
export default ___CSS_LOADER_EXPORT___;
