// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tree-container--1PjwF{overflow-y:hidden;position:relative;margin-top:24px}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/BUnitsSection.module.sass"],"names":[],"mappings":"AAAA,uBACE,iBAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".tree-container\n  overflow-y: hidden\n  position: relative\n  margin-top: 24px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tree-container": "tree-container--1PjwF"
};
export default ___CSS_LOADER_EXPORT___;
