import React, { Dispatch, SetStateAction } from 'react';
import useNLS from '@/components/App/Admin/hooks/hooks';
import RadioButton from '@/components/Common/form/RadioButton';

export enum UnitsFilters {
  WithoutFilter = 'without-filter',
  BySelected = 'useSelected',
  BySpec = 'specs',
}

interface SelectUnitFilterRadioProps {
  id: UnitsFilters;
  selectedFilterId: UnitsFilters;
  setSelectedFilterId: Dispatch<SetStateAction<UnitsFilters>>;
  selectedUnitsCount: number;
  filteredSpecs: string[];
}

const namesById = {
  [UnitsFilters.WithoutFilter]: 'results.calculate.with.all',
  [UnitsFilters.BySelected]: 'results.calculate.with.selected',
  [UnitsFilters.BySpec]: 'results.calculate.with.selected.specs',
};

export default function SelectUnitFilterRadio({
  id, selectedFilterId, setSelectedFilterId, selectedUnitsCount, filteredSpecs,
}: SelectUnitFilterRadioProps) {
  const tNLS = useNLS();

  const checkShowCondition = (): boolean => {
    switch (id) {
      case UnitsFilters.WithoutFilter:
        return (selectedUnitsCount > 0 || !!filteredSpecs.length);
      case UnitsFilters.BySelected:
        return selectedUnitsCount > 0;
      case UnitsFilters.BySpec:
        return !!filteredSpecs.length;
      default:
        return false;
    }
  };

  if (!checkShowCondition()) return null;

  return (
    // @ts-ignore
    <RadioButton
      name={tNLS(namesById[id])}
      id={id}
      label={tNLS(namesById[id])}
      checked={selectedFilterId === id}
      onChange={() => setSelectedFilterId(id)}
    />
  );
}