// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-button--t1ZUZ{width:100%;text-align:left;padding:0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/NewBUnitButton/NewBUnitButton.module.sass"],"names":[],"mappings":"AAAA,oBACE,UAAA,CACA,eAAA,CACA,SAAA","sourcesContent":[".unit-button\n  width: 100%\n  text-align: left\n  padding: 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-button": "unit-button--t1ZUZ"
};
export default ___CSS_LOADER_EXPORT___;
