import React from 'react';
import styles from './BaseWrapper.module.sass';
import Footer from '@/components/Common/BaseTemplate/Footer';
import { getClearClassNames } from '@/helpers/helpers';

interface BaseWrapperProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  isAdmin?: boolean;
}

function BaseWrapper({
  children,
  header,
  isAdmin = false,
}: BaseWrapperProps) {
  const defaultLangId = window.location.host.includes('bssl')
  || window.location.host.includes('azimuth')
    ? 'ru'
    : 'en';

  return (
    <div
      className={getClearClassNames([
        styles.wrapper,
        'wrapper',
        !isAdmin && styles['without-select'],
      ])}
    >
      {header}
      <main className={styles.main}>{children}</main>
      <Footer langId={defaultLangId} />
    </div>
  );
}

export default BaseWrapper;