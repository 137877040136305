// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".action-button--\\+lmXF{position:relative;border:0;padding:0;background:none}.action-button--\\+lmXF.hidden--AzOIR{opacity:0}.action-button--\\+lmXF:hover{cursor:pointer}.action-button--\\+lmXF:hover.hidden--AzOIR{cursor:inherit}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitActionButtons/ActionButton/ActionButton.module.sass"],"names":[],"mappings":"AAEA,uBACE,iBAAA,CACA,QAAA,CACA,SAAA,CACA,eAAA,CACA,qCACE,SAAA,CACF,6BACE,cAAA,CACA,2CACE,cAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.action-button\n  position: relative\n  border: 0\n  padding: 0\n  background: none\n  &.hidden\n    opacity: 0\n  &:hover\n    cursor: pointer\n    &.hidden\n      cursor: unset\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action-button": "action-button--+lmXF",
	"hidden": "hidden--AzOIR"
};
export default ___CSS_LOADER_EXPORT___;
