// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-admin-invite--SagAd .input{margin-bottom:20px}.admins-section--TD7\\+0 .list-admins--La1lr{display:flex;flex-wrap:wrap;gap:0.75rem;align-items:center}.admins-section--TD7\\+0 .msg-no-admins--gSaIS{margin-top:1.25rem;color:var(--admin-main-font-color)}.admin-menu-item--SVzZq{display:flex;align-items:center;gap:0.3125rem}.icon-admin-role--TbtHX{height:0.625rem;width:0.625rem;border-radius:50%}.icon-admin-role--TbtHX.red--Vxt0r{background-color:#eb5757}.icon-admin-role--TbtHX.yellow--lHYRu{background-color:#f2c94c}.icon-admin-role--TbtHX.green--OfgC5{background-color:#219653}.icon-admin-role--TbtHX.blue--38Ihv{background-color:#2f80ed}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SupersetSettings/SectionAdmins/SectionAdmins.module.sass"],"names":[],"mappings":"AAGE,iCACE,kBAAA,CAGF,4CACE,YAAA,CACA,cAAA,CACA,WAAA,CACA,kBAAA,CACF,8CACE,kBAAA,CACA,kCAAA,CAEJ,wBACE,YAAA,CACA,kBAAA,CACA,aAAA,CAEF,wBACE,eAAA,CACA,cAAA,CACA,iBAAA,CACA,mCACE,wBAAA,CACF,sCACE,wBAAA,CACF,qCACE,wBAAA,CACF,oCACE,wBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.form-admin-invite\n  :global(.input)\n    margin-bottom: 20px\n\n.admins-section\n  .list-admins\n    display: flex\n    flex-wrap: wrap\n    gap: func.rem(12)\n    align-items: center\n  .msg-no-admins\n    margin-top: func.rem(20)\n    color: var(--admin-main-font-color)\n\n.admin-menu-item\n  display: flex\n  align-items: center\n  gap: func.rem(5)\n\n.icon-admin-role\n  height: func.rem(10)\n  width: func.rem(10)\n  border-radius: 50%\n  &.red\n    background-color: #EB5757\n  &.yellow\n    background-color: #F2C94C\n  &.green\n    background-color: #219653\n  &.blue\n    background-color: #2F80ED"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-admin-invite": "form-admin-invite--SagAd",
	"admins-section": "admins-section--TD7+0",
	"list-admins": "list-admins--La1lr",
	"msg-no-admins": "msg-no-admins--gSaIS",
	"admin-menu-item": "admin-menu-item--SVzZq",
	"icon-admin-role": "icon-admin-role--TbtHX",
	"red": "red--Vxt0r",
	"yellow": "yellow--lHYRu",
	"green": "green--OfgC5",
	"blue": "blue--38Ihv"
};
export default ___CSS_LOADER_EXPORT___;
