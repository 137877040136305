// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radio-label--cMrqk{margin:0 0.75rem 0 0}.radio-label--cMrqk .radio-input--3mVMB{margin:0 0.5rem 0 0}.radio-label--cMrqk .input-text--zh-0W{font-size:1rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/CreateBUnitSection/CreateBUnitModal/BUnitType/BUnitTypeRadioCase/BUnitTypeRadioCase.module.sass"],"names":[],"mappings":"AAEA,oBACE,oBAAA,CACA,wCACE,mBAAA,CACF,uCACE,cAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.radio-label\n  margin: 0 func.rem(12) 0 0\n  .radio-input\n    margin: 0 func.rem(8) 0 0\n  .input-text\n    font-size: func.rem(16)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio-label": "radio-label--cMrqk",
	"radio-input": "radio-input--3mVMB",
	"input-text": "input-text--zh-0W"
};
export default ___CSS_LOADER_EXPORT___;
