// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-results-control-block--GUV7K{margin-bottom:1.25rem}.wrap-results-control-block--GUV7K .wrap-result-control-btns--K9308{display:flex;gap:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/Results/Cuts/HeaderResults/ResultsControlBlock/ResultsControlBlock.module.sass"],"names":[],"mappings":"AAEA,mCACE,qBAAA,CACA,oEACE,YAAA,CACA,WAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.wrap-results-control-block\n  margin-bottom: func.rem(20)\n  .wrap-result-control-btns\n    display: flex\n    gap: func.rem(20)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-results-control-block": "wrap-results-control-block--GUV7K",
	"wrap-result-control-btns": "wrap-result-control-btns--K9308"
};
export default ___CSS_LOADER_EXPORT___;
