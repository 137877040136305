// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".status-icon--0eLRg{font-size:1.125rem}.status-icon--0eLRg:before{color:var(--admin-btn-color)}.tooltip--Nomqr{font-size:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitStatusIcons/BUnitStatusIcon/BUnitStatusIcon.module.sass"],"names":[],"mappings":"AAEA,oBACE,kBAAA,CACA,2BACE,4BAAA,CAEJ,gBACE,iBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.status-icon\n  font-size: func.rem(18)\n  &:before\n    color: var(--admin-btn-color)\n\n.tooltip\n  font-size: func.rem(20)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status-icon": "status-icon--0eLRg",
	"tooltip": "tooltip--Nomqr"
};
export default ___CSS_LOADER_EXPORT___;
