import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getResultsByRatings,
  loadRatingsFourD, setScoreType, setTargetedObjectID,
  setSelectedParamIDs, setSelectionComplete, setSelectedObjectID,
} from '@/store/actions/admin/superset/process/cuts/fourD';
import {
  FourDSliceInitialState, Rating, RatingResult, SelectedParamIDs,
} from '@/store/types/admin/superset/cuts/fourD';
import { PERCENTILE_SCORE_TYPE } from '@/components/App/Admin/Results/Cuts/FourD/constants';

const initialState: FourDSliceInitialState = {
  ratings: [],
  selectedParamIDs: null,
  ratingsResults: null,
  scoreType: PERCENTILE_SCORE_TYPE,
  isSelectionComplete: false, // if true, then there's a rect && mouseUP is fired
  targetedObjectID: null, // object selected from list
  selectedObject: null, // object selected on the diagram
};

const fourDSlice = createSlice({
  name: 'admin/superset/process/cuts/fourD',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadRatingsFourD.fulfilled, (
        state,
        action: PayloadAction<Rating[]>,
      ) => {
        state.ratings = action.payload;
      })
      .addCase(getResultsByRatings.fulfilled, (
        state,
        action: PayloadAction<RatingResult[]>,
      ) => {
        state.ratingsResults = action.payload;
      })
      .addCase(setSelectedParamIDs, (state, action: PayloadAction<SelectedParamIDs>) => {
        state.selectedParamIDs = action.payload;
      })
      .addCase(setScoreType, (state, { payload: scoreType }) => {
        state.scoreType = scoreType;
      })
      .addCase(setSelectionComplete, (state, { payload: status }) => {
        state.isSelectionComplete = status;
      })
      .addCase(setTargetedObjectID, (state, { payload: objectID }) => {
        state.targetedObjectID = objectID;
      })
      .addCase(setSelectedObjectID, (state, { payload: objectInfo }) => {
        state.selectedObject = objectInfo;
      });
  },
});

export default fourDSlice.reducer;