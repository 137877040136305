// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-list--gX2y5{display:flex;flex-direction:column;position:absolute;top:calc(100% + 12px);max-height:8.75rem;min-width:100%;overflow-y:auto;overflow-x:hidden;background:var(--main-bg);border-radius:0.75rem;z-index:100;padding:0.5rem;gap:0.5rem;box-shadow:0 0 10px -4px var(--main-color)}.dropdown-list--gX2y5 .dash--OgyT2{text-align:center}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/OldBUnitBranch/BUnit/BUnitMenu/BUnitCardFields/BUnitFieldsContent/BUnitFieldsSections/BUnitSpec/CardSelectDropdown/CardSelectDropdown.module.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,qBAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,yBAAA,CACA,qBAAA,CACA,WAAA,CACA,cAAA,CACA,UAAA,CACA,0CAAA,CACA,mCACE,iBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.dropdown-list\n  display: flex\n  flex-direction: column\n  position: absolute\n  top: calc(100% + 12px)\n  max-height: func.rem(140)\n  min-width: 100%\n  overflow-y: auto\n  overflow-x: hidden\n  background: var(--main-bg)\n  border-radius: func.rem(12)\n  z-index: 100\n  padding: func.rem(8)\n  gap: func.rem(8)\n  box-shadow: 0 0 10px -4px var(--main-color)\n  .dash\n    text-align: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-list": "dropdown-list--gX2y5",
	"dash": "dash--OgyT2"
};
export default ___CSS_LOADER_EXPORT___;
