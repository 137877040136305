// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unit-tickets-item--h1sBs{margin-bottom:1.25rem}.unit-tickets-item--h1sBs .wrap-field-count-tickets--ijDxL .wrap-input-number-controller--OjW5e{width:6.25rem}.unit-tickets-item--h1sBs .valid-error-massage--H55Tr{display:inline-block;margin-top:0.3125rem;font-family:\"Inter Medium\",sans-serif;color:var(--error-color);font-size:0.875rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/SectionBusinessUnitsSettings/ModalGenerateTicketUnits/ModalGenerateTicketUnits.module.sass"],"names":[],"mappings":"AAEA,0BACE,qBAAA,CAEE,gGACE,aAAA,CACJ,sDACE,oBAAA,CACA,oBAAA,CACA,qCAAA,CACA,wBAAA,CACA,kBAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.unit-tickets-item\n  margin-bottom: func.rem(20)\n  .wrap-field-count-tickets\n    .wrap-input-number-controller\n      width: func.rem(100)\n  .valid-error-massage\n    display: inline-block\n    margin-top: func.rem(5)\n    font-family: 'Inter Medium', sans-serif\n    color: var(--error-color)\n    font-size: func.rem(14)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit-tickets-item": "unit-tickets-item--h1sBs",
	"wrap-field-count-tickets": "wrap-field-count-tickets--ijDxL",
	"wrap-input-number-controller": "wrap-input-number-controller--OjW5e",
	"valid-error-massage": "valid-error-massage--H55Tr"
};
export default ___CSS_LOADER_EXPORT___;
