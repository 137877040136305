// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".file-prev-container--9OX7m{position:relative;border-radius:0.5rem}.file-prev-container--9OX7m .remove-button--hV8y4{position:absolute;right:0;top:0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/SuperAdmin/SuperUploading/FilesForUploadList/FileForUpload/FileForUpload.module.sass"],"names":[],"mappings":"AAEA,4BACE,iBAAA,CACA,oBAAA,CACA,kDACE,iBAAA,CACA,OAAA,CACA,KAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.file-prev-container\n  position: relative\n  border-radius: func.rem(8)\n  .remove-button\n    position: absolute\n    right: 0\n    top: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-prev-container": "file-prev-container--9OX7m",
	"remove-button": "remove-button--hV8y4"
};
export default ___CSS_LOADER_EXPORT___;
