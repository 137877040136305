// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".status-icons-container--azv-2{display:flex;gap:0.625rem;padding-right:20rem}.status-icons-container--azv-2 .active--xWl8d::before{color:var(--secondary-color)}.status-icons-container--azv-2 .deactivated--lJfD3::before{color:var(--select-question-default-bg)}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitStatusIcons/BUnitStatusIcons.module.sass"],"names":[],"mappings":"AAEA,+BACE,YAAA,CACA,YAAA,CACA,mBAAA,CAEE,sDACE,4BAAA,CAEF,2DACE,uCAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.status-icons-container\n  display: flex\n  gap: func.rem(10)\n  padding-right: func.rem(320)\n  .active\n    &::before\n      color: var(--secondary-color)\n  .deactivated\n    &::before\n      color: var(--select-question-default-bg)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status-icons-container": "status-icons-container--azv-2",
	"active": "active--xWl8d",
	"deactivated": "deactivated--lJfD3"
};
export default ___CSS_LOADER_EXPORT___;
