import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SelectAllCheckBox.module.sass';
import { selectTreeUnits } from '@/store/actions/admin/superset/unitsTree';
import { selectUnitsTreeData } from '@/store/selectors/admin/superset/unitsTree';
import { UnitId } from '@/store/types/user/units';
import useNLS from '@/components/App/Admin/hooks/hooks';
import BUnitContext from '@/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitContext';
import { UnitsSelectActionFlags } from '../../../../SelectMenu/useSelectAction';

interface SelectAllCheckBoxProps {
  unitsForSelect: UnitId[];
}

export default function SelectAllCheckBox({ unitsForSelect }: SelectAllCheckBoxProps) {
  const { units } = useSelector(selectUnitsTreeData);
  const dispatch = useDispatch();
  const { id } = useContext(BUnitContext);
  const tNLS = useNLS();

  const isAllChildrenSelected = () => {
    let notSelectedCount = 0;
    for (let i = 0; i < unitsForSelect.length; i += 1) {
      if (
        units[unitsForSelect[i]].isunit === 0
        && !units[unitsForSelect[i]].selected
      ) {
        notSelectedCount += 1;
      }
    }
    return !notSelectedCount;
  };

  const selectUnits = (isSelect: boolean, unitsId: string[]) => {
    dispatch(
      selectTreeUnits({
        unitsId,
        selectValue: isSelect ? UnitsSelectActionFlags.Select : UnitsSelectActionFlags.Unselect,
      }),
    );
  };

  const selectAllChildren = () => {
    const selectAction = !isAllChildrenSelected();
    const newChildrenArr: string[] = unitsForSelect.filter((unitId) => units[unitId].isunit === 0);

    selectUnits(selectAction, newChildrenArr);
  };

  return (
    <label
      className={`${styles['choose-all-children']}`}
      htmlFor={`${id}-select-all`}
    >
      <input
        id={`${id}-select-all`}
        className={styles['unit-checkbox']}
        type="checkbox"
        checked={isAllChildrenSelected()}
        onChange={() => {
          selectAllChildren();
        }}
      />
      <span>{tNLS('tree.select.unit')}</span>
    </label>
  );
}