import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import objectHash from 'object-hash';
import { selectSuperset } from '@/store/selectors/admin/superset/supersets';
import { selectAllSNACutsParams, selectCachedCuts } from '@/store/selectors/admin/superset/process/cuts/unitsCuts';
import axiosProcessing from '@/axiosConfig';
import { CutsMap, ItemsListByLang } from '@/store/types/admin/superset/cuts/unitsCuts';
import { AdminState } from '@/store/admin';

export const loadUnitCuts = createAsyncThunk<
{ SNACuts: CutsMap, direction: boolean, self: boolean, hashedParams: string },
undefined | { self: boolean } | { direction: boolean }
>(
  'superset/admin/process/cuts/unitsCuts/loadUnitCuts',
  async (form, { getState, rejectWithValue }) => {
    const state = getState() as AdminState;
    const { headers } = state.admin.authentication;
    const { supersetId } = selectSuperset(state);
    const currentParams = selectAllSNACutsParams(state);
    const cachedCuts = selectCachedCuts(state);
    const hashedParams = objectHash({ ...currentParams, ...form });
    const changedParamName = form && Object.keys(form)[0];

    if (changedParamName && (changedParamName === 'self' || changedParamName === 'direction')) {
      const cachedData = cachedCuts[hashedParams];

      if (cachedData) {
        return {
          SNACuts: cachedData,
          direction: currentParams.direction,
          self: currentParams.self,
          hashedParams,
          ...form,
        };
      }
    }

    const sendingData = {
      /*
        Data by default.
            direction: true - Outgoing (delivered) assessments by selected staff members on a given question;
            direction: false - Incoming (received) assessments to selected employees on a given issue;
       */
      modForSNA: true,
      langId: currentParams.langID,
      criterios: currentParams.criterios,
      direction: currentParams.direction,
      self: currentParams.self,
      ...form,
    };

    try {
      const response = await axiosProcessing.post(
        `/api/admin/getUnitCuts/${supersetId}`,
        sendingData,
        { headers },
      );

      return {
        SNACuts: response.data,
        direction: sendingData.direction,
        self: sendingData.self,
        hashedParams,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setUnitsCutsItems = createAction<ItemsListByLang>(
  'superset/admin/process/cuts/unitsCuts/setUnitsCutsItems',
);