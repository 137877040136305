// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".action-buttons-default---o1\\+l{display:flex;flex-direction:row;gap:0.625rem;align-items:center;font-size:1.125rem}.unit-actions-list--rMKTn{opacity:1;transition:opacity ease 300ms}.unit-actions-list--rMKTn.hidden--yhDsV{opacity:0}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/BUnitsSection/UnitsTree/BUnit/BUnitActionButtons/BUnitActionButtons.module.sass"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CAEF,0BACE,SAAA,CACA,6BAAA,CACA,wCACE,SAAA","sourcesContent":["@use 'src/styles/functions' as func\n\n.action-buttons-default\n  display: flex\n  flex-direction: row\n  gap: func.rem(10)\n  align-items: center\n  font-size: func.rem(18)\n\n.unit-actions-list\n  opacity: 1\n  transition: opacity ease 300ms\n  &.hidden\n    opacity: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action-buttons-default": "action-buttons-default---o1+l",
	"unit-actions-list": "unit-actions-list--rMKTn",
	"hidden": "hidden--yhDsV"
};
export default ___CSS_LOADER_EXPORT___;
