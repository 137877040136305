// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-form-uploading-resources--unEZr .error-message-on-uploading--lHZtO{font-weight:bold;margin-bottom:1.25rem}", "",{"version":3,"sources":["webpack://./src/components/App/Admin/ListSupersets/ModalCreateSuperset/FormUploadingResources/FormUploadingResources.module.sass"],"names":[],"mappings":"AAGE,yEACE,gBAAA,CACA,qBAAA","sourcesContent":["@use \"src/styles/functions\" as func\n\n.wrap-form-uploading-resources\n  .error-message-on-uploading\n    font-weight: bold\n    margin-bottom: func.rem(20)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-form-uploading-resources": "wrap-form-uploading-resources--unEZr",
	"error-message-on-uploading": "error-message-on-uploading--lHZtO"
};
export default ___CSS_LOADER_EXPORT___;
