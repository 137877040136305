// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer---ExsH{position:relative;margin-top:0.625rem}.footer---ExsH .footer-container--sAcvz{display:flex;justify-content:space-between;max-width:95%;margin:0 auto;padding:0 15px;align-items:center;min-height:5.3125rem;font-size:14px}@media screen and (max-width: 376px){.footer---ExsH .footer-container--sAcvz{min-height:5.625rem;font-size:12px;padding:0 5px}}.footer---ExsH .footer-container--sAcvz .footer-copyright--ILhD3{color:var(--secondary-grey-text)}.footer---ExsH .footer-container--sAcvz .footer-contact--3LSzU{z-index:5;color:#fff;text-align:right}.footer---ExsH .footer-decor--wvse2{position:absolute;right:0;bottom:0;width:440px;z-index:-1}", "",{"version":3,"sources":["webpack://./src/components/Common/BaseTemplate/Footer/Footer.module.sass"],"names":[],"mappings":"AAGA,eACE,iBAAA,CACA,mBAAA,CACA,wCACE,YAAA,CACA,6BAAA,CACA,aAAA,CACA,aAAA,CACA,cAAA,CACA,kBAAA,CACA,oBAAA,CACA,cAAA,CACA,qCATF,wCAUI,mBAAA,CACA,cAAA,CACA,aAAA,CAAA,CACF,iEACE,gCAAA,CACF,+DACE,SAAA,CACA,UAAA,CACA,gBAAA,CACJ,oCACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA","sourcesContent":["@use 'src/styles/functions' as func\n@use 'src/styles/colors'\n\n.footer\n  position: relative\n  margin-top: func.rem(10)\n  .footer-container\n    display: flex\n    justify-content: space-between\n    max-width: 95%\n    margin: 0 auto\n    padding: 0 15px\n    align-items: center\n    min-height: func.rem(85)\n    font-size: 14px\n    @media screen and (max-width: 376px)\n      min-height: func.rem(90)\n      font-size: 12px\n      padding: 0 5px\n    .footer-copyright\n      color: var(--secondary-grey-text)\n    .footer-contact\n      z-index: 5\n      color: #fff\n      text-align: right\n  .footer-decor\n    position: absolute\n    right: 0\n    bottom: 0\n    width: 440px\n    z-index: -1"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer---ExsH",
	"footer-container": "footer-container--sAcvz",
	"footer-copyright": "footer-copyright--ILhD3",
	"footer-contact": "footer-contact--3LSzU",
	"footer-decor": "footer-decor--wvse2"
};
export default ___CSS_LOADER_EXPORT___;
