import { createSlice } from '@reduxjs/toolkit';
import { getSuperSupersetsList } from '@/store/actions/super/supersets';
import LoadingStatus from '@/constants/enums';
import { SuperSupersetsInitialState } from '@/store/types/super/supersets/supersets';

const initialState: SuperSupersetsInitialState = {
  loadStatus: LoadingStatus.Idle,
  supersetsList: [],
};

export const supersetsSlice = createSlice({
  name: 'supersets',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loadStatus = LoadingStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSuperSupersetsList.pending, (state) => {
        state.loadStatus = LoadingStatus.Loading;
      })
      .addCase(getSuperSupersetsList.fulfilled, (state, { payload }) => {
        state.supersetsList = payload;
        state.loadStatus = LoadingStatus.Received;
      })
      .addCase(getSuperSupersetsList.rejected, (state) => {
        state.loadStatus = LoadingStatus.Error;
      });
  },
});

const { reducer } = supersetsSlice;

export default reducer;